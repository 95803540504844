import React, { Fragment } from 'react';
import { Text, CloseButton } from "@wix/design-system";
import { connect } from 'react-redux';
import s from './ModalHeader.scss';

const ModalHeader = (props: any) => {
  const { dragCallback, closeClicked } = props;
  return (
    <Fragment>
      <Text className={s.modalTitle}>Account Settings</Text>
      <div className={s.grabLine} onMouseDown={dragCallback}></div>
      <CloseButton
        size="small"
        className={s.xBtnContainer}
        onClick={closeClicked}
      ></CloseButton>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  mode: state.requestModal.mode,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalHeader);
