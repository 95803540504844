import { httpClient } from '../../Client/httpClient';

export async function approveSeat(seat) {
  try {
    const approvedSeat = {};
    approvedSeat[seat._id] = {
      status: 2,
      approvedDate: new Date().toISOString(),
    };

    // approveSeat
    // { "17155": { "status": 2, "isPrePendingApproved": true, "approvedDate": "2023-03-15T16:49:45.374Z" } }
    // { "17138": { "status": 2, "approvedDate": "2023-03-15T16:50:29.981Z" } }

    const res = await httpClient.put(`/seats/seats`, approvedSeat, {});
    return res;
  } catch (error) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('approveSeat failed -origin error-' + error);
  }
}
