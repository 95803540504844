import {
  rejectedReasonStatuses,
  PRE_PENDING_GUILDS_NAMES,
} from '../../../../../Client/config';

export const allColumns: any = [
  {
    title: 'Actions',
    columns: [
      {
        title: 'Actions',
        source: 'none',
        size: 90,
        hideTitle: true,
        type: 'actions',
        edit: false,
      },
    ],
  },
  {
    title: 'Duplications Report',
    type: 'grid',
    tableOnly: true,
    filtered: true,
    columns: [
      {
        title: 'Exclude',
        source: 'excludeDuplicationsReport',
        size: 110,
        type: 'checkbox',
        edit: false,
      },
    ],
  },
  {
    title: 'Seats No.',
    columns: [
      {
        title: 'Creation date',
        source: 'createdAt',
        size: 110,
        type: 'date',
        edit: false,
      },
      {
        title: 'Seat Number',
        source: '_id',
        size: 110,
        hideTitle: true,
        type: 'text',
        edit: false,
      },
      {
        title: 'SR job ref',
        source: 'refNumber',
        size: 110,
        type: 'text',
        edit: false,
      },
    ],
  },
  {
    title: 'Team Status',
    type: 'grid',
    tableOnly: true,
    filtered: false,
    columns: [
      {
        title: 'Current HC',
        source: 'teamHeadCount',
        tooltip: 'Current team members.',
        size: 110,
        type: 'text',
        edit: false,
      },
      {
        title: 'Approved/focus/DIY',
        source: 'approvedFocusDIY',
        tooltip: 'Number of position requests in Approved, Focus or DIY status.',
        size: 150,
        type: 'text',
        edit: false,
      },
      {
        title: 'Target',
        source: 'teamTargetHeadCount',
        tooltip: 'Number of employees that the team should have.',
        size: 110,
        type: 'text',
        edit: false,
      },
    ],
  },

  {
    title: 'Recruitment Status',
    tableOnly: true,
    columns: [
      {
        title: 'Status',
        source: 'status',
        size: 150,
        type: 'status',
        edit: false,
      },
      {
        title: 'Justification',
        source: 'businessJustification',
        size: 80,
        type: 'justification',
        edit: true,
      },
    ],
  },
  {
    title: 'Requester',
    tableOnly: true,
    columns: [
      {
        title: 'Requester',
        source: 'requester',
        size: 135,
        hideTitle: true,
        type: 'requester',
        placeholder: 'Requester',
        edit: true,
        required: true,
        addititonalValue: 'requesterEmail',
        // formTooltip: 'this should be the recruiting manager or team manager',
      },
    ],
  },
  {
    title: 'Position Details',
    type: 'grid',
    columns: [
      {
        title: 'Position Name',
        source: 'positionName',
        size: 175,
        type: 'inputText',
        placeholder: 'Position Name',
        edit: true,
        required: false,
        rowStart: 2,
        rowEnd: 3,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Profession',
        source: 'profession',
        size: 135,
        type: 'dropdown',
        placeholder: 'Profession',
        edit: true,
        required: true,
        rowStart: 1,
        rowEnd: 2,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Guild',
        source: 'guild',
        size: 135,
        type: 'dropdown',
        placeholder: 'Guild',
        edit: true,
        required: true,
        rowStart: 2,
        rowEnd: 3,
        colStart: 2,
        colEnd: 3,
      },
      {
        title: 'Sub Guild',
        source: 'subGuild',
        parent: 'guild',
        size: 175,
        type: 'dropdown',
        placeholder: 'Sub Guild',
        edit: true,
        required: true,
        rowStart: 3,
        rowEnd: 4,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Company / Department',
        source: 'company',
        size: 175,
        type: 'dropdown',
        placeholder: 'Company / Department',
        edit: true,
        required: true,
        rowStart: 3,
        rowEnd: 4,
        colStart: 2,
        colEnd: 3,
      },
      {
        title: 'Team',
        source: 'subTeam',
        parent: 'company',
        size: 220,
        type: 'dropdown',
        placeholder: 'Team',
        edit: true,
        required: true,
        rowStart: 4,
        rowEnd: 5,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Budget ID',
        source: 'budgetId',
        size: 135,
        type: 'text',
        placeholder: 'Budget ID',
        edit: false,
        editModeOnly: true,
        rowStart: 5,
        rowEnd: 6,
        colStart: 2,
        colEnd: 3,
      },
      {
        title: 'Shift',
        source: 'shift',
        placeholder: 'Shift',
        size: 135,
        type: 'dropdown',
        edit: true,
        filtered: true,
        hidder: {
          source: ['company', 'guild'],
          showValues: PRE_PENDING_GUILDS_NAMES,
        },
        rowStart: 4,
        rowEnd: 5,
        colStart: 2,
        colEnd: 3,
      },
      {
        title: 'Internal / External Recruit',
        source: 'internalExternalRecruit',
        placeholder: 'Internal / External Recruit',
        size: 135,
        type: 'dropdown',
        display: true,
        edit: true,
        filtered: false,
        // hidder: {
        //   source: ['company', 'guild'],
        //   showValues: PRE_PENDING_GUILDS_NAMES,
        // },
        rowStart: 5,
        rowEnd: 6,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Seniority',
        source: 'seniority',
        size: 135,
        display: true,
        filtered: false,
        type: 'dropdown',
        placeholder: 'Seniority',
        edit: true,
        required: true,
        rowStart: 1,
        rowEnd: 2,
        colStart: 2,
        colEnd: 3,
      },
    ],
  },
  {
    title: 'Position Type',
    type: 'grid',
    columns: [
      {
        title: 'Full / part time',
        source: 'type',
        size: 135,
        type: 'dropdown',
        placeholder: 'Full / part time',
        edit: true,
        required: true,
        rowStart: 1,
        rowEnd: 2,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Student / intern',
        source: 'position',
        size: 135,
        type: 'dropdown',
        placeholder: 'Student / intern',
        edit: true,
        required: true,
        rowStart: 1,
        rowEnd: 2,
        colStart: 2,
        colEnd: 3,
      },
      {
        title: 'Temp',
        source: 'temp',
        size: 135,
        type: 'checkbox',
        dummy: true,
        placeholder: 'Temp',
        edit: true,
        rowStart: 3,
        rowEnd: 4,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Replace / New',
        source: 'replacementType',
        size: 135,
        type: 'dropdown',
        placeholder: 'Replace / New',
        edit: true,
        required: true,
        rowStart: 2,
        rowEnd: 3,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'In House / OS',
        source: 'employmentType',
        size: 135,
        display: true,
        filtered: false,
        type: 'dropdown',
        placeholder: 'In House/OS',
        edit: true,
        required: true,
        rowStart: 2,
        rowEnd: 3,
        colStart: 2,
        colEnd: 3,
      },
    ],
  },
  {
    title: 'Replacement Details',
    type: 'grid',
    hidder: {
      source: ['replacementType'],
      showValues: [
        'Replacement (EOE)',
        'Replacement (Internal Move)',
        'ML (Leave)',
        'LOA (Unpaid Leave)',
      ],
    },
    columns: [
      {
        title: 'Name',
        source: 'replacementName',
        size: 135,
        type: 'replacement',
        placeholder: 'Name',
        edit: true,
        required: true,
        dropdownName: 'requester',
        hidder: {
          source: ['replacementType'],
          showValues: [
            'Replacement (EOE)',
            'Replacement (Internal Move)',
            'ML (Leave)',
            'LOA (Unpaid Leave)',
          ],
        },
      },
      {
        title: 'Closed a Position',
        source: 'closedPosition',
        size: 135,
        type: 'checkbox',
        dummy: true,
        placeholder: 'Closed a Position',
        edit: true,
        required: true,
        hidder: {
          source: ['replacementType'],
          showValues: ['Replacement (Internal Move)'],
        },
      },
      {
        title: 'Moved To',
        source: 'movedTo',
        size: 135,
        type: 'inputText',
        placeholder: 'Moved To',
        edit: true,
        required: true,
        hidder: {
          source: ['replacementType'],
          showValues: ['Replacement (Internal Move)'],
        },
      },
      {
        title: 'Voluntary',
        source: 'voluntary',
        size: 135,
        type: 'checkbox',
        edit: true,
        dummy: true,
        required: true,
        hidder: {
          source: ['replacementType'],
          showValues: ['Replacement (EOE)'],
        },
      },
      {
        title: 'Date (Est.)',
        source: 'replacementDate',
        size: 135,
        type: 'datepicker',
        edit: true,
        required: true,
        hidder: {
          source: ['replacementType'],
          showValues: [
            'Replacement (EOE)',
            'Replacement (Internal Move)',
            'ML (Leave)',
            'LOA (Unpaid Leave)',
          ],
        },
      },
    ],
  },
  {
    title: 'City',
    type: 'grid',
    tableOnly: true,
    columns: [
      {
        title: 'Requested',
        source: 'site',
        size: 115,
        type: 'dropdown',
        placeholder: 'Requested',
        edit: true,
        required: true,
      },
      // {
      //   title: 'Approved',
      //   source: 'siteApproved',
      //   size: 115,
      //   type: 'dropdown',
      //   placeholder: 'Approved',
      //   edit: false,
      // },
    ],
  },
  {
    title: 'Priority',
    type: 'grid',
    columns: [
      // {
      //   title: 'Wix Priority',
      //   source: 'wixPriority',
      //   size: 135,
      //   display: true,
      //   filtered: false,
      //   type: 'dropdown',
      //   placeholder: 'Wix Priority',
      //   edit: true,
      //   required: false,
      //   rowStart: 1,
      //   rowEnd: 2,
      //   colStart: 1,
      //   colEnd: 2,
      // },
      {
        title: 'Priority Status',
        source: 'strategicPlanning',
        size: 135,
        type: 'dropdown',
        placeholder: 'Priority Status',
        edit: false,
        rowStart: 3,
        rowEnd: 4,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Guild Priority',
        source: 'guildPriority',
        size: 135,
        type: 'dropdown',
        placeholder: 'Guild Priority',
        edit: false,
        rowStart: 2,
        rowEnd: 3,
        colStart: 1,
        colEnd: 2,
      },
    ],
  },
  {
    title: 'Q',
    type: 'grid',
    tableOnly: true,
    columns: [
      {
        title: 'Q Request',
        source: 'qRequest',
        size: 115,
        type: 'dropdown',
        placeholder: 'Q Request',
        edit: true,
        required: true,
      },
      // {
      //   title: 'Q Approved',
      //   source: 'qApproved',
      //   size: 115,
      //   type: 'dropdown',
      //   placeholder: 'Q Approved',
      //   edit: false,
      // },
    ],
  },
  {
    title: 'Recruitment Details',
    type: 'grid',
    columns: [
      {
        title: 'HR',
        source: 'hr',
        size: 135,
        type: 'dropdown',
        placeholder: 'HR',
        edit: false,
        rowStart: 1,
        rowEnd: 2,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Recruiter',
        source: 'hrRecruiter',
        size: 135,
        type: 'dropdown',
        placeholder: 'Recruiter',
        edit: false,
        rowStart: 2,
        rowEnd: 3,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Talent Center',
        source: 'talentCenter',
        size: 135,
        type: 'dropdown',
        placeholder: 'Talent Center',
        edit: false,
        rowStart: 3,
        rowEnd: 4,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Employee Name (recruited)',
        source: 'employeeName',
        size: 135,
        type: 'dropdown',
        placeholder: 'Employee Name (recruited)',
        edit: false,
        required: true,
        hidder: {
          source: ['status'],
          showValues: [6],
        },
        rowStart: 3,
        rowEnd: 4,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'Approved Date',
        source: 'approvedDate',
        size: 135,
        type: 'datepicker',
        edit: false,
        rowStart: 1,
        rowEnd: 2,
        colStart: 2,
        colEnd: 3,
      },
      {
        title: 'End of Process',
        source: 'processEnd',
        size: 135,
        type: 'datepicker',
        edit: false,
        rowStart: 2,
        rowEnd: 3,
        colStart: 2,
        colEnd: 3,
      },
      {
        title: 'Closed by Internal Move',
        source: 'closedByInternalMove',
        size: 135,
        dummy: true,
        type: 'checkbox',
        edit: false,
        rowStart: 3,
        rowEnd: 4,
        colStart: 2,
        colEnd: 3,
      },
    ],
  },
  {
    title: 'Request Details',
    formOnly: true,
    type: 'grid',
    columns: [
      {
        title: 'Seat Number',
        source: '_id',
        type: 'inputText',
        edit: false,
        editModeOnly: true,
        rowStart: 4,
        rowEnd: 5,
        colStart: 1,
        colEnd: 2,
      },
      {
        title: 'SR job ref',
        source: 'refNumber',
        size: 135,
        display: true,
        type: 'inputText',
        edit: false,
        editModeOnly: true,
        rowStart: 4,
        rowEnd: 5,
        colStart: 2,
        colEnd: 3,
      },
      {
        title: 'Requester',
        source: 'requester',
        type: 'requester',
        placeholder: 'Requester',
        edit: true,
        required: true,
        rowStart: 1,
        rowEnd: 2,
        colStart: 1,
        colEnd: 2,
        addititonalValue: 'requesterEmail',
        formTooltip: 'this should be the recruiting manager or team manager',
      },
      {
        title: 'Status',
        placeholder: 'Status',
        source: 'status',
        type: 'status',
        edit: false,
        rowStart: 2,
        rowEnd: 3,
        colStart: 1,
        colEnd: 2,
      },
    ],
  },
  {
    title: 'Status Change Justification',
    formOnly: true,
    hidder: {
      source: ['status'],
      showValues: rejectedReasonStatuses,
    },
    columns: [
      {
        title: 'Status Change Justification',
        source: 'statusReason',
        type: 'textArea',
        placeholder: 'Status Change Justification',
        edit: false,
        hidder: {
          source: ['status'],
          showValues: rejectedReasonStatuses,
        },
      },
    ],
  },
  {
    title: 'Business Justification',
    formOnly: true,
    columns: [
      {
        title: 'Business Justification',
        source: 'businessJustification',
        type: 'textArea',
        placeholder: 'Business Justification',
        edit: true,
      },
    ],
  },
  {
    title: 'Share Request with',
    formOnly: true,
    columns: [
      {
        title: 'Share Request with',
        source: 'delegates',
        type: 'multiSelect',
        placeholder: 'Share Request with',
        edit: true,
        dropdownName: 'delegates',
      },
    ],
  },
  {
    title: 'City & Q',
    formOnly: true,
    type: 'grid',
    columns: [
      {
        title: 'City Requested',
        source: 'site',
        type: 'dropdown',
        placeholder: 'Requested',
        edit: true,
        required: true,
        rowStart: 1,
        rowEnd: 2,
        colStart: 1,
        colEnd: 2,
      },
      // {
      //   title: 'City Approved',
      //   source: 'siteApproved',
      //   type: 'dropdown',
      //   placeholder: 'Approved',
      //   edit: false,
      //   rowStart: 2,
      //   rowEnd: 3,
      //   colStart: 1,
      //   colEnd: 2,
      // },
      {
        title: 'Q Requested',
        source: 'qRequest',
        type: 'dropdown',
        placeholder: 'Q Request',
        edit: true,
        required: true,
        rowStart: 1,
        rowEnd: 2,
        colStart: 2,
        colEnd: 3,
      },
      // {
      //   title: 'Q Approved',
      //   source: 'qApproved',
      //   type: 'dropdown',
      //   placeholder: 'Q Approved',
      //   edit: false,
      //   rowStart: 2,
      //   rowEnd: 3,
      //   colStart: 2,
      //   colEnd: 3,
      // },
    ],
  },
];
