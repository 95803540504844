import { EMPLOYEES_TABLE } from './constants';
import { SECOND_HEIRACHY_HC_TARGET_TEAMS } from '../../../../Client/config';

export const initEmployeesTable = () => async (
  dispatch: any,
  getState: any,
) => {
  const { amountOfSeatsByTeamAndStatus } = getState().seats;
  if (!amountOfSeatsByTeamAndStatus) {
    return;
  }
  const {
    headCount,
    targetHeadCount,
    topCompanies,
    topTeams,
    subTeams,
  } = getState().headCount;

  const employeesTableData = getEmployeesTableData(
    headCount,
    targetHeadCount,
    amountOfSeatsByTeamAndStatus,
    topCompanies,
    topTeams,
    subTeams,
  );

  dispatch({
    payload: employeesTableData,
    type: EMPLOYEES_TABLE.EMPLOYEES_DATA,
  });
};

const getEmployeesTableData = (
  headCount: any,
  targetHeadCount: any,
  amountOfSeatsByTeamAndStatus: any,
  topCompanies: any,
  topTeams: any,
  subTeams: any,
) => {
  const employeesTableData = [];
  if (subTeams && subTeams.length > 0) {
    for (const subTeam of subTeams) {
      const teamRow = getGroupRow(
        'subTeam',
        subTeam,
        headCount,
        targetHeadCount,
        amountOfSeatsByTeamAndStatus,
      );
      employeesTableData.push(teamRow);
    }
  }
  for (const teamRow of employeesTableData) {
    if (
      teamRow.target &&
      (!topCompanies || !topCompanies.includes(teamRow.name))
    ) {
      if (!topCompanies) {
        topCompanies = [];
      }
      topCompanies.push(teamRow.name);
    }
  }
  const topCompaniesRows: any = [];
  if (topCompanies && topCompanies.length > 0) {
    for (const topCompany of topCompanies) {
      const topCompanyRow = {
        name: topCompany,
        subTeamsRows: [] as any,
        nonStudentEmployeesCount: 0,
        studentEmployeesCount: 0,
        target: 0,
        nonStudentsOpenAndFocusSeatsCount: 0,
        studentsOpenAndFocusSeatsCount: 0,
        pendingSeatsCount: 0,
        remaining: 0,
        type: 'company',
        label: topCompany,
        value: topCompany,
      };
      for (const teamRow of employeesTableData) {
        if (
          (teamRow.name.startsWith(topCompany) &&
            teamRow.name.includes('\\') &&
            !SECOND_HEIRACHY_HC_TARGET_TEAMS.includes(topCompany)) ||
          teamRow.name === topCompany
        ) {
          topCompanyRow.subTeamsRows.push(teamRow);
          topCompanyRow.nonStudentEmployeesCount +=
            teamRow.nonStudentEmployeesCount;
          topCompanyRow.studentEmployeesCount += teamRow.studentEmployeesCount;
          topCompanyRow.target += teamRow.target;
          topCompanyRow.nonStudentsOpenAndFocusSeatsCount +=
            teamRow.nonStudentsOpenAndFocusSeatsCount;
          topCompanyRow.studentsOpenAndFocusSeatsCount +=
            teamRow.studentsOpenAndFocusSeatsCount;
          topCompanyRow.pendingSeatsCount += teamRow.pendingSeatsCount;
          topCompanyRow.remaining += teamRow.remaining;
          teamRow.isCompanyExists = true;
        }
      }
      topCompanyRow.subTeamsRows.sort((a: any, b: any) =>
        a?.name?.localeCompare(b?.name),
      );
      topCompaniesRows.push(topCompanyRow);
    }
  }
  topCompaniesRows.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));
  if (subTeams && subTeams.length > 0) {
    const miscCompanyRow = {
      name: 'Misc.',
      subTeamsRows: [] as any,
      nonStudentEmployeesCount: 0,
      studentEmployeesCount: 0,
      target: 0,
      nonStudentsOpenAndFocusSeatsCount: 0,
      studentsOpenAndFocusSeatsCount: 0,
      pendingSeatsCount: 0,
      remaining: 0,
      type: 'company',
    };
    for (const teamRow of employeesTableData) {
      if (!teamRow.isCompanyExists && subTeams.includes(teamRow.name)) {
        miscCompanyRow.subTeamsRows.push(teamRow);
        miscCompanyRow.nonStudentEmployeesCount +=
          teamRow.nonStudentEmployeesCount;
        miscCompanyRow.studentEmployeesCount += teamRow.studentEmployeesCount;
        miscCompanyRow.target += teamRow.target;
        miscCompanyRow.nonStudentsOpenAndFocusSeatsCount +=
          teamRow.nonStudentsOpenAndFocusSeatsCount;
        miscCompanyRow.studentsOpenAndFocusSeatsCount +=
          teamRow.studentsOpenAndFocusSeatsCount;
        miscCompanyRow.pendingSeatsCount += teamRow.pendingSeatsCount;
        miscCompanyRow.remaining += teamRow.remaining;
      }
    }
    if (miscCompanyRow.subTeamsRows.length > 0) {
      miscCompanyRow.subTeamsRows.sort((a: any, b: any) =>
        a?.name?.localeCompare(b?.name),
      );
      topCompaniesRows.push(miscCompanyRow);
    }
  }
  if (topCompaniesRows && topCompaniesRows.length > 0) {
    return topCompaniesRows;
  }
  return employeesTableData;
};

const getGroupRow = (
  type: any,
  group: any,
  headCount: any,
  targetHeadCount: any,
  amountOfSeatsByTeamAndStatus: any,
) => {
  const groupEmployees = headCount.filter((employee: any) =>
    type === 'subGuild'
      ? employee.subGuild === group
      : employee.subTeam === group,
  );
  groupEmployees.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));
  const nonStudentInternEmployees = groupEmployees.filter(
    (employee: any) => (!employee.studentIntern),
  );
  const studentInternEmployees = groupEmployees.filter(
    (employee: any) => (employee.studentIntern),
  );
  const groupTarget = targetHeadCount[group] || 0;
  const pendingSeats = getSeatsByStatus(
    type,
    group,
    '1',
    amountOfSeatsByTeamAndStatus,
    false,
  );
  let studentsOpenAndFocusSeats = getSeatsByStatus(
    type,
    group,
    '2',
    amountOfSeatsByTeamAndStatus,
    true,
  );
  studentsOpenAndFocusSeats += getSeatsByStatus(
    type,
    group,
    '5',
    amountOfSeatsByTeamAndStatus,
    true,
  );
  studentsOpenAndFocusSeats += getSeatsByStatus(
    type,
    group,
    '9',
    amountOfSeatsByTeamAndStatus,
    true,
  );
  studentsOpenAndFocusSeats += getSeatsByStatus(
    type,
    group,
    '13',
    amountOfSeatsByTeamAndStatus,
    true,
  );
  let nonStudentsOpenAndFocusSeats = getSeatsByStatus(
    type,
    group,
    '2',
    amountOfSeatsByTeamAndStatus,
    false,
  );
  nonStudentsOpenAndFocusSeats += getSeatsByStatus(
    type,
    group,
    '5',
    amountOfSeatsByTeamAndStatus,
    false,
  );
  nonStudentsOpenAndFocusSeats += getSeatsByStatus(
    type,
    group,
    '9',
    amountOfSeatsByTeamAndStatus,
    false,
  );
  nonStudentsOpenAndFocusSeats += getSeatsByStatus(
    type,
    group,
    '13',
    amountOfSeatsByTeamAndStatus,
    false,
  );

  const teamRemaining =
    groupTarget -
    nonStudentInternEmployees.length -
    nonStudentsOpenAndFocusSeats;
  const teamRow = {
    name: group,
    employees: groupEmployees,
    nonStudentEmployeesCount: nonStudentInternEmployees.length,
    studentEmployeesCount: studentInternEmployees.length,
    target: groupTarget,
    nonStudentsOpenAndFocusSeatsCount: nonStudentsOpenAndFocusSeats,
    studentsOpenAndFocusSeatsCount: studentsOpenAndFocusSeats,
    pendingSeatsCount: pendingSeats,
    remaining: teamRemaining,
    type,
    isCompanyExists: false,
  };
  return teamRow;
};

const getSeatsByStatus = (
  type: any,
  groupName: any,
  status: any,
  amountOfSeatsByTeamAndStatus: any,
  studentIntern: any,
) => {
  const group =
    type === 'subGuild'
      ? amountOfSeatsByTeamAndStatus.subGuilds[groupName]
      : amountOfSeatsByTeamAndStatus.subTeams[groupName];
  return group && group[status]
    ? studentIntern
      ? group[status].studentsInterns || 0
      : group[status].nonStudentsInterns || 0
    : 0;
};

export const handleSearchEmployees = (searchValue: any) => async (
  dispatch: any,
  getState: any,
) => {
  searchValue = searchValue.trim();
  dispatch({
    payload: searchValue,
    type: EMPLOYEES_TABLE.SEARCH_VALUE,
  });
  if (searchValue.length === 0) {
    dispatch({
      payload: null,
      type: EMPLOYEES_TABLE.EMPLOYEES_SEARCH_RESULT,
    });
  } else {
    const state = getState();
    let employeesData = searchEmployees(state, searchValue);
    const { hcdFilters } = getState().filter;
    if (hcdFilters) {
      employeesData = applyFilters(employeesData, hcdFilters);
    }
    dispatch({
      payload: employeesData,
      type: EMPLOYEES_TABLE.EMPLOYEES_SEARCH_RESULT,
    });
    updateFilteredHeadCountAmount(employeesData, dispatch);
  }
};

const searchEmployees = (state: any, searchValue: any) => {
  const {
    headCount,
    targetHeadCount,
    topCompanies,
    topTeams,
    subTeams,
  } = state.headCount;
  const searchResults = applySearch(headCount, searchValue);
  const { amountOfSeatsByTeamAndStatus } = state.seats;
  const employeesData = getEmployeesTableData(
    searchResults,
    targetHeadCount,
    amountOfSeatsByTeamAndStatus,
    topCompanies,
    topTeams,
    subTeams,
  );
  return employeesData;
};

const applySearch = (headCount: any, searchValue: any) => {
  const words = searchValue.toString().toLowerCase().split(' ');
  const filteredResults = [];
  for (const employee of headCount) {
    let containsAll = true;
    for (const word of words) {
      let containsWord = false;
      if (word) {
        for (const key in employee) {
          if (key === 'managerName') {
            continue;
          }
          if (employee[key]?.toString().toLowerCase().includes(word)) {
            containsWord = true;
            break;
          }
        }
      }
      if (!containsWord) {
        containsAll = false;
        break;
      }
    }
    if (containsAll) {
      filteredResults.push(employee);
    }
  }
  return filteredResults;
};

export const filterHeadCount = (filters: any) => async (
  dispatch: any,
  getState: any,
) => {
  const { employeesData, searchValue } = getState().employeesTable;
  let searchResults;
  if (searchValue) {
    const state = getState();
    searchResults = searchEmployees(state, searchValue);
  }
  const filterResults = searchResults
    ? applyFilters(searchResults, filters)
    : applyFilters(employeesData, filters);
  dispatch({
    payload: filterResults,
    type: EMPLOYEES_TABLE.EMPLOYEES_SEARCH_RESULT,
  });
  updateFilteredHeadCountAmount(filterResults, dispatch);
};

const applyFilters = (employeesData: any, filters: any) => {
  if (!filters) {
    return employeesData;
  }
  const results = [];
  for (const row of employeesData) {
    let allFiltersValid = true;
    for (const filter in filters) {
      for (const source in filters[filter]) {
        if (!filters[filter][source].includes(row[source])) {
          allFiltersValid = false;
          break;
        }
      }
      if (!allFiltersValid) {
        break;
      }
    }
    if (allFiltersValid) {
      results.push(row);
    }
  }
  return results;
};

const updateFilteredHeadCountAmount = (employeesData: any, dispatch: any) => {
  let filteredHeadCountAmount = 0;
  for (const groupRow of employeesData) {
    filteredHeadCountAmount +=
      groupRow.nonStudentEmployeesCount + groupRow.studentEmployeesCount;
  }
  dispatch({
    payload: filteredHeadCountAmount,
    type: EMPLOYEES_TABLE.FILTERED_HEAD_COUNT_AMOUNT,
  });
};
