import React, { useState, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import s from './FormReplacement.scss';
import { Visible } from 'wix-ui-icons-common';
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
import { Field } from 'redux-form';
import { Text } from "@wix/design-system";
import { getReplacement } from '../../../redux/actions/seatsActions';
import { formName } from '../../../redux/actions/requestModalActions';

const validateRequired = (value: string) => (value ? undefined : 'Required');

const renderField = ({
  input,
  placeholder,
  aditionalClass,
  meta: { touched, error, warning },
  disabled,
}: any) => {
  return (
    <input
      {...input}
      autoComplete="off"
      autoFocus
      className={`${s.formInput} ${aditionalClass ? aditionalClass : ''} ${
        disabled ? s.disabled : ''
      } ${touched && error ? s.errorInput : ''}`}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

const renderSecret = ({
  input,
  aditionalClass,
  disabled,
  meta: { touched, error, warning },
}: any) => (
  <div
    className={`${s.main} ${aditionalClass ? aditionalClass : ''} ${
      disabled ? s.disabled : ''
    } ${touched && error ? s.errorInput : ''}`}
  >
    <Visible size="18px" className={s.nonVisible} />
    <div className={`${s.stars} ${disabled ? s.disabled : ''}`}>********</div>
    <input {...input} hidden />
  </div>
);

const Replacement = (props: any) => {
  const {
    title,
    source,
    request,
    dropdowns,
    dropdownName,
    placeholder,
    aditionalClass,
    required,
    currentInputRef,
    setRef,
    getReplacement,
    change,
    mode,
  } = props;

  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');
  const [myId, setMyId] = useState(uuidv4());
  const [options, setOptions] = useState([]);
  const [disabled, setDisabled] = useState(mode === 'View');

  const getReplacementDisplay = async () => {
    try {
      if (request && request._id && value === '') {
        const replacementNameResponse = await getReplacement(request._id);
        if (
          replacementNameResponse &&
          replacementNameResponse.replacementName
        ) {
          setValue(replacementNameResponse.replacementName);
          change(source, replacementNameResponse.replacementName);
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleClick = async (event: any) => {
    try {
      await getReplacementDisplay();
    } catch (error: any) {
      console.log(error);
    } finally {
      setOptionValues();
      scrollTo();
      setRef(myId);
      setFocus(true);
    }
  };

  const handleOptionSelect = (option: any) => {
    handleSetValue(option.value);
    setFocus(false);
  };

  const handleSetValue = (value: string) => {
    change(source, value);
    setValue(value);
  };

  const setOptionValues = () => {
    const temp = dropdowns[dropdownName || source]
      ? dropdowns[dropdownName || source].data
      : [];
    setOptions(temp);
  };

  const scrollTo = () => {
    const element = document.getElementById(source);
    const form = document.getElementById(formName);

    if (
      form &&
      element &&
      element.offsetTop + 200 > form.clientHeight + form.scrollTop
    ) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const isFocused = focus && currentInputRef === myId;

  return (
    <Fragment>
      {title && (
        <Text className={s.formInputLabel}>
          {title}
          {required && ' *'}
        </Text>
      )}
      <div
        className={`${s.formInputGroup} ${aditionalClass} ${
          disabled ? s.disabled : ''
        }`}
      >
        {isFocused ? (
          <Fragment>
            <Field
              name={source}
              component={renderField}
              aditionalClass={aditionalClass}
              validate={required ? [validateRequired] : null}
              onChange={(e: any) => setValue(e.target.value)}
            />
            <CustomDropdown
              filter={value}
              title={placeholder}
              options={options}
              onSelect={handleOptionSelect}
              style={{
                position: 'absolute',
                zIndex: 1,
                width: 'inherit',
                height: '150px',
                marginTop: '9px',
              }}
            />
          </Fragment>
        ) : (
          <div onClick={!disabled ? handleClick : () => {}}>
            <Field
              name={source}
              component={renderSecret}
              aditionalClass={aditionalClass}
              validate={required ? [validateRequired] : null}
              onChange={(e: any) => setValue(e.target.value)}
              disabled={disabled}
            />
            <div className={s.chevronBox}>
              {!disabled && <ChevronDown size="18" />}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  mode: state.requestModal.mode,
});

const mapDispatchToProps = (dispatch: any) => ({
  getReplacement: (seatId: number) => dispatch(getReplacement(seatId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Replacement);
