import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import s from './Login.scss';
import {
  signIn,
  setOriginalPath,
  authorize,
} from '../redux/actions/authActions';
import LoginTitle from './subComponents/LoginTitle';
import CustomGoogleLogin from './subComponents/CustomGoogleLogin/CustomGoogleLogin';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from '../../../Client/utils';
import { mobileMsg } from '../../../Client/config';
import { Button, Text } from "@wix/design-system";

const Login = (props: any) => {
  const { user, signIn, authorize } = props;

  const [isAuthChecked, setIsAuthChecked] = useState<any>(false);
  const [isMobileVersionAgreed, setIsMobileVersionAgreed] = useState<any>(
    false,
  );

  const navigate = useNavigate();
  const location: any = useLocation();

  const auth = () => {
    authorize().then(() => {
      if (user) {
        const from = location.state?.from || '/';
        navigate(from, { replace: true });
      }
      setIsAuthChecked(true);
    });
  };

  const googleRes = async (response: any) => {
    if (response.code) {
      await signIn(response.code);

      const from = location.state?.from || '/';
      navigate(from, { replace: true });
    }
    console.log('err');
  };

  return (
    <div className={s.loginContainer}>
      {isMobile() && !isMobileVersionAgreed ? (
        <Fragment>
          <LoginTitle />
          <Text className={s.mobileMsg}>{mobileMsg}</Text>
          <Button
            onClick={() => setIsMobileVersionAgreed(true)}
            className={s.button}
          >
            Continue to mobile version
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          {auth()}
          {isAuthChecked && <LoginTitle />}
          {isAuthChecked && (
            <GoogleLogin
              clientId="568324909982-n72di533blc5c05ureh3h005pnmanvrg.apps.googleusercontent.com"
              accessType="offline"
              onSuccess={googleRes}
              onFailure={googleRes}
              cookiePolicy="single_host_origin"
              responseType="code"
              prompt="consent"
              scope="profile email"
              render={(renderProps) => (
                <CustomGoogleLogin
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                />
              )}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  signIn: (token: any) => dispatch(signIn(token)),
  authorize: () => dispatch(authorize()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
