import React from 'react';

import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import store from './redux/store';
// import s from './App.scss';
import Login from './Login/Login';
import Main from './Main/Main';
import ApproveSeat from './ApproveSeat/approveSeat';
import "@wix/design-system/styles.global.css";

/* <-- To remove demo stuff just copy-paste:
  \{?/\*\s?<--([\n\n]|.)*?-->\s?\*\/\}?
  to your search input with RegExp enabled and remove everything matched.
--> */
// const [approveRes, getApproveRes] = useState('Please wait...');

interface AppProps extends WithTranslation {}

class App extends React.Component<AppProps> {
  state = {
    TemplateIntro: () => null,
    // data: ""
  };
  // approveSeat().then((res) => {
  //   this.setState({data :res})
  // })
  async componentDidMount() {} /* --> */

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/approve-seat/:seatId"
              element={
                <RequireAuth redirectTo="/login">
                  <ApproveSeat />
                </RequireAuth>
              }
            />
            <Route
              path="/eoe"
              element={
                <RequireAuth redirectTo="/login">
                  <Main />
                </RequireAuth>
              }
            />
            <Route
              path="/:seatId"
              element={
                <RequireAuth redirectTo="/login">
                  <Main />
                </RequireAuth>
              }
            />
            <Route
              path="/"
              element={
                <RequireAuth redirectTo="/login">
                  <Main />
                </RequireAuth>
              }
            />
            <Route
              path="/login"
              element={<Login state={{ from: location }} />}
            />
          </Routes>
        </BrowserRouter>
      </Provider>
    );
  }
}

const RequireAuth = ({ children, path, redirectTo }: any) => {
  const user = store.getState().auth.user;
  if (user) {
    return children;
  }
  const location = useLocation().pathname;

  return <Navigate to={redirectTo} state={{ from: location }} replace />;
};

export default withTranslation()(App);
