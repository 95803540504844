import React, { useState, useEffect } from 'react';
import s from './CustomDatePicker.scss';
import { DatePicker, Text } from "@wix/design-system";
import { DateSmall, X } from 'wix-ui-icons-common';
import { v4 as uuidv4 } from 'uuid';
import { Field } from 'redux-form';
import { formName } from '../../../redux/actions/requestModalActions';
import { connect } from 'react-redux';
import { monthsKey } from '../../../../../Client/config';

const validateRequired = (value: string) => (value ? undefined : 'Required');

const renderField = ({
  input,
  placeholder,
  aditionalClass,
  disabled,
  meta: { touched, error, warning },
}: any) => {
  const className = `${s.emptyDateInput}
  ${aditionalClass ? aditionalClass : ''}
  ${disabled ? s.disabled : ''}
  ${touched && error ? s.errorInput : ''}`;
  return (
    <input
      {...input}
      value="-"
      autoComplete="off"
      className={className}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

const getFormatedDate = (dateValue: any) => {
  if (!dateValue || dateValue === '-') {
    return dateValue;
  }
  const date = new Date(dateValue);
  const month = monthsKey[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear() % 2000;
  return `${day} ${month} ${year}`;
};

const CustomDatePicker = (props: any) => {
  const {
    title,
    initValue,
    currentInputRef,
    setRef,
    change,
    source,
    required,
    request,
    mode,
  } = props;

  const [focus, setFocus] = useState(false);
  const [myId, setMyId] = useState(uuidv4());
  const [value, setValue] = useState(initValue || null);
  const [toggleLeft, setToggleLeft] = useState(false);
  const [initialValue, setInitialValue] = useState(initValue || '');
  const [pendingChange, setPendingChange] = useState(null);
  const [disabled, setDisabled] = useState(mode === 'View');
  const [date, setDate] = useState<any>(null);

  useEffect(() => {
    if (request?.pendingChanges && request.pendingChanges[source]) {
      setPendingChange(request.pendingChanges[source]);
      change(source, request.pendingChanges[source]);
      setValue(request.pendingChanges[source]);
    }
  }, []);

  useEffect(() => {
    const date = dateFormat();
    setDate(date);
  }, [value]);

  const dateFormat = () => {
    if (!value) {
      return (
        <Field
          name={source}
          validate={required ? [validateRequired] : null}
          component={renderField}
          placeholder="-"
          disabled={disabled}
        />
      );
    }
    const date = value;
    const formatedDate = getFormatedDate(date);
    return (
      <div className={`${s.displayDate} ${disabled ? s.disabled : ''}`}>
        <DateSmall className={s.dateIcon} />
        {formatedDate}
      </div>
    );
  };

  const handleCellClick = (e: any) => {
    shouldToggleLeft(e);
    setRef(myId);
    setFocus(true);
  };

  const handleDatePick = (date: any) => {
    const IsoDate = date.toISOString();
    change(source, IsoDate);
    setValue(IsoDate);
    setTimeout(() => {
      setFocus(false);
    }, 0);
  };

  const shouldToggleLeft = (e: any) => {
    const inputLeft = e.target?.getBoundingClientRect().left;
    const formLeft = document.getElementById(formName)?.getBoundingClientRect()
      .left;

    inputLeft && formLeft && setToggleLeft(inputLeft - formLeft > 300);
  };

  const resetDate = (e: any) => {
    e.stopPropagation();
    change(source, null);
    setValue(null);
    setFocus(false);
  };

  return (
    <div className={s.mainContainer}>
      {title && (
        <Text className={s.formInputLabel}>
          {title}
          {required && ' *'}
        </Text>
      )}
      {focus && currentInputRef === myId ? (
        <div onClick={(event: any) => event.stopPropagation()}>
          <DatePicker
            width="160px"
            // onChange={(date: any) => console.log(date)}
            onChange={(date: any) => handleDatePick(date)}
            popoverProps={{
              placement: toggleLeft ? 'bottom-end' : 'bottom',
            }}
            customInput={
              <Field
                validate={required ? [validateRequired] : null}
                name={source}
                component={renderField}
                placeholder="Pick a date"
              />
            }
            inputProps={{ prefix: null }}
            initialOpen={true}
          />
        </div>
      ) : (
        <div
          onClick={!disabled ? handleCellClick : () => {}}
          className={`${s.nonFocus} ${disabled ? s.disabled : ''}`}
        >
          {dateFormat()}
          {value && !disabled && (
            <X className={s.resetIcon} size="18" onClick={resetDate} />
          )}
        </div>
      )}
      {pendingChange && <div></div>}
      {pendingChange && (
        <Text className={s.changedFromText}>Changed from:</Text>
      )}
      {pendingChange && <div></div>}
      {pendingChange && (
        <Text className={s.pendingChangeText}>
          {getFormatedDate(initialValue)}
        </Text>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  request: state.requestModal.request,
  mode: state.requestModal.mode,
});

export default connect(mapStateToProps)(CustomDatePicker);
