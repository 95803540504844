import React from 'react';
import s from '../CustomMultiSelect.scss';
import { X } from 'wix-ui-icons-common';

const Tag = (props: any) => {
  const { value, icon, removeTag } = props;

  return (
    <div className={s.tagContainer}>
      <img className={s.tagIcon} src={icon} width="20" height="20" />
      <div className={s.tagText}>{value}</div>
      <div className={s.tagX} onClick={() => removeTag(value)}>
        <X size="18" />
      </div>
    </div>
  );
};

export default Tag;
