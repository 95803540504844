import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Text, Button, Search, IconButton } from "@wix/design-system";
import s from './EmployeesTableHeader.scss';
import { signOut } from '../redux/actions/authActions';
import { v4 as uuidv4 } from 'uuid';
import {
  applyDataFilters,
  displayModalMessage,
  setRef,
  setPagination,
  downloadSpreadSheet,
} from '../redux/actions/seatsActions';
import { setHcdFilters } from '../redux/actions/filterAction';
import { handleSearchEmployees } from '../redux/actions/employeesTableActions';
import { DocMoveto } from 'wix-ui-icons-common';

let debounceTimer: any = null;

const EmployeesTableHeader = (props: any) => {
  const {
    message,
    searchValue,
    setPagination,
    pagination,
    headCount,
    handleSearchEmployees,
    setHcdFilters,
    filteredHeadCountAmount,
    employeesSearchResult,
    downloadSpreadSheet,
  } = props;

  const [searchText, setSearchText] = useState('');

  const debounceSearch = (value: any) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
      debounceTimer = null;
    }
    debounceTimer = setTimeout(() => {
      handleSearchEmployees(value);
    }, 500);
  };

  const searchValueChange = (event: any) => {
    pagination > 0 && setPagination(0);
    const value = event.target.value;
    setSearchText(value);
    if (value.length === 0) {
      debounceSearch(' ');
      return;
    }
    debounceSearch(value);
  };

  const clearSearch = async () => {
    handleSearchEmployees(' ');
    return;
  };

  useEffect(() => {
    if (searchText !== searchValue) {
      setSearchText(searchValue);
    }
  }, [searchValue]);

  return (
    <div className={s.headerContainer}>
      <section className={s.headerSection}>
        <section className={s.headerSubSection}>
          <Text className={s.requests}>
            My Employees (
            {filteredHeadCountAmount !== null &&
            filteredHeadCountAmount !== headCount.length
              ? `${filteredHeadCountAmount}/${headCount.length}`
              : headCount.length}
            )
          </Text>
          {message ? (
            <section className={s.headerSubSection}>
              <div className={s[`${message.type}Message`]}>
                <Text className={s.messageText}>{message.text}</Text>
              </div>
            </section>
          ) : null}
        </section>
        <section className={s.headerSubSection}>
          <section className={s.filterSection}>
            <Button
              onClick={() => {
                const res = clearSearch();
                setSearchText('');
                setHcdFilters(null);
              }}
              className={`${s.filterButton} ${s.clearChangesButton}`}
            >
              Clear all filters
            </Button>
            <Search
              className={s.filterSearch}
              // onEnterPressed={searchHandler}
              onChange={searchValueChange}
              onClear={clearSearch}
              value={searchText}
              customInput="input"
              noLeftBorderRadius={true}
              noRightBorderRadius={true}
              placeholder="Search"
              clearButton={false}
            />
          </section>
        </section>
      </section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  seats: state.seats.seats,
  searchResults: state.seats.searchResults,
  currentCellRef: state.seats.currentCellRef,
  columnGroups: state.filter.columnGroups,
  searchValue: state.filter.searchValue,
  pagination: state.seats.pagination,
  headCount: state.headCount.headCount,
  employeesSearchResult: state.employeesTable.employeesSearchResult,
  filteredHeadCountAmount: state.employeesTable.filteredHeadCountAmount,
});

const mapDispatchToProps = (dispatch: any) => ({
  signOut: () => dispatch(signOut()),
  applyDataFilters: () => dispatch(applyDataFilters()),
  displayModalMessage: (message: any) => dispatch(displayModalMessage(message)),
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  handleSearchEmployees: (value: any) => dispatch(handleSearchEmployees(value)),
  setPagination: (value: number) => dispatch(setPagination(value)),
  downloadSpreadSheet: () => dispatch(downloadSpreadSheet()),
  setHcdFilters: (hcdFilters: any) => dispatch(setHcdFilters(hcdFilters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeesTableHeader);
