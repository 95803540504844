import React, { Fragment } from 'react';
import { Text, CloseButton } from "@wix/design-system";
import { connect } from 'react-redux';
import { closeModal } from '../../redux/actions/requestModalActions';
import s from './ModalHeader.scss';

const ModalHeader = (props: any) => {
  const { mode, closeModal } = props;
  return (
    <Fragment>
      <Text className={s.modalTitle}>{mode} Request</Text>
      <div className={s.grabLine}></div>
      <CloseButton
        size="small"
        className={s.xBtnContainer}
        onClick={closeModal}
      ></CloseButton>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  mode: state.requestModal.mode,
});

const mapDispatchToProps = (dispatch: any) => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalHeader);
