import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Modal } from "@wix/design-system";
import s from './RequestModal.scss';
import store from '../redux/store';
import {
  closeModal,
  submit,
  initFormValues,
} from '../redux/actions/requestModalActions';
import ModalHeader from './ModalHeader/ModalHeader';
import Form from './Form/Form';
import ModalButtons from './ModalButtons/ModalButtons';
import ModalToolTip from './Form/ModalToolTip/ModalToolTip';

const moveModal = (pageX: number, pageY: number) => {
  const requestModal = document.getElementById('request-modal');
  const modalUpperSection = document.getElementById(s.modalUpperSection);
  if (!requestModal || !modalUpperSection) {
    return;
  }
  requestModal.style.left = `${pageX - modalUpperSection.offsetWidth / 2}px`;
  requestModal.style.top = `${pageY - modalUpperSection.offsetHeight / 2}px`;
};

const onMouseMove = (e: any) => moveModal(e.pageX, e.pageY);

const mouseUp = () => {
  const requestModal = document.getElementById('request-modal');
  if (!requestModal) {
    return;
  }

  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', mouseUp);
};

const escFunction = (event: any) => {
  if (event.keyCode === 27) {
    const state = store.getState();
    const { currentInputRef } = state.requestModal;
    !currentInputRef && store.dispatch(closeModal() as any);
  }
};

const RequestModal = (props: any) => {
  const {
    closeModal,
    isModalOpen,
    initFormValues,
    request,
    submit,
    mode,
  } = props;

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mouseup', mouseUp);
      document.addEventListener('keydown', escFunction);
    } else {
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('keydown', escFunction);
    }
  }, [isModalOpen]);

  const mouseDown = (e: any) => {
    const requestModal = document.getElementById('request-modal');
    if (!requestModal) {
      return;
    }

    requestModal.style.position = 'absolute';
    requestModal.style.zIndex = '1000';

    document.addEventListener('mousemove', onMouseMove);
  };

  if (request && Object.values(request).length > 0) {
    const temp = { ...request };
    // if (mode === 'New') {
    //   delete temp.replacementName;
    //   delete temp.replacementDate;
    // }
    initFormValues(temp, false);
  }

  return (
    <Modal horizontalPosition="start" isOpen={isModalOpen}>
      <div className={s.modalContainer} onMouseUp={mouseUp}>
        <div id="request-modal" className={s.modalBox}>
          <section id={s.modalUpperSection} onMouseDown={mouseDown}>
            <ModalHeader />
          </section>
          <section id={s.modalMiddleSection}>
            <Form onSubmit={submit} />
          </section>
          <section id={s.modalLowerSection}>
            <ModalButtons />
          </section>
          <ModalToolTip />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  isModalOpen: state.requestModal.isModalOpen,
  request: state.requestModal.request,
  mode: state.requestModal.mode,
});

const mapDispatchToProps = (dispatch: any) => ({
  closeModal: () => dispatch(closeModal()),
  submit: () => dispatch(submit()),
  initFormValues: (data: any, keepDirty: boolean) =>
    dispatch(initFormValues(data, keepDirty)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestModal);
