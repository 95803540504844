import React from 'react';
import { connect } from 'react-redux';
import { Text } from "@wix/design-system";
import { WixChat } from 'wix-ui-icons-common';
import { setShowContactUsModal } from '../redux/actions/modalsActions';
import s from './Footer.scss';

const Footer = (props: any) => {
  const { setShowContactUsModal } = props;

  return (
    <div className={s.footer}>
      <Text className={s.footerText}>Powered by Wix Internal Systems</Text>
      <div className={s.button} onClick={() => setShowContactUsModal(true)}>
        <Text className={s.buttonText}> Give us feedback </Text>
        <WixChat className={s.icon}></WixChat>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  setShowContactUsModal: (isShow: any) =>
    dispatch(setShowContactUsModal(isShow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
