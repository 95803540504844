import { httpClient } from '../../../../Client/httpClient';
import { AUTH } from './constants';
import { setMessage } from './seatsActions';
import {
  SETTINGS_SAVED_MESSAGE,
  DEFAULT_FAILURE_MESSAGE,
} from '../../../../Client/config';

export const signIn = (code: any) => async (dispatch: any) => {
  try {
    const { data } = await httpClient.get(`/security/login?code=${code}`);
    dispatch({
      payload: data,
      type: AUTH.SIGNIN,
    });
  } catch (error: any) {
    console.error(error);
  }
};

export const signOut = () => (dispatch: any) => {
  dispatch({
    type: AUTH.SIGNOUT,
  });
};

export const authorize = () => async (dispatch: any) => {
  try {
    const { data } = await httpClient.get('security/authorize');
    dispatch({
      payload: data,
      type: AUTH.SIGNIN,
    });
  } catch (error: any) {
    dispatch(signOut());
    console.log(error);
  }
};

export const setOriginalPath = (path: string) => (dispatch: any) => {
  path = path.replace('wix-seats-stage/', '');
  path = path.replace('wix-seats-stage', '');
  dispatch({
    payload: path,
    type: AUTH.ORIGINAL_PATH,
  });
};

export const setUserPermission = (permission: number) => async (
  dispatch: any,
  getState: any,
) => {
  const res = await httpClient.put('/user/set_user_permission', {
    permission,
  });
  if (res.status === 200) {
    window.location.reload();
  }
};

export const displayUserSettingsModal = (isShow: boolean) => async (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    payload: isShow,
    type: AUTH.DISPLAY_USER_SETTINGS_MODAL,
  });
};

export const updateHeadOfDelegates = (delegates: any) => async (
  dispatch: any,
  getState: any,
) => {
  try {
    const res = await httpClient.put(
      '/seats/bulkUpdateHeadOfDelegates',
      delegates,
    );
    if (res.status === 200) {
      dispatch({
        payload: delegates,
        type: AUTH.HEAD_OF_DELEGATES,
      });
      dispatch(
        setMessage(
          {
            type: 'success',
            text: SETTINGS_SAVED_MESSAGE,
          },
          5000,
        ),
      );
    } else {
      dispatch(
        setMessage(
          {
            type: 'fail',
            text: DEFAULT_FAILURE_MESSAGE,
          },
          5000,
        ),
      );
    }
  } catch (error) {
    console.log('error', error);
    dispatch(
      setMessage(
        {
          type: 'fail',
          text: DEFAULT_FAILURE_MESSAGE,
        },
        5000,
      ),
    );
  }
};

export const setDelegates = () => async (dispatch: any, getState: any) => {
  const res = await httpClient.get('/seats/getHeadOfDelegates');
  let delegates = res.data.data;
  const { avatarsHash } = getState().dropdowns;
  if (delegates && delegates.length !== 0) {
    delegates = delegates.map((delegate: any) => {
      return {
        ...delegate,
        delegate: { ...delegate.delegate, icon: avatarsHash[delegate.value] },
      };
    });
    dispatch({
      payload: delegates,
      type: AUTH.HEAD_OF_DELEGATES,
    });
  }
};
