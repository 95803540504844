import { REQUEST } from './constants';
import diff from 'object-diff';
import { httpClient } from '../../../../Client/httpClient';
import { initialize, formValueSelector } from 'redux-form';
import {
  setMessage,
  setSeatsLocal,
  setChangedSeats,
  setRefreshSeatsTable,
} from './seatsActions';
import { convertSeat, types } from '../../../../Client/idsConvertor';
import {
  DEFAULT_SUCCESS_MESSAGE,
  PENDING_SUCCESS_MESSAGE,
  DEFAULT_FAILURE_MESSAGE,
} from '../../../../Client/config';

export const formName = 'requestModalForm';

export const setRequest = (request: any) => (dispatch: any) => {
  dispatch({
    type: REQUEST.SET_REQUEST,
    payload: request,
  });
};

export const setOpenNew = (openNew: boolean) => (dispatch: any) => {
  dispatch({
    type: REQUEST.SET_OPEN_NEW,
    payload: openNew,
  });
};

export const initFormValues = (data: any, keepDirty: boolean) => (
  dispatch: any,
) => dispatch(initialize(formName, data, keepDirty));

export const submit = () => (dispatch: any, getState: any) => {
  const { mode } = getState().requestModal;
  switch (mode) {
    case 'New': {
      dispatch(createRequest());
      break;
    }
    case 'Edit': {
      dispatch(updateRequest());
      break;
    }
    default: {
      dispatch(
        setMessage({ type: 'fail', text: DEFAULT_FAILURE_MESSAGE }, 2000),
      );
    }
  }
};

export const createRequest = () => async (dispatch: any, getState: any) => {
  try {
    const { values } = getState().form[formName];
    const { openNew } = getState().requestModal;
    const { seats } = getState().seats;
    const { columnGroups } = getState().filter;
    const { user } = getState().auth;
    // Creating hash of fields that are allowed to be edited
    const editPermissionsHash: any = { requesterEmail: true };
    for (const group of columnGroups) {
      for (const column of group.columns) {
        if (column.edit) {
          editPermissionsHash[column.source] = true;
        }
      }
    }
    // Delete `null` values and fields that are not allowed to bo edited
    Object.entries(values).map(([key, value]) => {
      if (!value || value === '-' || !editPermissionsHash[key]) {
        delete values[key];
      }
    });

    if (Object.values(values).length > 0) {
      dispatch({
        type: REQUEST.SUBMIT_REQUEST,
      });

      const { amountOfCopies } = getState().requestModal;
      if (amountOfCopies > 1) {
        delete values.guildPriority;
      }
      const seatsPromiseArray: any = [];

      for (let index = 0; index < amountOfCopies; index++) {
        seatsPromiseArray.push(httpClient.post(`/seats/seat`, values));
      }

      const seatsResponseArray: any = await Promise.all(seatsPromiseArray);

      const dateTime = new Date();
      const userNameEndIndex = user.email.indexOf('@');
      const username = user.email.substring(0, userNameEndIndex);
      const newSeats = seats;

      if (values.guildPriority) {
        const { data } = seatsResponseArray[0];
        for (const changedSeatId in data.seat) {
          const changedSeat = data.seat[changedSeatId];
          // const updatedAt = changedSeat.updatedAt;
          const updatedIndex: number = newSeats.findIndex(
            (seat: any) => seat._id == changedSeatId,
          );
          const dateTime = new Date();
          const userNameEndIndex = user.email.indexOf('@');
          const username = user.email.substring(0, userNameEndIndex);
          if (updatedIndex !== -1) {
            newSeats[updatedIndex] = {
              ...newSeats[updatedIndex],
              updatedAt: dateTime,
              statusReasonUpdater: username,
              statusReasonUpdateTime: dateTime,
            };
            for (const param in changedSeat) {
              newSeats[updatedIndex][param] = changedSeat[param];
            }
            if (data.wixPriorityChanges) {
              for (const seat of newSeats) {
                seat.wixPriority =
                  data.wixPriorityChanges[seat._id] || seat.wixPriority;
              }
              // refreshGuildPriority(seats);
            }
          } else {
            newSeats.push({
              ...changedSeat[0],
              status,
              newSeat: true,
              createdAt: data.seat.createdAt,
              mySeat: true,
              statusReasonUpdater: username,
              statusReasonUpdateTime: dateTime,
            });
            // Update localy
            // setting the new wixpriorities based on the change
            if (data.wixPriorityChanges) {
              for (const seat of newSeats) {
                seat.wixPriority =
                  data.wixPriorityChanges[seat._id] || seat.wixPriority;
              }
              // refreshGuildPriority(seats);
            }
          }
        }
      } else {
        for (const response of seatsResponseArray) {
          if (response.status !== 200) {
            throw new Error('Submit failed.');
          }
          const { data } = response;
          newSeats.unshift({
            ...data.seat,
            newSeat: true,
            createdAt: data.seat.createdAt,
            mySeat: true,
            statusReasonUpdater: username,
            statusReasonUpdateTime: dateTime,
          });
          // Update localy
          // setting the new wixpriorities based on the change
          // if (data.wixPriorityChanges) {
          //   for (const seat of newSeats) {
          //     seat.wixPriority =
          //       data.wixPriorityChanges[seat._id] || seat.wixPriority;
          //   }
          //   refreshGuildPriority(seats);
          // }
        }
      }

      dispatch(setSeatsLocal(newSeats));
      dispatch(setRefreshSeatsTable(true));
      dispatch(setChangedSeats({}));

      dispatch(
        setMessage(
          {
            type: 'success',
            text: 'Your new request was submitted successfully.',
          },
          2000,
        ),
      );

      dispatch({
        type: REQUEST.SUBMIT_SUCCESS,
        payload: openNew,
      });
    }
  } catch (error: any) {
    console.error('error in create request: ', error);
    dispatch({
      type: REQUEST.SUBMIT_FAILURE,
    });
    dispatch(setMessage({ type: 'fail', text: DEFAULT_FAILURE_MESSAGE }, 5000));
  } finally {
    dispatch({
      type: REQUEST.SET_AMOUNT_OF_COPIES,
      payload: 1,
    });
  }
};

export const updateRequest = () => async (dispatch: any, getState: any) => {
  try {
    const { values, initial } = getState().form[formName];
    const { openNew } = getState().requestModal;
    const { seats } = getState().seats;

    const changedValues = diff(initial, values);

    if (
      initial.pendingChanges &&
      Object.keys(initial.pendingChanges).length > 0
    ) {
      for (const param in initial.pendingChanges) {
        if (
          initial.pendingChanges[param] !== values[param] &&
          !changedValues[param]
        ) {
          changedValues[param] = values[param];
        }
      }
    }

    if (Object.values(changedValues).length > 0) {
      dispatch({
        type: REQUEST.SUBMIT_REQUEST,
      });

      const { user } = getState().auth;
      const { data } = await httpClient.put(
        `/seats/seat/${initial._id}`,
        changedValues,
      );

      if (!data.success) {
        throw new Error('Submit failed.');
      }

      //     Update localy
      const temp = seats;
      let successMessage = DEFAULT_SUCCESS_MESSAGE;
      for (const changedSeatId in data.seats) {
        const changedSeat = data.seats[changedSeatId];
        // const updatedAt = changedSeat.updatedAt;
        const updatedIndex: number = temp.findIndex(
          (seat: any) => seat._id == changedSeatId,
        );
        const dateTime = new Date();
        const userNameEndIndex = user.email.indexOf('@');
        const username = user.email.substring(0, userNameEndIndex);
        temp[updatedIndex] = {
          ...temp[updatedIndex],
          updatedAt: dateTime,
          statusReasonUpdater: username,
          statusReasonUpdateTime: dateTime,
        };
        for (const param in changedSeat) {
          if (param === 'pendingChanges') {
            temp[updatedIndex].pendingChanges = {
              ...temp[updatedIndex].pendingChanges,
              ...changedSeat.pendingChanges,
            };
            successMessage = PENDING_SUCCESS_MESSAGE;
            continue;
          }
          temp[updatedIndex][param] = changedSeat[param];
          if (
            (user.permission === 3 || user.permission === 4) &&
            temp[updatedIndex].pendingChanges &&
            temp[updatedIndex].pendingChanges[param]
          ) {
            delete temp[updatedIndex].pendingChanges[param];
          }
        }
        if (
          temp[updatedIndex].pendingChanges &&
          Object.keys(temp[updatedIndex].pendingChanges).length === 0
        ) {
          delete temp[updatedIndex].pendingChanges;
        }
        // if (data.wixPriorityChanges) {
        //   for (const seat of temp) {
        //     seat.wixPriority =
        //       data.wixPriorityChanges[seat._id] || seat.wixPriority;
        //   }
        //   refreshGuildPriority(seats);
        // }
      }
      dispatch(setSeatsLocal(temp));
      dispatch(setRefreshSeatsTable(true));
      dispatch(setChangedSeats({}));
      dispatch(
        setMessage(
          {
            type: 'success',
            text: successMessage,
          },
          5000,
        ),
      );

      dispatch({
        type: REQUEST.SUBMIT_SUCCESS,
        payload: openNew,
      });
    }
  } catch (error: any) {
    console.error('error in update request: ', error);
    dispatch({
      type: REQUEST.SUBMIT_FAILURE,
    });
    dispatch(setMessage({ type: 'fail', text: DEFAULT_FAILURE_MESSAGE }, 5000));
  }
};

export const saveAsDraft = () => async (dispatch: any) => {
  try {
    dispatch(closeModal());

    // fetch :0
    await new Promise((resolve) => {
      setTimeout(resolve, 1500);
    });
  } catch (error: any) {
    console.error(error);
  }
};

export const openModal = () => async (dispatch: any) => {
  dispatch({
    type: REQUEST.OPEN_MODAL,
  });
};

export const closeModal = () => (dispatch: any) => {
  dispatch({
    type: REQUEST.SET_CURRENT_DROPDOWN_VALUE,
    payload: null,
  });
  dispatch({
    type: REQUEST.CLOSE_MODAL,
  });
};

export const setMode = (mode: string) => (dispatch: any) => {
  dispatch({
    type: REQUEST.SET_MODE,
    payload: mode,
  });
};

export const setCurrentDropdownValue = (value: any) => (dispatch: any) => {
  dispatch({
    type: REQUEST.SET_CURRENT_DROPDOWN_VALUE,
    payload: value,
  });
};

export const setAmountOfCopies = (value: number) => (dispatch: any) => {
  dispatch({
    type: REQUEST.SET_AMOUNT_OF_COPIES,
    payload: value,
  });
};

export const setRef = (ref: any) => (dispatch: any) => {
  setTimeout(() => {
    dispatch({
      type: REQUEST.SET_INPUT_REF,
      payload: ref,
    });
  }, 20);
};
export const clearInputRef = () => (dispatch: any, getState: any) => {
  dispatch({
    type: REQUEST.SET_INPUT_REF,
    payload: null,
  });
};

export const getValue = (source: string) => (dispatch: any, getState: any) => {
  const selector = formValueSelector(formName);
  const value = selector(getState(), source);
  return value;
};

// TODO: add this isHidden to form item
export const isHidden = (hidder: any) => (dispatch: any) => {
  if (!hidder) {
    return false;
  }

  const { source, showValues } = hidder;
  for (const sourceName of source) {
    const hidderValue = dispatch(getValue(sourceName));
    if (hidderValue && showValues.includes(hidderValue)) {
      return false;
    }
  }
  return true;
};

export const showModalTooltip = (text: string, x: string, y: string) => (
  dispatch: any,
) => {
  dispatch({
    payload: {
      tooltipY: y,
      tooltipX: x,
      tooltipText: text,
    },
    type: REQUEST.SHOW_MODAL_TOOLTIP,
  });
};
export const hideModalTooltip = () => (dispatch: any) => {
  dispatch({
    type: REQUEST.HIDE_MODAL_TOOLTIP,
  });
};

export const handleOpenEOEReplacement = (queryParams: any) => async (
  dispatch: any,
) => {
  let newRequest: any = { replacementType: 'Replacement (EOE)' };
  queryParams.forEach(function (value: any, key: any) {
    if (key === 'FTE_PTE' || key === 'STUDENT_INTERN') {
      newRequest = {
        ...newRequest,
        [oracleToSeatsHash[key]]: positionTypeHash[value],
      };
      return;
    }
    if (key === 'EOE_DATE') {
      const date = new Date(value);
      const qRequest = getQ(date);
      newRequest = {
        ...newRequest,
        [oracleToSeatsHash[key]]: date.toISOString(),
        qRequest,
      };
      return;
    }
    newRequest = { ...newRequest, [oracleToSeatsHash[key]]: value };
  });
  const convertedNewRequest = await convertSeat(newRequest, types.ID_TO_NAME);
  dispatch(setMode('New'));
  dispatch(setRequest(convertedNewRequest));
  dispatch(openModal());
};

const getQ = (date: Date) => {
  const month = date.getMonth() + 1;
  const quarter = getQuarter(month);
  const year = date.getFullYear() - 2000;
  return `${quarter}/${year}`;
};

const getQuarter = (month: number) => {
  if (month > 6) {
    if (month > 9) {
      return 4;
    }
    return 3;
  }
  if (month > 3) {
    return 2;
  }
  return 1;
};

const oracleToSeatsHash: any = {
  LINE_MANAGER: 'requester',
  POSITION_TITLE: 'positionName',
  GUILD_TOP_ID: 'guild',
  GUILD_ID: 'subGuild',
  DEPARTMANT_TOP_ID: 'company',
  DEPARTMANT_FULL_ID: 'subTeam',
  SITE_ID: 'site',
  FTE_PTE: 'type',
  STUDENT_INTERN: 'type',
  TEMP_FLAG: 'temp',
  EMPLOYEE_NUMBER: 'replacementName',
  EOE_DATE: 'replacementDate',
};

const positionTypeHash: any = {
  FTE: 'Full time',
  PTE: 'Part time',
  STUDENT: 'Student',
  INTERN: 'Intern',
};
