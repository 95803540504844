import { FILTERS } from '../actions/constants';
import { getColumnsByPermission } from './columns/permissionsConfig';

// interface Column {
//   title: String;
//   source: String;
//   size: Number;
//   display: Boolean;
// }

const initState: any = {
  columnGroups: null,
  fieldsFilter: null,
  dataFilters: null,
  searchValue: null,
  showSaveViewModal: false,
  views: null,
  currentView: null,
  hcdFilters: null,
};

export default (state = initState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case FILTERS.GET_FIELDS_FILTER:
      return { ...state };
    case FILTERS.SET_FIELDS_FILTER:
      return { ...state, fieldsFilter: payload };
    case FILTERS.GET_DATA_FILTERS:
      return { ...state };
    case FILTERS.SET_DATA_FILTERS:
      return { ...state, dataFilters: payload };
    case FILTERS.GET_COLUMN_GROUPS:
      return { ...state };
    case FILTERS.SET_COLUMN_GROUPS:
      return { ...state, columnGroups: payload };
    case FILTERS.SET_PERMISSIONS:
      return { ...state, columnGroups: getColumnsByPermission(payload) };
    case FILTERS.SET_SEARCH_VALUE:
      return { ...state, searchValue: payload };
    case FILTERS.SET_SHOW_SAVE_VIEW_MODAL:
      return { ...state, showSaveViewModal: payload };
    case FILTERS.SET_VIEWS:
      return { ...state, views: payload };
    case FILTERS.SET_CURRENT_VIEW:
      return { ...state, currentView: payload };
    case FILTERS.HCD_DATA_FILTERS:
      return { ...state, hcdFilters: payload };
    default:
      return state;
  }
};
