import { HttpClient } from '@wix/http-client';

export let httpClient: HttpClient;

export const initHttpClient = (baseURL: string) => {
  if (baseURL.includes('stage')) {
    baseURL += '/wix-seats-stage';
  }
  httpClient = new HttpClient({ baseURL });
};
