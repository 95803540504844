import { httpClient } from '../../../../Client/httpClient';
import lodash from 'lodash';
import { DROPDOWNS } from './constants';
import { getMergedSeats } from './seatsActions';
import { guildPriorityStatuses } from '../../../../Client/config';
import { initHashIdNamesCache } from '../../../../Client/idsConvertor';
import { initPrePendingNames } from '../../../../Client/utils';

const subGuildToGuild: any = {};
const teamToCompany: any = {};

export const setDropdownData = () => async (dispatch: any) => {
  try {
    const { data } = await httpClient.get(`/data/getDropDownData`);
    const dropdownsData = data;
    for (const guild of dropdownsData.guild.data) {
      for (const subGuild of guild.subs) {
        subGuildToGuild[subGuild.value] = guild.value;
      }
    }
    for (const company of dropdownsData.company.data) {
      for (const team of company.subs) {
        teamToCompany[team.value] = company.value;
      }
    }
    initHashIdNamesCache(dropdownsData);
    dropdownsData.employeeName = dropdownsData.replacementName;
    dropdownsData.employeeName.data.unshift({ label: '-', value: '-' });
    dispatch(setAvatars(dropdownsData.avatarsHash));
    delete dropdownsData.avatarsHash;
    dispatch({
      type: DROPDOWNS.SET_DROPDOWNS,
      payload: dropdownsData,
    });
    initPrePendingNames();
  } catch (error: any) {
    console.error(error);
  }
};
export const updateDropdownFilterOptionsInstances = (seats: any) => async (
  dispatch: any,
  getState: any,
) => {
  try {
    const { dropdowns } = getState().dropdowns;
    const dropdownsOptions = lodash.cloneDeep(dropdowns);
    dropdownsOptions.budgetId = {
      data: [
        { value: 'MK', label: 'MK' },
        { value: 'R&D', label: 'R&D' },
        { value: 'G&A', label: 'G&A' },
        { value: 'COS', label: 'COS' },
      ],
    };
    const dropdownKeys = Object.keys(dropdownsOptions);
    seats.forEach((seat: any) => {
      for (const param in seat) {
        if (
          !dropdownKeys.includes(param) &&
          param !== 'subGuild' &&
          param !== 'subTeam' &&
          param !== 'pendingChanges'
        ) {
          continue;
        }
        let column;
        if (param === 'subGuild') {
          const guildName = subGuildToGuild[seat[param]];
          for (const guild of dropdownsOptions.guild.data) {
            if (guild.value === guildName) {
              column = guild.subs;
              break;
            }
          }
        }
        if (param === 'subTeam') {
          const companyName = teamToCompany[seat[param]];
          for (const company of dropdownsOptions.company.data) {
            if (company.value === companyName) {
              column = company.subs;
              break;
            }
          }
        }
        if (param === 'pendingChanges') {
          column = dropdownsOptions.status.data;
        }
        if (!column) {
          if (!dropdownsOptions[param]) {
            continue;
          }
          column = dropdownsOptions[param].data;
        }
        if (column) {
          for (const option of column) {
            if (
              option.value === seat[param] ||
              (option.label === 'Modified' && param === 'pendingChanges')
            ) {
              option.instances = option.instances ? option.instances + 1 : 1;
              break;
            }
          }
        }
      }
    });
    // dispatch({
    //   type: DROPDOWNS.SET_DROPDOWNS,
    //   payload: dropdownsOptions,
    // });
    dispatch(setDropdownFilterOptions(dropdownsOptions));
  } catch (error: any) {
    console.error(error);
  }
};
const setDropdownFilterOptions = (dropdownsOptions: any) => async (
  dispatch: any,
  getState: any,
) => {
  const dropdownFilterOptions: any = {};
  for (const columnName in dropdownsOptions) {
    const column = dropdownsOptions[columnName];
    dropdownFilterOptions[columnName] = { data: [] };
    column.data.forEach((option: any) => {
      if (option.instances && option.instances > 0) {
        dropdownFilterOptions[columnName].data.push({ ...option });
        if (columnName === 'company' || columnName === 'guild') {
          const lastItemIndex =
            dropdownFilterOptions[columnName].data.length - 1;
          dropdownFilterOptions[columnName].data[lastItemIndex].subs = [];
          option.subs.forEach((subOption: any) => {
            if (subOption.instances && subOption.instances > 0) {
              dropdownFilterOptions[columnName].data[lastItemIndex].subs.push({
                ...subOption,
              });
            }
          });
        }
      }
    });
  }
  dispatch({
    type: DROPDOWNS.SET_DROPDOWNS_FILTER_OPTIONS,
    payload: dropdownFilterOptions,
  });
};
export const setDropdownInstances = () => async (
  dispatch: any,
  getState: any,
) => {
  const { viewId } = getState().seats;
  const { data } = await httpClient.get(`/data/getDropdownInstances/${viewId}`);
  dispatch({
    type: DROPDOWNS.SET_DROPDOWNS_FILTER_OPTIONS,
    payload: data.dropdownInstances,
  });
};

export const setAvatars = (avatarsHash: any) => async (dispatch: any) => {
  // const avatars: any = {};
  // for (const requester of requestersData) {
  //   avatars[requester.label] = requester.icon;
  // }
  try {
    dispatch({
      type: DROPDOWNS.SET_AVATARS,
      payload: avatarsHash,
    });
  } catch (error: any) {
    console.error(error);
  }
};

export const setGuildPriorityDropdownData = () => async (
  dispatch: any,
  getState: any,
) => {
  const { seats } = getState().seats;
  const filteredSeats = seats.filter(
    (seat: any) => seat.guildPriority && seat.status !== 6,
  );
  const guildPriorityDropdownData = calculateGuildPriorityDropdownData(
    filteredSeats,
  );
  dispatch({
    type: DROPDOWNS.SET_GUILD_PRIORITY_DROPDOWN_DATA,
    payload: guildPriorityDropdownData,
  });
};

export const updateGuildPriorityDropdownData = (changedSeats: any) => async (
  dispatch: any,
  getState: any,
) => {
  const { seats } = getState().seats;
  const mergedSeats = getMergedSeats(seats, changedSeats);
  const filteredSeats = mergedSeats.filter(
    (seat: any) =>
      seat.guildPriority &&
      seat.guildPriority !== '-' &&
      guildPriorityStatuses.includes(seat.status),
  );
  const guildPriorityDropdownData = calculateGuildPriorityDropdownData(
    filteredSeats,
  );
  dispatch({
    type: DROPDOWNS.SET_GUILD_PRIORITY_DROPDOWN_DATA,
    payload: guildPriorityDropdownData,
  });
};

const calculateGuildPriorityDropdownData = (seats: any) => {
  let guildPriorities: any = {};
  for (const seat of seats) {
    if (
      guildPriorities[seat.subGuild] &&
      guildPriorities[seat.subGuild][seat.site]
    ) {
      guildPriorities[seat.subGuild][seat.site].push({
        label: guildPriorities[seat.subGuild][seat.site].length,
        value: guildPriorities[seat.subGuild][seat.site].length,
      });
    } else {
      const options = [
        {
          label: '-',
          value: '-',
        },
        {
          label: 1,
          value: 1,
        },
      ];
      guildPriorities = {
        ...guildPriorities,
        [seat.subGuild]: {
          ...guildPriorities[seat.subGuild],
          [seat.site]: options,
        },
      };
    }
  }
  return guildPriorities;
};
