export const AUTH = {
  SIGNIN: 'SIGNIN',
  SIGNOUT: 'SIGNOUT',
  ORIGINAL_PATH: 'ORIGINAL_PATH',
  DISPLAY_USER_SETTINGS_MODAL: 'DISPLAY_USER_SETTINGS_MODAL',
  HEAD_OF_DELEGATES: 'HEAD_OF_DELEGATES',
};

export const SEATS = {
  GET_SEATS: 'GET_SEATS',
  SET_SEATS: 'SET_SEATS',
  SET_SEAT_ID: 'SET_SEAT_ID',
  SEARCH_SEATS: 'SEARCH_SEATS',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_SORT: 'SET_SORT',
  SET_REF: 'SET_REF',
  SET_CHANGED_SEATS: 'SET_CHANGED_SEATS',
  SET_MERGED_SEATS: 'SET_MERGED_SEATS',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_MODAL_MESSAGE: 'SET_MODAL_MESSAGE',
  SET_JUSTIFICATION_MODAL_MESSAGE: 'SET_JUSTIFICATION_MODAL_MESSAGE',
  SET_FILTERING_COLUMNS: 'SET_FILTERING_COLUMNS',
  SET_STATUS_JUSTIFICATIONS: 'SET_STATUS_JUSTIFICATIONS',
  SET_VIEW_ID: 'SET_VIEW_ID',
  SET_VIEW_START: 'SET_VIEW_START',
  SET_VIEW_END: 'SET_VIEW_END',
  SET_SEATS_COUNT: 'SET_SEATS_COUNT',
  SET_AMOUNT_OF_SEATS_BY_TEAM_AND_STATUS:
    'SET_AMOUNT_OF_SEATS_BY_TEAM_AND_STATUS',
};
export const DROPDOWNS = {
  SET_DROPDOWNS: 'SET_DROPDOWNS',
  SET_DROPDOWNS_FILTER_OPTIONS: 'SET_DROPDOWNS_FILTER_OPTIONS',
  SET_GUILD_PRIORITY_DROPDOWN_DATA: 'SET_GUILD_PRIORITY_DROPDOWN_DATA',
  SET_AVATARS: 'SET_AVATARS',
};

export const FILTERS = {
  GET_COLUMN_GROUPS: 'GET_COLUMN_GROUPS',
  SET_COLUMN_GROUPS: 'SET_COLUMN_GROUPS',
  GET_FIELDS_FILTER: 'GET_FIELDS_FILTER',
  SET_FIELDS_FILTER: 'SET_FIELDS_FILTER',
  GET_DATA_FILTERS: 'GET_DATA_FILTERS',
  SET_DATA_FILTERS: 'SET_DATA_FILTERS',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
  SET_SHOW_SAVE_VIEW_MODAL: 'SET_SHOW_SAVE_VIEW_MODAL',
  SET_VIEWS: 'SET_VIEWS',
  SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
  HCD_DATA_FILTERS: 'HCD_DATA_FILTERS',
};

export const REQUEST = {
  SET_AMOUNT_OF_COPIES: 'SET_AMOUNT_OF_COPIES',
  SET_REQUEST: 'SET_REQUEST',
  SUBMIT_REQUEST: 'SUBMIT_REQUEST',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SET_MODE: 'SET_MODE',
  SET_INPUT_REF: 'SET_INPUT_REF',
  SET_CURRENT_DROPDOWN_VALUE: 'SET_CURRENT_DROPDOWN_VALUE',
  SET_OPEN_NEW: 'SET_OPEN_NEW',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAILURE: 'SUBMIT_FAILURE',
  SHOW_MODAL_TOOLTIP: 'SHOW_MODAL_TOOLTIP',
  HIDE_MODAL_TOOLTIP: 'HIDE_MODAL_TOOLTIP',
};

export const DATA = {
  SET_REQUEST: 'SET_REQUEST',
  SUBMIT_REQUEST: 'SUBMIT_REQUEST',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
};

export const FORM = {
  SET_FIELD: 'SET_FIELD',
  UNSET: 'UNSET',
};

export const TOOLTIP = {
  SHOW_TOOLTIP: 'SHOW_TOOLTIP',
  HIDE_TOOLTIP: 'HIDE_TOOLTIP',
};

export const TABLE = {
  TABLE_MODE: 'TABLE_MODE',
  REFRESH_SEATS_TABLE: 'REFRESH_SEATS_TABLE',
  SHOW_SEATS_TABLE_LOADER: 'SHOW_SEATS_TABLE_LOADER',
};

export const HEAD_COUNT = {
  SET_HEAD_COUNT: 'SET_HEAD_COUNT',
  SET_TARGET_HEAD_COUNT: 'SET_TARGET_HEAD_COUNT',
  SET_HEAD_COUNT_BY_TEAM: 'SET_HEAD_COUNT_BY_TEAM',
  SET_TOP_COMPANIES: 'SET_TOP_COMPANIES',
  SET_TOP_TEAMS: 'SET_TOP_TEAMS',
  SET_SUB_TEAMS: 'SET_SUB_TEAMS',
};

export const EMPLOYEES_TABLE = {
  COLUMN_GROUPS: 'COLUMN_GROUPS',
  EMPLOYEES_DATA: 'EMPLOYEES_DATA',
  EMPLOYEES_SEARCH_RESULT: 'EMPLOYEES_SEARCH_RESULT',
  SEARCH_VALUE: 'SEARCH_VALUE',
  FILTERED_HEAD_COUNT_AMOUNT: 'FILTERED_HEAD_COUNT_AMOUNT',
};

export const MODALS = {
  SHOW_CONTACT_US_MODAL: 'SHOW_CONTACT_US_MODAL',
};
