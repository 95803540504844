import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './UserSettingsModal.scss';
import { Modal, Text, Button, TextButton } from "@wix/design-system";
import { setRef, clearRef } from '../../../../redux/actions/seatsActions';
import {
  displayUserSettingsModal,
  setUserPermission,
  updateHeadOfDelegates,
} from '../../../../redux/actions/authActions';
import { permissions } from '../../../../../../Client/config';
import Delegate from './Delegate/Delegate';
import ModalHeader from './ModalHeader/ModalHeader';
import { v4 as uuidv4 } from 'uuid';
import CustomDropdown from '../../../../CustomDropdown/CustomDropdown';
import { ChevronDown, ChevronUp } from 'wix-ui-icons-common';

const moveModal = (pageX: number, pageY: number) => {
  const UserSettingsModal = document.getElementById('user-settings-modal');
  const modalUpperSection = document.getElementById(s.modalUpperSection);
  if (!UserSettingsModal || !modalUpperSection) {
    return;
  }
  UserSettingsModal.style.left = `${
    pageX - modalUpperSection.offsetWidth / 2
  }px`;
  UserSettingsModal.style.top = `${
    pageY - modalUpperSection.offsetHeight / 2
  }px`;
};

const onMouseMove = (e: any) => moveModal(e.pageX, e.pageY);

const mouseUp = () => {
  const UserSettingsModal = document.getElementById('user-settings-modal');
  if (!UserSettingsModal) {
    return;
  }

  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', mouseUp);
};

const UserSettingsModal = (props: any) => {
  const {
    isUserSettingsModalShown,
    displayUserSettingsModal,
    updateHeadOfDelegates,
    user,
    headOfDelegates,
    avatarsHash,
    setUserPermission,
    currentCellRef,
    setRef,
    clearRef,
  } = props;

  const [selectedPermission, setSelectedPermission] = useState<any>(
    user.permission,
  );
  const [userDelegates, setUserDelegates] = useState<any>(null);
  const [focusDropdown, setFocusDropdown] = useState<any>(false);
  const [permissionsDropdownId, setPermissionDropdownId] = useState(uuidv4());
  const [isDelegatesChanged, setIsDelegatesChanged] = useState<any>(false);
  const [isEmptyDelegatesValues, setIsEmptyDelegatesValues] = useState<any>(
    false,
  );

  const escFunction = (event: any) => {
    if (event.keyCode === 27) {
      event.stopPropagation();
      onClose();
    }
  };

  const getDelegatesHash = (array: any) => {
    const delegateHash: any = {};
    for (const delegate of array) {
      if (
        (!delegate.topCompany && !delegate.topTeam && !delegate.company) ||
        !delegate.delegate
      ) {
        continue;
      }
      if (delegate.topCompany) {
        if (!delegateHash[delegate.topCompany.value]) {
          delegateHash[delegate.topCompany.value] = [];
        }
        delegateHash[delegate.topCompany.value].push(delegate.delegate.email);
      }
      if (delegate.topTeam) {
        if (!delegateHash[delegate.topTeam.value]) {
          delegateHash[delegate.topTeam.value] = [];
        }
        delegateHash[delegate.topTeam.value].push(delegate.delegate.email);
      }
      if (delegate.company) {
        if (!delegateHash[delegate.company.value]) {
          delegateHash[delegate.company.value] = [];
        }
        delegateHash[delegate.company.value].push(delegate.delegate.email);
      }
    }
    return delegateHash;
  };

  const isDelegatesArrayEqual = (
    originalDelegates: any,
    changedDelegates: any,
  ) => {
    for (const delegate of changedDelegates) {
      if (
        (!delegate.topCompany && !delegate.topTeam && !delegate.company) ||
        !delegate.delegate
      ) {
        setIsEmptyDelegatesValues(true);
        return false;
      }
    }
    setIsEmptyDelegatesValues(false);
    const array1Hash = getDelegatesHash(originalDelegates);
    for (const delegate of changedDelegates) {
      if (
        (!array1Hash[delegate?.topCompany?.value] ||
          !array1Hash[delegate?.topCompany?.value].includes(
            delegate.delegate.email,
          )) &&
        (!array1Hash[delegate?.topTeam?.value] ||
          !array1Hash[delegate?.topTeam?.value].includes(
            delegate.delegate.email,
          )) &&
        (!array1Hash[delegate?.company?.value] ||
          !array1Hash[delegate?.company?.value].includes(
            delegate.delegate.email,
          ))
      ) {
        return false;
      }
    }
    if (originalDelegates.length !== changedDelegates.length) {
      return false;
    }
    return true;
  };

  const handlePermissionClicked = () => {
    if (currentCellRef === permissionsDropdownId) {
      clearRef();
      setFocusDropdown(false);
    } else {
      setRef(permissionsDropdownId);
      setFocusDropdown(true);
    }
  };

  useEffect(() => {
    if (currentCellRef !== permissionsDropdownId) {
      setFocusDropdown(false);
    }
  }, [currentCellRef]);

  const permissionsList = () => {
    if (!user || !user.additionalPermissions) {
      return null;
    }
    const additionalPermissions = user.additionalPermissions.split(',');
    const options = additionalPermissions.map((permission: any) => {
      return {
        id: parseInt(permission, 10),
        value: permissions[parseInt(permission, 10)],
      };
    });
    return options;
  };

  const options = permissionsList();

  const registerListeners = () => {
    document.addEventListener('mouseup', mouseUp, false);
    document.addEventListener('keydown', escFunction, false);
  };

  const mouseDown = (e: any) => {
    const requestModal = document.getElementById('user-settings-modal');
    if (!requestModal) {
      return;
    }

    requestModal.style.position = 'absolute';
    requestModal.style.zIndex = '1000';

    document.addEventListener('mousemove', onMouseMove);
  };

  const avatarUrl = () => {
    if (avatarsHash && avatarsHash[user.name]) {
      return avatarsHash[user.name];
    }
    return 'https://i.stack.imgur.com/l60Hf.png';
  };

  const onPermissionSelected = (option: any) => {
    setSelectedPermission(option.id);
    setFocusDropdown(false);
    clearRef();
  };

  const [avatarImage, setAvatarImage] = useState(avatarUrl());

  const [isHeadOf, setIsHeadOf] = useState(false);

  useEffect(() => {
    setIsHeadOf(
      user &&
        (permissions[user.permission] === 'Head Of' ||
          permissions[user.permission] === 'Budget Owner'),
    );
  }, [user]);

  useEffect(() => {
    setUserDelegates(
      headOfDelegates ? JSON.parse(JSON.stringify(headOfDelegates)) : [],
    );
  }, [headOfDelegates]);

  const listUserDelegates = () => {
    if (!userDelegates) {
      return;
    }
    const userDelegatesComponents = [];
    for (const delegate of userDelegates) {
      userDelegatesComponents.push(
        <Delegate
          key={delegate.id}
          data={delegate}
          onTeamSelectedCallback={onTeamSelected}
          onDelegateSelectedCallback={onDelegateSelected}
          onRemoveCallback={onRemoveDelegateClicked}
        ></Delegate>,
      );
    }
    return userDelegatesComponents;
  };

  const onTeamSelected = (id: any, selectedTeam: any) => {
    const delegate = userDelegates.find((delegate: any) => delegate.id === id);
    if (selectedTeam?.type) {
      delete delegate.topCompany;
      delete delegate.topTeam;
      delete delegate.company;
      delegate[selectedTeam.type] = selectedTeam;
    } else {
      delegate.company = selectedTeam;
    }
    setIsDelegatesChanged(
      !isDelegatesArrayEqual(headOfDelegates, userDelegates),
    );
  };

  const onDelegateSelected = (id: any, selectedTeamMember: any) => {
    const delegate = userDelegates.find((delegate: any) => delegate.id === id);
    if (!delegate.delegate && selectedTeamMember) {
      setIsEmptyDelegatesValues(false);
    }
    delegate.delegate = selectedTeamMember;
    setIsDelegatesChanged(
      !isDelegatesArrayEqual(headOfDelegates, userDelegates),
    );
  };

  const onAddDelegateClicked = () => {
    const tempUserDelegates = [...userDelegates];
    tempUserDelegates.push({ id: uuidv4(), company: null, delegate: null });
    setUserDelegates(tempUserDelegates);
    setIsDelegatesChanged(
      !isDelegatesArrayEqual(headOfDelegates, tempUserDelegates),
    );
  };

  const onRemoveDelegateClicked = (id: any) => {
    const tempUserDelegates = [...userDelegates];
    const indexToRemove = tempUserDelegates.findIndex(
      (delegate) => delegate.id === id,
    );
    tempUserDelegates.splice(indexToRemove, 1);
    setUserDelegates(tempUserDelegates);
    setIsDelegatesChanged(
      !isDelegatesArrayEqual(headOfDelegates, tempUserDelegates),
    );
  };

  const onSubmit = () => {
    if (JSON.stringify(headOfDelegates) !== JSON.stringify(userDelegates)) {
      updateHeadOfDelegates(userDelegates);
    }
    if (selectedPermission && selectedPermission !== user.permission) {
      setUserPermission(selectedPermission);
    }
    onClose();
  };

  const onCancel = () => {
    setUserDelegates(
      headOfDelegates ? JSON.parse(JSON.stringify(headOfDelegates)) : [],
    );
    onClose();
  };

  const onClose = () => {
    displayUserSettingsModal(false);
    setSelectedPermission(user.permission);
    setFocusDropdown(false);
    setIsDelegatesChanged(false);
    setIsEmptyDelegatesValues(false);
    clearRef();
    document.removeEventListener('mouseup', mouseUp, false);
    document.removeEventListener('keydown', escFunction, false);
  };

  return (
    <Modal
      isOpen={isUserSettingsModalShown || false}
      onAfterOpen={registerListeners}
    >
      <div
        id="user-settings-modal"
        className={isHeadOf ? `${s.main} ${s.headOf}` : s.main}
        onMouseUp={mouseUp}
      >
        <section id={s.modalUpperSection}>
          <ModalHeader dragCallback={mouseDown} closeClicked={onCancel} />
        </section>
        <div className={s.userPersonal}>
          <div>
            {avatarImage && (
              <img
                className={s.avatar}
                src={avatarImage}
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = 'https://i.stack.imgur.com/l60Hf.png';
                }}
                width="37.5"
                height="36"
              ></img>
            )}
            {/* <div onMouseEnter={renderTooltip}>
              {value && value.length > maxChars
                ? `${value.substring(0, maxChars - 3)}...`
                : value}
            </div> */}
          </div>
          <section>
            <Text className={s.nameText}>{user.name}</Text>
          </section>
        </div>
        <div className={s.userDetailsContainer}>
          <Text className={`${s.detailsBox} ${s.detailName}`}>Email</Text>
          <Text className={`${s.detailsBox} ${s.detailName}`}>Team</Text>
          <Text className={`${s.detailsBox} ${s.detailName}`}>Role</Text>
          <Text className={`${s.detailsBox} ${s.detailValue}`}>
            {user.email}
          </Text>
          <Text className={`${s.detailsBox} ${s.detailValue}`}>
            {user.subTeam}
          </Text>
          {user.additionalPermissions ? (
            <div>
              <div
                className={s.formInputGroup}
                onClick={handlePermissionClicked}
              >
                <input
                  className={s.formInput}
                  value={
                    focusDropdown && currentCellRef === permissionsDropdownId
                      ? '-Select Role-'
                      : permissions[selectedPermission]
                  }
                ></input>
                <div className={s.chevronBox}>
                  {focusDropdown && currentCellRef === permissionsDropdownId ? (
                    <ChevronUp size="16" />
                  ) : (
                    <ChevronDown size="16" />
                  )}
                </div>
              </div>
              {focusDropdown && currentCellRef === permissionsDropdownId && (
                <CustomDropdown
                  onClick={(event: any) => event.stopPropagation()}
                  title={' '}
                  options={options}
                  onSelect={(option: any) => onPermissionSelected(option)}
                  style={{
                    position: 'absolute',
                    zIndex: 1,
                    height: '97px',
                    width: '158px',
                    marginTop: '-128px',
                  }}
                  headerStyle={{
                    height: '12px',
                  }}
                  optionsStyle={{
                    height: '30px',
                    paddingTop: '5px',
                    paddingBottom: '6px',
                    fontSize: '12px',
                  }}
                  footerStyle={{
                    height: '12px',
                  }}
                />
              )}
            </div>
          ) : (
            <Text className={`${s.detailsBox} ${s.detailValue}`}>
              {permissions[user.permission]}
            </Text>
          )}
        </div>
        {(permissions[user.permission] === 'Head Of' ||
          permissions[user.permission] === 'Budget Owner') && (
          <div className={s.delegatesContainer}>
            <div style={{ height: '24px' }}></div>
            <div>
              <Text className={s.delegatesHeader}>Delegates</Text>
            </div>
            <div>
              <Text className={s.delegatesSubHeader}>
                Add team members that will have the same permisions as you have
              </Text>
            </div>
            <div className={s.delegatesList}>
              {userDelegates && listUserDelegates()}
              {!isEmptyDelegatesValues ? (
                <TextButton
                  className={s.addDelegateButton}
                  onClick={onAddDelegateClicked}
                >
                  + Add Delegate
                </TextButton>
              ) : (
                <TextButton className={s.addDelegateButton} disabled>
                  + Add Delegate
                </TextButton>
              )}
            </div>
          </div>
        )}
        <div className={s.bottom}>
          <TextButton className={s.cancelButton} onClick={onCancel}>
            Cancel
          </TextButton>
          {(selectedPermission && selectedPermission !== user.permission) ||
          ((permissions[user.permission] === 'Head Of' ||
            permissions[user.permission] === 'Budget Owner') &&
            isDelegatesChanged &&
            !isEmptyDelegatesValues) ? (
            <Button
              className={`${s.button} ${s.submitButton}`}
              onClick={onSubmit}
            >
              Save
            </Button>
          ) : (
            <Button className={`${s.button} ${s.disabledButton}`} disabled>
              Save
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  headOfDelegates: state.auth.headOfDelegates,
  isUserSettingsModalShown: state.auth.isUserSettingsModalShown,
  avatarsHash: state.dropdowns.avatarsHash,
  currentCellRef: state.seats.currentCellRef,
});

const mapDispatchToProps = (dispatch: any) => ({
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  clearRef: () => dispatch(clearRef()),
  setUserPermission: (permission: number) =>
    dispatch(setUserPermission(permission)),
  displayUserSettingsModal: (isShow: any) =>
    dispatch(displayUserSettingsModal(isShow)),
  updateHeadOfDelegates: (userDelegates: any) =>
    dispatch(updateHeadOfDelegates(userDelegates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsModal);
