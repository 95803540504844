import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './FormStatus.scss';
import { setRef } from '../../../redux/actions/requestModalActions';
import { v4 as uuidv4 } from 'uuid';
import { Badge, Text } from "@wix/design-system";
import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
import {
  defaultStatusReasonMessage,
  defaultRejectedPrePendingMessage,
  rejectedReasonStatuses,
  statusNames,
  prePendingStatuses,
  PRE_PENDING_PERMISSION,
  HR_PERMIAAION,
  availableStatusesForHr,
  hrApprovedStatuses,
  BUDGET_OWNER,
  availableStatusesForChairman,

} from '../../../../../Client/config';
import { isPrePending } from '../../../../../Client/utils';

const Status = (props: any) => {
  const {
    title,
    initValue,
    placeholder,
    currentInputRef,
    setRef,
    source,
    change,
    formValues,
    mode,
    user,
  } = props;

  const [myId, setMyId] = useState(uuidv4());
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(initValue || 1);
  const [originalVale, setOriginalValue] = useState(initValue || 1);
  const [pendingChange, setPendingChange] = useState(null);
  const [showModified, setShowModified] = useState(false);
  const [disabled, setDisabled] = useState(
    mode === 'View' ||
      (user.permission === PRE_PENDING_PERMISSION && !isPrePending(formValues)),
  );

  useEffect(() => {
    setPendingChange(
      formValues.pendingChanges &&
        Object.keys(formValues.pendingChanges).length > 0,
    );
    setShowModified(
      formValues.pendingChanges &&
        Object.keys(formValues.pendingChanges).length > 0,
    );
  }, []);
  const getStatusOptions = () => {
    let validStatuses =
      user.permission === PRE_PENDING_PERMISSION
        ? { ...prePendingStatuses }
        : user.permission === BUDGET_OWNER ? {...availableStatusesForChairman} : { ...statusNames };
    if (user.permission === HR_PERMIAAION) {
      validStatuses = { ...availableStatusesForHr };
    }
    if (!isPrePending(formValues)) {
      delete validStatuses[10];
      delete validStatuses[11];
    }
    delete validStatuses[12];
    const options = Object.entries(validStatuses).map((statusName: any) => {
      const [key, value] = statusName;
      return {
        label: (
          <Badge size="small" skin={value.skin} onClick={() => {}}>
            {value.label}
          </Badge>
        ),
        value: parseInt(key, 10),
      };
    });
    return options;
  };
  useEffect(() => {
    change(source, value);
  }, []);
  useEffect(() => {
    if (formValues.status) {
      setValue(formValues.status);
      change(source, formValues.status);
    }
  }, [formValues.status]);

  const handleCellClick = () => {
    setFocus(true);
    setRef(myId);
  };

  const handleOptionSelect = (option: any) => {
    setValue(option.value);
    change(source, option.value);
    setShowModified(false);
    if (isPrePending(formValues)) {
      if (option.value === 10 && option.value === 11) {
        change('isPrePendingApproved', false);
      } else {
        change('isPrePendingApproved', true);
      }
    }
    if (option.value === 2 && user.permission !== HR_PERMIAAION) {
      change('approvedDate', new Date().toISOString());
    }
    if (formValues.guildPriority && option.value !== 6) {
      change('guildPriority', '');
    }
    if (
      rejectedReasonStatuses.includes(option.value) &&
      !formValues?.statusReason
    ) {
      if (option.value === 11) {
        change('statusReason', defaultRejectedPrePendingMessage);
      } else {
        change('statusReason', defaultStatusReasonMessage);
      }
    }
    setFocus(false);
  };

  return (
    <div className={s.mainContainer}>
      {title && <Text className={s.formInputLabel}>{title}</Text>}
      {focus && currentInputRef === myId ? (
        <div className={s.status}>
          <Badge
            size="small"
            skin={showModified ? statusNames[12].skin : statusNames[value].skin}
            onClick={() => {}}
          >
            {showModified ? statusNames[12].label : statusNames[value].label}
          </Badge>
          {hrApprovedStatuses.includes(originalVale) && <CustomDropdown
            title={`Select ${placeholder}`}
            options={getStatusOptions()}
            onSelect={handleOptionSelect}
            style={{
              position: 'absolute',
              zIndex: 1,
            }}
          />}
        </div>
      ) : (
        <div
          className={`${s.status} ${disabled ? s.disabled : ''}`}
          onClick={!disabled ? handleCellClick : () => {}}
        >
          <Badge
            size="small"
            skin={showModified ? statusNames[12].skin : statusNames[value].skin}
            onClick={() => {}}
          >
            {showModified ? statusNames[12].label : statusNames[value].label}
          </Badge>
        </div>
      )}
      {pendingChange && <div></div>}
      {pendingChange && (
        <div>
          <Text className={s.changedFromText}>Previous status:</Text>
          <Text className={s.pendingChangeText}>
            {statusNames[originalVale].label}
          </Text>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentInputRef: state.requestModal.currentInputRef,
  formValues: state.form.requestModalForm?.values,
  mode: state.requestModal.mode,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
