import { HEAD_COUNT } from '../actions/constants';

const initState: any = {
  headCount: [],
  targetHeadCount: [],
  headCountByTeam: null,
  topCompanies: [],
  topTeams: [],
  subTeams: [],
};

export default (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case HEAD_COUNT.SET_HEAD_COUNT:
      return { ...state, headCount: payload };
    case HEAD_COUNT.SET_TARGET_HEAD_COUNT:
      return { ...state, targetHeadCount: payload };
    case HEAD_COUNT.SET_HEAD_COUNT_BY_TEAM:
      return { ...state, headCountByTeam: payload };
    case HEAD_COUNT.SET_TOP_COMPANIES:
      return { ...state, topCompanies: payload };
    case HEAD_COUNT.SET_TOP_TEAMS:
      return { ...state, topTeams: payload };
    case HEAD_COUNT.SET_SUB_TEAMS:
      return { ...state, subTeams: payload };
    default:
      return state;
  }
};
