import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import s from './CustomMultiSelect.scss';
import { Input } from "@wix/design-system";
import { ChevronDown } from 'wix-ui-icons-common';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import Tag from './subComponents/Tag';
import { formName } from '../redux/actions/requestModalActions';

const CustomMultiSelect = (props: any) => {
  const {
    source,
    dropdownName,
    placeholder,
    dropdowns,
    currentInputRef,
    setRef,
    initValue,
    change,
    mode,
  } = props;
  const [tags, setTags] = useState(initValue || []);
  const [inputValue, setInputValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [options, setOptions] = useState([]);
  const [myId, setMyId] = useState(uuidv4());
  const [disabled, setDisabled] = useState(mode === 'View');

  const ref = useRef() as any;

  useEffect(() => {
    if (tags.length > 0 && !tags[0].value) {
      const dropdownData = dropdowns[dropdownName]
        ? dropdowns[dropdownName].data
        : [];
      const tempTags = tags.map((tag: any) => {
        const dropdownTag = dropdownData.find(
          (dataItem: any) => dataItem.oracleMail === tag,
        );
        return (
          dropdownTag || {
            value: tag,
            oracleMail: tag,
            icon: 'https://i.stack.imgur.com/l60Hf.png',
          }
        );
      });
      setTags(tempTags);
    }
  }, [initValue]);

  useEffect(() => {
    // Filter options with existing tags
    handleSetOptions();
  }, [focus]);

  const handleOnSelect = (option: any) => {
    // Check if the tag with this email already exists
    const exists = tags.find(
      (tag: any) => tag.oracleMail === option.oracleMail,
    );
    if (exists) {
      return;
    }

    const temp: any = [...tags, { ...option }];
    const values = [...temp.map((item: any) => item.oracleMail)];

    setInputValue('');
    setTags(temp);
    change(source, values);
    setFocus(false);
  };

  const handleInputClick = (event: any) => {
    scrollTo();
    handleSetOptions();
    setInputValue('');
    setRef(myId);
    setFocus(true);
  };

  const scrollTo = () => {
    ref && ref.current && ref.current.focus();
    const element = document.getElementById(source);
    const form = document.getElementById(formName);

    if (
      form &&
      element &&
      element.offsetTop + 200 > form.clientHeight + form.scrollTop
    ) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleOnRemoveTag = (value: string) => {
    const temp = tags.filter((tag: any) => tag.value !== value);
    const values = [...temp.map((item: any) => item.oracleMail)];

    setTags(temp);
    change(source, values);
  };

  const handleSetOptions = () => {
    const temp = dropdowns[dropdownName] ? dropdowns[dropdownName].data : [];
    const tagFilter = tags.map((tag: any) => tag.oracleMail);
    const filteredTemp = temp.filter(
      (option: any) => !tagFilter.includes(option.oracleMail),
    );

    setOptions(filteredTemp);
  };

  const renderTags = () => {
    return tags.map((tag: any, index: number) => (
      <Tag
        key={index}
        value={tag.value}
        icon={tag.icon}
        id={tag.id}
        removeTag={handleOnRemoveTag}
      />
    ));
  };

  const isFocused = focus && currentInputRef === myId;

  return (
    <div className={s.multiSelect}>
      <div
        onClick={handleInputClick}
        className={`${s.multiSelectInputsContainer} ${
          isFocused && !disabled ? s.focused : ''
        }`}
      >
        {renderTags()}
        <div>
          {isFocused && !disabled ? (
            <Input
              ref={ref}
              value={inputValue}
              onChange={(e: any) => setInputValue(e.target.value)}
              className={s.multiSelectInput}
            />
          ) : (
            <Input
              ref={ref}
              value={inputValue}
              onChange={(e: any) => setInputValue(e.target.value)}
              className={s.multiSelectInput}
              placeholder={placeholder}
              suffix={!disabled && <ChevronDown />}
              disabled={disabled}
            />
          )}
        </div>
      </div>
      {isFocused && !disabled ? (
        <CustomDropdown
          onClick={(event: any) => event.stopPropagation()}
          filter={inputValue}
          title={placeholder}
          options={options}
          onSelect={handleOnSelect}
          style={{
            position: 'absolute',
            zIndex: 2,
            width: '250px',
            height: '150px',
            top: '-159px',
            left: '0',
          }}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  mode: state.requestModal.mode,
});

const mapDispatchToProps = (dispatch: any) => ({
  change: (field: string, value: string, form = formName) =>
    dispatch(change(form, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomMultiSelect);
