import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { displayJustificationModalMessage } from '../../../redux/actions/seatsActions';
import s from './Justification.scss';
import { Email, CircleSmallFilled } from 'wix-ui-icons-common';
import { rejectedReasonStatuses } from '../../../../../Client/config';

const Justification = (props: any) => {
  const {
    value,
    data,
    blockEdit,
    changedSeats,
    user,
    displayJustificationModalMessage,
    statusJustifications,
  } = props;

  const [edited, setEdited] = useState(false);
  const [showJustification, setShowJustification] = useState<any>(false);

  useEffect(() => {
    setEdited(
      changedSeats[data._id] &&
        (changedSeats[data._id].businessJustification ||
          changedSeats[data._id].statusReason),
    );
  }, [changedSeats]);

  useEffect(() => {
    if (
      data.businessJustification ||
      (changedSeats[data._id] && changedSeats[data._id].businessJustification)
    ) {
      setShowJustification(true);
    } else if (changedSeats[data._id] && changedSeats[data._id].status) {
      setShowJustification(
        rejectedReasonStatuses.includes(changedSeats[data._id].status),
      );
    } else {
      setShowJustification(rejectedReasonStatuses.includes(data.status));
    }
  }, [changedSeats, data]);

  const handleCellClick = async () => {
    let statusReason;
    let showStatusReason;

    const currentStatus =
      (changedSeats[data._id] && changedSeats[data._id].status) || data.status;
    if (rejectedReasonStatuses.includes(currentStatus)) {
      showStatusReason = true;
      statusReason = {
        reason: data.statusReason || statusJustifications[data._id]?.reason,
        username:
          data.statusReasonUpdater || statusJustifications[data._id]?.updater,
        time:
          data.statusReasonUpdateTime ||
          new Date(statusJustifications[data._id]?.updatedTime) ||
          null,
      };
      if (changedSeats[data._id] && changedSeats[data._id].statusReason) {
        statusReason = {
          ...statusReason,
          reason: changedSeats[data._id].statusReason,
        };
      }
    }

    const businessJustificationMessage =
      (changedSeats[data._id] &&
        changedSeats[data._id].businessJustification) ||
      value;
    const canEditStatusReason = user.permission !== 1 && user.permission !== 5;
    displayJustificationModalMessage({
      seatId: data._id,
      requester: data.requester,
      businessJustificationMessage,
      statusReason,
      showStatusReason,
      canEditBusinessJustification: !blockEdit,
      canEditStatusReason,
      user,
      status: currentStatus,
    });
  };

  return (
    <div>
      {showJustification && (
        <div>
          {edited && <CircleSmallFilled className={s.edited} size="18px" />}
          <Email className={s.justification} onClick={handleCellClick} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  changedSeats: state.seats.changedSeats,
  user: state.auth.user,
  statusJustifications: state.seats.statusJustifications,
});

const mapDispatchToProps = (dispatch: any) => ({
  displayJustificationModalMessage: (message: any) =>
    dispatch(displayJustificationModalMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Justification);
