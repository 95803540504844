import { HEAD_COUNT } from './constants';
import { httpClient } from '../../../../Client/httpClient';

export const setHeadCount = () => async (dispatch: any, getState: any) => {
  const { data } = await httpClient.get(`/data/getHeadCountData/`);
  dispatch({
    payload: data.targetHeadCount,
    type: HEAD_COUNT.SET_TARGET_HEAD_COUNT,
  });
  dispatch({
    payload: data.headCount,
    type: HEAD_COUNT.SET_HEAD_COUNT,
  });
  dispatch({
    payload: data.headCountByTeam,
    type: HEAD_COUNT.SET_HEAD_COUNT_BY_TEAM,
  });
  dispatch({
    payload: data.topCompanies,
    type: HEAD_COUNT.SET_TOP_COMPANIES,
  });
  dispatch({
    payload: data.topTeams,
    type: HEAD_COUNT.SET_TOP_TEAMS,
  });
  dispatch({
    payload: data.subTeams,
    type: HEAD_COUNT.SET_SUB_TEAMS,
  });
};
