import { login } from '../../../../../Server/controllers/security';
import { allColumns } from './columns';

const baseCols: any = {
  'Seats No.': ['Creation date', 'Seat Number', 'SR job ref'],
  Priority: ['Priority Status', 'Guild Priority'],
  'Recruitment Status': ['Status', 'Justification'],
  Requester: ['Requester'],
  City: ['Requested'],
  Q: ['Q Request', 'Q Approved'],
  'Request Details': ['Seat Number', 'Requester', 'SR job ref'],
  'Status Change Justification': ['Status Change Justification'],
  'Position Details': [
    'Position Name',
    'Profession',
    'Guild',
    'Sub Guild',
    'Company / Department',
    'Team',
    'Budget ID',
    'Shift',
    'Internal / External Recruit',
    'Seniority',
  ],
  'City & Q': ['City Requested', 'Q Requested'],
  'Position Type': ['Full / part time', 'Student / intern', 'Temp', 'Replace / New', 'In House / OS'],
  'Replacement Details': [
    'Name',
    'Closed a Position',
    'Moved To',
    'Voluntary',
    'Date (Est.)',
  ],
  'Business Justification': ['Business Justification'],
  'Share Request with': ['Share Request with'],
  'Recruitment Details': ['Employee Name (recruited)'],
};

const formGroupColsOrder: any = [
  'Request Details',
  'Status Change Justification',
  'Position Details',
  'City & Q',
  'Position Type',
  'Replacement Details',
  'Business Justification',
  'Recruitment Details',
  'Priority',
  'Share Request with',
];

const tableGroupColsOrder: any = [
  'Actions',
  'Seats No.',
  'Team Status',
  'Priority',
  'Recruitment Status',
  'Requester',
  'Position Details',
  'Position Type',
  'City',
  'Q',
  'Replacement Details',
  'Recruitment Details',
  'Duplications Report',
];

const requesterCols: any = { Actions: ['Actions'] };

const approverCols: any = { 'Request Details': ['Status', 'SR job ref'] };

const adminCols: any = {
  ...requesterCols,
  ...approverCols,
  'Recruitment Details': [
    'HR',
    'Recruiter',
    'Talent Center',
    'Approved Date',
    'End of Process',
    'Closed by Internal Move',
  ],
  'Duplications Report': ['Exclude'],
  'Team Status': ['Current HC', 'Approved/focus/DIY', 'Target'],
};

const pltCols: any = {
  ...requesterCols,
  ...approverCols,
  'Recruitment Details': [
    'HR',
    'Recruiter',
    'Talent Center',
    'Approved Date',
    'End of Process',
    'Closed by Internal Move',
  ],
};

const approverEditPermissions: any = {
  'Request Details': ['Status'],
  'Status Change Justification': ['Status Change Justification'],
  'Recruitment Status': ['Status'],
  Priority: ['Priority Status', 'Guild Priority'],
  // 'City & Q': ['City Approved', 'Q Approved'],
  // City: ['Approved'],
  // Q: ['Q Approved'],
};

const adminEditPermissions: any = {
  ...approverEditPermissions,
  'Recruitment Details': [
    'HR',
    'Recruiter',
    'Talent Center',
    'Employee Name (recruited)',
    'Approved Date',
    'End of Process',
    'Closed by Internal Move',
  ],
  'Duplications Report': ['Exclude'],
};

const pltEditPermissions: any = {
  Priority: ['Priority Status', 'Guild Priority'],
  'Request Details': ['Status'],
  'Recruitment Details': [
    'HR',
    'Recruiter',
    'Talent Center',
  ],
}

const headOfCols: any = {
  ...requesterCols,
  ...approverCols,
};

const prepApproverCols: any = {
  ...requesterCols,
  ...approverCols,
};

export const getColumnsByPermission = (permission: any) => {
  switch (permission) {
    case 1:
      return getValidColumns(requesterCols, null);
    case 2:
      return getValidColumns(approverCols, approverEditPermissions);
    case 6:
      return getValidColumns(prepApproverCols, approverEditPermissions);
    case 3:
    case 4:
      return getValidColumns(adminCols, adminEditPermissions);
    case 5:
      return getValidColumns(pltCols, pltEditPermissions);
    case 7:
    case 8:
    case 9:
      return getValidColumns(headOfCols, null);
    default:
      return getValidColumns(requesterCols, null);
  }
};

const getValidColumns = (columnsNames: any, editColumnsNames: any) => {
  const columns = getColumnNames(columnsNames);
  columnsNames = { ...baseCols, ...columnsNames };
  const permittedColGroups: any = Object.keys(columns);
  const validColumns: any = [];
  for (const colGroup in allColumns) {
    const currentColGroup: any = { ...allColumns[colGroup] };
    if (permittedColGroups.includes(currentColGroup.title)) {
      if (!currentColGroup.formOnly) {
        currentColGroup.display = true;
        currentColGroup.filtered = currentColGroup.filtered || false;
      }
      if (formGroupColsOrder.includes(currentColGroup.title)) {
        currentColGroup.order =
          formGroupColsOrder.indexOf(currentColGroup.title) + 1;
      }
      const permittedCols: any = Object.values(columns[currentColGroup.title]);
      for (const column in currentColGroup.columns) {
        const currentColumn = currentColGroup.columns[column];
        if (permittedCols.includes(currentColumn.title)) {
          if (!currentColGroup.formOnly) {
            currentColumn.display = true;
            currentColumn.filtered = currentColumn.filtered || false;
          }
          if (
            editColumnsNames &&
            editColumnsNames[currentColGroup.title] &&
            editColumnsNames[currentColGroup.title].includes(
              currentColumn.title,
            )
          ) {
            currentColumn.edit = true;
          }
        } else {
          currentColGroup.columns.splice(column, 1);
        }
      }
      validColumns.push(currentColGroup);
    }
  }

  return orderColumnsForTable(validColumns);
};

const orderColumnsForTable = (columns: any) => {
  const orderColumns = [] as Array<any>;
  tableGroupColsOrder.forEach((title: string) => {
    const item = columns.find((col: any) => col.title == title)
    if (item) orderColumns.push(item)
  });
  columns.forEach((col: any) => {
    if (!tableGroupColsOrder.includes(col.title)) {
      orderColumns.push(col);
    }
  })

  return orderColumns;
}

const getColumnNames = (columnsNames: any) => {
  let columns = { ...baseCols };
  for (const col in columnsNames) {
    if (columns[col]) {
      columnsNames[col].forEach((column: any) => {
        columns[col].push(column);
      });
    } else {
      columns = { ...columns, [col]: columnsNames[col] };
    }
  }
  return columns;
};
