import React from 'react';
import { connect } from 'react-redux';
import FormItem from '../FormItem/FormItem';
import { formValueSelector, change } from 'redux-form';
import { formName } from '../../../redux/actions/requestModalActions';
import s from '../Form.scss';

const Group = (props: any) => {
  const { title, columns, hidden, order, tableOnly } = props;
  const input = columns[0];
  if (!input.edit || tableOnly || hidden) {
    return null;
  }

  return (
    <div className={s.formGroup} style={{ order: order || 'auto' }}>
      <h3 className={s.groupTitle}>{title}</h3>
      <FormItem
        {...input}
        title={null}
        aditionalClass={s.largeAutofillDropdown}
      />
    </div>
  );
};

const selector = formValueSelector(formName);

const isHidden = (state: any, ownProps: any) => {
  const { hidder } = ownProps;
  if (!hidder) {
    return false;
  }

  const { source, showValues } = hidder;

  for (const sourceName of source) {
    const hidderValue = selector(state, sourceName);
    if (hidderValue && showValues.includes(hidderValue)) {
      return false;
    }
  }
  return true;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  hidden: isHidden(state, ownProps),
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  change: (field: string, value: string, form = formName) =>
    dispatch(change(form, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Group);
