import { httpClient } from './httpClient';
import {
  PRE_PENDING_GUILDS,
  PRE_PENDING_COMPANIES,
  PRE_PENDING_GUILDS_NAMES,
  PRE_PENDING_COMPANIES_NAMES,
} from './config';
import { convert, types } from './idsConvertor';

export function JSONToCSVConvertor(
  JSONData: any,
  ReportTitle: any,
  ShowLabel: any,
) {
  // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  const arrData =
    typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;
  let CSV: string = '';
  // Set Report title in first row or line
  // CSV += ReportTitle + '\r\n\n';
  // This condition will generate the Label/Header
  if (ShowLabel) {
    let row: string = '';
    // This loop will extract the label from 1st index of on array
    for (let index in arrData[0]) {
      index = index.charAt(0).toUpperCase() + index.slice(1);
      // Now convert each value to string and comma-seprated
      row += index.replace(/_/g, ' ') + ',';
    }
    row = row.slice(0, -1);
    // append Label row with line break
    CSV += row + '\r\n';
  }
  // 1st loop is to extract each row
  for (const obj of arrData) {
    let row = '';
    // 2nd loop will extract each column and convert it in string comma-seprated
    for (const index in obj) {
      row += `"${obj[index]}",`;
    }
    row.slice(0, row.length - 1);
    // add a line break after each row
    CSV += row + '\r\n';
  }
  if (CSV === '') {
    alert('Invalid data');
    return;
  }
  getSeatsSpreadsheet(CSV, ReportTitle);
}

export function getSeatsSpreadsheet(CSV: any, ReportTitle: any) {
  // Generate a file name
  let fileName = 'seats_';
  // this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle;
  // .replace(/ /g, "_");
  // Initialize file format you want csv or xls
  const uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension
  // this trick will generate a temp <a /> tag
  const link = document.createElement('a');
  link.href = uri;
  // set the visibility hidden so it will not effect on your web-layout
  link.style.cssText = 'visibility:hidden';
  link.download = fileName + '.csv';
  // this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function refreshGuildPriority(seats: any) {
  const filteredSeats = seats.filter(
    (seat: any) =>
      (seat.status === 2 || seat.status === 5 || seat.status === 9) &&
      Number(seat.wixPriority),
  );
  const sortedSeats = filteredSeats.sort(compareWixPriority);
  const seatsByGuildsHash: any = {};
  for (const seat of sortedSeats) {
    if (!seatsByGuildsHash[seat.guild]) {
      seatsByGuildsHash[seat.guild] = [];
    }
    seatsByGuildsHash[seat.guild].push(seat);
  }
  for (const guild in seatsByGuildsHash) {
    for (let i = 0; i < seatsByGuildsHash[guild].length; i++) {
      const seat = seatsByGuildsHash[guild][i];
      seat.guildPriority = i + 1;
    }
  }
}
const NONE_PRIORITIES = ['-', 'None'];
const compareWixPriority = (a: any, b: any) => {
  if (!a.wixPriority || NONE_PRIORITIES.indexOf(a.wixPriority) !== -1) {
    return -1;
  }
  if (!b.wixPriority || NONE_PRIORITIES.indexOf(b.wixPriority) !== -1) {
    return 1;
  }
  const wixPriorityA = Number(a.wixPriority);
  const wixPriorityB = Number(b.wixPriority);
  if (wixPriorityA < wixPriorityB) {
    return -1;
  }
  if (wixPriorityA > wixPriorityB) {
    return 1;
  }
  if (a.isNewAdded) {
    return -1;
  }
  return 1;
};

export const syncGoogleSheets = async () => {
  await httpClient.get('/seats/syncGoogleSheets');
  return true;
};

export const sendReplacementReport = async () => {
  await httpClient.get('/seats/sendReplacementNameReport');
  return true;
};

export const initPrePendingNames = () => {
  for (const guild of PRE_PENDING_GUILDS) {
    const guildName = convert(guild, types.ID_TO_NAME, 'guild');
    PRE_PENDING_GUILDS_NAMES.push(guildName);
  }
  for (const company of PRE_PENDING_COMPANIES) {
    const companyName = convert(company, types.ID_TO_NAME, 'company');
    PRE_PENDING_COMPANIES_NAMES.push(companyName);
  }
};

export const isPrePending = (data: any) => {
  return (
    PRE_PENDING_GUILDS_NAMES.includes(data.guild) ||
    PRE_PENDING_COMPANIES_NAMES.includes(data.company)
  );
};

export const getPendingChangeTooltip = (
  pendingValue: any,
  originalValue: any,
  column: any,
) => {
  if (pendingValue) {
    return `The ${convertToCapitalCase(
      column,
    )} was changed from '${originalValue}' to '${pendingValue}'.`;
  }
  return null;
};

const convertToCapitalCase = (text: string) => {
  text = text.replace(/([A-Z])/g, ' $1');
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const convertArrayToObj = (keyField: any, arr: any) => {
  if (!arr || !keyField || !arr[0][keyField]) {
    return null;
  }
  let obj = {};
  for (const element of arr) {
    const keyValue = element[keyField];
    delete element[keyField];
    obj = { ...obj, [keyValue]: element };
  }
  return obj;
};

export const isMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    return true;
  }
  return false;
};

export function formatDate(inputStr: string | null | undefined): string {
  if (inputStr === null || inputStr === undefined) return "";

  const inputDate = new Date(inputStr);

  if (isNaN(inputDate.valueOf())) return "";

  const day = inputDate.getDate();
  const month = inputDate.toLocaleString('en-US', { month: 'long' });
  const year = inputDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export function cleanString(inputStr: string) {
  let cleanedStr = inputStr.replace(/\xa0/g, ' ');
  let parts = cleanedStr.split('\\\\');
  let trimmedParts = parts.map(part => part.trim());
  let resultStr = trimmedParts.join('\\\\');
  return resultStr;
}
