export const types = {
  ID_TO_NAME: 'ID_TO_NAME',
  NAME_TO_ID: 'NAME_TO_ID',
};

const idToNameHash: any = {};
const nameToIdHash: any = {};

const keysToConvert: any = {
  requester: 'requester',
  replacementName: 'requester',
  guild: 'guild',
  subGuild: 'subGuild',
  company: 'company',
  subTeam: 'subTeam',
  site: 'site',
};

export const initHashIdNamesCache = (collectionsData: any) => {
  for (const collectionName in collectionsData) {
    if (!Object.values(keysToConvert).includes(collectionName)) {
      continue;
    }
    idToNameHash[collectionName] = {};
    nameToIdHash[collectionName] = {};
    for (const obj of collectionsData[collectionName].data) {
      const idSource =
        collectionName === 'requester' ? obj.empId : obj.externalId;
      idToNameHash[collectionName][idSource] = obj.value || obj.name;
      nameToIdHash[collectionName][obj.value || obj.name] = idSource;
      if (collectionName === 'guild') {
        for (const subGuild of obj.subs) {
          const subId = subGuild.externalId;
          idToNameHash.subGuild = {
            ...idToNameHash.subGuild,
            [subId]: subGuild.value,
          };
          nameToIdHash.subGuild = {
            ...nameToIdHash.subGuild,
            [subGuild.value]: subId,
          };
        }
      }
      if (collectionName === 'company') {
        for (const subTeam of obj.subs) {
          const subId = subTeam.externalId;
          idToNameHash.subTeam = {
            ...idToNameHash.subTeam,
            [subId]: subTeam.value,
          };
          nameToIdHash.subTeam = {
            ...nameToIdHash.subTeam,
            [subTeam.value]: subId,
          };
        }
      }
    }
  }
};

const getHash = (type: any) => {
  return type === types.ID_TO_NAME ? idToNameHash : nameToIdHash;
};

export const convertSeatsArray = async (data: any, type: any) => {
  const convertedSeatsPromiseArray = data.map((seat: any) =>
    convertSeat(seat, type),
  );
  const convertedSeats = await Promise.all(convertedSeatsPromiseArray);
  return convertedSeats;
};

export const convertSeat = async (seat: any, type: any) => {
  try {
    const hash: any = getHash(type);
    const convertedSeat = { ...seat };
    for (const key in keysToConvert) {
      if (seat[key] && seat[key] !== '-') {
        convertedSeat[key] = hash[keysToConvert[key]][seat[key]];
      } else {
        convertedSeat[key] = seat[key];
      }
    }
    if (convertedSeat.site === 'Kiev') {
      convertedSeat.site = 'Kyiv';
    }
    if (convertedSeat.siteApproved && convertedSeat.siteApproved === 'Kiev') {
      convertedSeat.siteApproved = 'Kyiv';
    }
    return convertedSeat;
  } catch (error: any) {
    console.log(error);
  }
};

export const convert = (key: any, type: any, field: any) => {
  const hash: any = getHash(type);
  if (field === 'Sites') {
    return hash.Sites[key];
  }
  return hash[keysToConvert[field]][key];
};
