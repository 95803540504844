import React, { useEffect, useState } from 'react';
import { Text } from "@wix/design-system";
import s from './CustomInput.scss';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { formName } from '../../../redux/actions/requestModalActions';

const validateRequired = (value: string) => (value ? undefined : 'Required');

const renderField = ({
  input,
  placeholder,
  aditionalClass,
  disabled,
  meta: { touched, error, warning },
}: any) => {
  return (
    <input
      {...input}
      autoComplete="off"
      placeholder={placeholder}
      className={`${s.formInput} ${disabled ? s.disabled : ''} ${
        aditionalClass ? aditionalClass : ''
      } ${touched && error ? s.errorInput : ''}`}
      disabled={disabled}
    />
  );
};

const CustomInput = (props: any) => {
  const {
    title,
    source,
    placeholder,
    aditionalClass,
    required,
    editModeOnly,
    initValue,
    change,
    form,
    mode,
  } = props;

  const [pendingChange, setPendingChange] = useState(null);
  const [initialValue, setInitialValue] = useState(initValue || '');
  const [disabled, setDisabled] = useState(mode === 'View');

  useEffect(() => {
    if (form?.values?.pendingChanges && form.values.pendingChanges[source]) {
      setPendingChange(form.values.pendingChanges[source]);
      change(source, form.values.pendingChanges[source]);
    }
  }, []);

  return (
    <div className={s.mainContainer}>
      {title && (
        <Text className={s.formInputLabel}>
          {title}
          {required && '*'}
        </Text>
      )}
      <div className={s.formInputGroup}>
        {editModeOnly ? (
          <Text className={s.formInputGroup}>{initValue}</Text>
        ) : (
          <Field
            name={source}
            component={renderField}
            validate={required ? [validateRequired] : null}
            aditionalClass={aditionalClass}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
      </div>
      {pendingChange && <div></div>}
      {pendingChange && (
        <div>
          <Text className={s.changedFromText}>Changed from:</Text>
          <Text className={s.pendingChangeText}>{initialValue}</Text>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  form: state.form[formName],
  mode: state.requestModal.mode,
});

export default connect(mapStateToProps)(CustomInput);
