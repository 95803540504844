import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './CustomDatePicker.scss';
import { DatePicker, Input } from "@wix/design-system";
import { DateSmall, CircleSmallFilled } from 'wix-ui-icons-common';
import { setRef, setChangedSeats } from '../../../redux/actions/seatsActions';
import { v4 as uuidv4 } from 'uuid';
import { showTooltip } from '../../../redux/actions/tooltipActions';
import { getPendingChangeTooltip } from '../../../../../Client/utils';
import { monthsKey } from '../../../../../Client/config';

const getFormatedDate = (dateValue: any) => {
  if (!dateValue || dateValue === '-') {
    return dateValue;
  }
  const date = new Date(dateValue);
  const month = monthsKey[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear() % 2000;
  return `${day} ${month} ${year}`;
};

const CustomDatePicker = (props: any) => {
  const {
    value,
    data,
    col,
    changedSeats,
    setChangedSeats,
    currentCellRef,
    setRef,
    blockEdit,
    showTooltip,
  } = props;
  const [myId, setMyId] = useState(uuidv4());
  const [focus, setFocus] = useState(false);
  const [edited, setEdited] = useState(
    changedSeats[data._id] && changedSeats[data._id][col.source],
  );
  const [pendingChange, setPendingChange] = useState<any>(null);

  useEffect(() => {
    setPendingChange(
      data.pendingChanges && data.pendingChanges[col.source]
        ? data.pendingChanges[col.source]
        : null,
    );
  }, [data, changedSeats]);

  useEffect(() => {
    setEdited(changedSeats[data._id] && changedSeats[data._id][col.source]);
  }, [changedSeats]);

  const renderTooltip = (e: any) => {
    if (!pendingChange) {
      return;
    }
    const display = getPendingChangeTooltip(
      getFormatedDate(pendingChange),
      getFormatedDate(value),
      col.source,
    );
    const rect = e.target.getBoundingClientRect();
    showTooltip(display, rect.x, rect.y - 40);
  };

  const dateFormat = () => {
    if (!value && !edited && !pendingChange) {
      return <div>-</div>;
    }
    const date = edited || pendingChange || value;
    const formatedDate = getFormatedDate(date);
    return (
      <div
        onMouseEnter={renderTooltip}
        className={pendingChange && !edited ? s.pendingChange : s.displayDate}
      >
        <DateSmall className={s.dateIcon} />
        {formatedDate}
      </div>
    );
  };
  const handleCellClick = () => {
    if (blockEdit) {
      return;
    }
    setRef(myId);
    setFocus(true);
  };
  const handleDatePick = (date: any) => {
    const IsoDate = date.toISOString();
    const newChangedSeats = {
      ...changedSeats,
      [data._id]: { ...changedSeats[data._id], [col.source]: IsoDate },
    };
    setChangedSeats(newChangedSeats);
    setEdited(IsoDate);
    setTimeout(() => {
      setFocus(false);
    }, 0);
  };
  return (
    <div className={s.main}>
      {focus && currentCellRef === myId ? (
        <div onClick={(event: any) => event.stopPropagation()}>
          <DatePicker
            onChange={(date: any) => handleDatePick(date)}
            popoverProps={{
              placement: 'bottom',
            }}
            customInput={<Input className={s.datePicker}></Input>}
            inputProps={{ prefix: null }}
            initialOpen={true}
          />
        </div>
      ) : (
        <div onClick={handleCellClick} className={s.nonFocus}>
          {edited && <CircleSmallFilled className={s.edited} size="18px" />}
          {dateFormat()}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentCellRef: state.seats.currentCellRef,
  changedSeats: state.seats.changedSeats,
});

const mapDispatchToProps = (dispatch: any) => ({
  setChangedSeats: (changedSeats: any) =>
    dispatch(setChangedSeats(changedSeats)),
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDatePicker);
