import { AUTH } from '../actions/constants';

const initState: any = {
  user: null,
  originalPath: null,
  isUserSettingsModalShown: false,
  headOfDelegates: [],
};

export default (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH.SIGNIN:
      return { ...state, user: payload };
    case AUTH.SIGNOUT:
      return { ...state, user: null };
    case AUTH.ORIGINAL_PATH:
      return { ...state, originalPath: payload };
    case AUTH.DISPLAY_USER_SETTINGS_MODAL:
      return { ...state, isUserSettingsModalShown: payload };
    case AUTH.HEAD_OF_DELEGATES:
      return { ...state, headOfDelegates: payload };
    default:
      return state;
  }
};
