import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './JustificationModalMessage.scss';
import { Modal, Text, Button, CloseButton, InputArea } from "@wix/design-system";
import {
  displayJustificationModalMessage,
  onJustificationModalSubmitClicked,
} from '../../../../redux/actions/seatsActions';
import { EditSmall } from 'wix-ui-icons-common';
import {
  defaultStatusReasonMessage,
  defaultRejectedPrePendingMessage,
} from '../../../../../../Client/config';

const JustificationModalMessage = (props: any) => {
  const {
    justificationModalMessage,
    displayJustificationModalMessage,
    onJustificationModalSubmitClicked,
  } = props;
  const {
    seatId,
    requester,
    businessJustificationMessage,
    statusReason,
    showStatusReason,
    editStatusReason,
    canEditBusinessJustification,
    canEditStatusReason,
    user,
    status,
  } = justificationModalMessage || {};

  const defaultBusinessJustificationMessage =
    'No business justification was given.';
  const emptyStatusReasonMessage = 'No change status justification was given.';
  const cancelButtonText = 'Cancel';
  const currentDefaultRejectedMessage =
    status === 11
      ? defaultRejectedPrePendingMessage
      : defaultStatusReasonMessage;

  const [statusReasonUser, setStatusReasonUser] = useState('');
  const [statusReasonMessage, setStatusReasonMessage] = useState('');

  const [
    editBusinessJustificationMessage,
    setEditBusinessJustificationMessage,
  ] = useState(false);
  const [editStatusReasonMessage, setEditStatusReasonMessage] = useState(false);
  const [
    newBusinessJustificationMessage,
    setNewBusinessJustificationMessage,
  ] = useState(businessJustificationMessage);
  const [newStatusReasonMessage, setNewStatusReasonMessage] = useState(
    currentDefaultRejectedMessage,
  );
  const [dateTime, setDateTime] = useState<any>();
  const [
    editedStatusJustificationDateTime,
    setEditedStatusJustificationDateTime,
  ] = useState<any>({});
  const [username, setUsername] = useState<any>();

  useEffect(() => {
    setNewBusinessJustificationMessage(businessJustificationMessage);
  }, [businessJustificationMessage]);

  useEffect(() => {
    if (editStatusReasonMessage && !statusReasonMessage) {
      setNewStatusReasonMessage(currentDefaultRejectedMessage);
      return;
    }
    setNewStatusReasonMessage(statusReasonMessage);
  }, [statusReasonMessage, editStatusReasonMessage]);

  useEffect(() => {
    setEditStatusReasonMessage(editStatusReason);
  }, [editStatusReason]);

  useEffect(() => {
    setDateTime(
      editedStatusJustificationDateTime[seatId] || statusReason?.time,
    );
    setStatusReasonUser(
      editedStatusJustificationDateTime[seatId]
        ? username
        : statusReason?.username,
    );
    setStatusReasonMessage(statusReason?.reason);
  }, [statusReason]);

  const onSubmitClicked = () => {
    let changes = {};
    if (newBusinessJustificationMessage !== businessJustificationMessage) {
      changes = {
        businessJustificationMessage: newBusinessJustificationMessage,
      };
    }
    if (newStatusReasonMessage !== statusReasonMessage) {
      setCurrentDateTime();
      if (!username) {
        setEditUsername();
      }
      changes = { ...changes, statusReasonMessage: newStatusReasonMessage };
    }
    onJustificationModalSubmitClicked(seatId, changes);
    onClose();
  };

  const setCurrentDateTime = () => {
    const dateTime = new Date();
    setEditedStatusJustificationDateTime({
      ...editedStatusJustificationDateTime,
      [seatId]: dateTime,
    });
  };

  const setEditUsername = () => {
    const userNameEndIndex = user.email.indexOf('@');
    setUsername(user.email.substring(0, userNameEndIndex));
  };

  const onCancel = () => {
    onClose();
    setNewBusinessJustificationMessage(businessJustificationMessage);
    setNewStatusReasonMessage(statusReasonMessage);
  };

  const onClose = () => {
    displayJustificationModalMessage(null);
    setEditBusinessJustificationMessage(false);
    setEditStatusReasonMessage(false);
  };

  const pad = (num: number) => {
    if (num < 10) {
      return '0' + num;
    }
    return num;
  };

  return (
    <Modal isOpen={justificationModalMessage || false}>
      <div className={s.main}>
        <div>
          <div className={s.top}>
            <CloseButton
              size="small"
              className={s.closeButton}
              onClick={onCancel}
            ></CloseButton>
          </div>
        </div>
        {editBusinessJustificationMessage ? (
          <div className={s.titleBox}>
            <Text className={s.title}>Business Justification</Text>
            <InputArea
              maxHeight="300px"
              autoGrow
              value={newBusinessJustificationMessage}
              onChange={(e) =>
                setNewBusinessJustificationMessage(e.target.value)
              }
            />
          </div>
        ) : (
          <div className={s.titleBox}>
            <div>
              <Text className={s.title}>
                {businessJustificationMessage
                  ? `${requester}'s Business Justification`
                  : defaultBusinessJustificationMessage}
              </Text>
              {canEditBusinessJustification && (
                <EditSmall
                  onClick={() => setEditBusinessJustificationMessage(true)}
                  style={{ cursor: 'pointer' }}
                />
              )}
              {canEditBusinessJustification && (
                <Text
                  onClick={() => setEditBusinessJustificationMessage(true)}
                  style={{ cursor: 'pointer' }}
                  className={s.statusReasonEdit}
                >
                  Edit
                </Text>
              )}
            </div>
            {businessJustificationMessage && (
              <Text className={s.message}>{businessJustificationMessage}</Text>
            )}
          </div>
        )}
        {showStatusReason && (
          <div>
            {editStatusReasonMessage ? (
              <div className={s.titleBox}>
                <Text className={s.title}>Status Change Justification</Text>
                <InputArea
                  maxHeight="300px"
                  autoGrow
                  value={newStatusReasonMessage}
                  onChange={(e) => setNewStatusReasonMessage(e.target.value)}
                />
              </div>
            ) : (
              <div className={s.titleBox}>
                <div>
                  <Text className={s.title}>
                    {statusReasonMessage
                      ? `Status Change Justification`
                      : emptyStatusReasonMessage}
                  </Text>
                  {canEditStatusReason && (
                    <EditSmall
                      onClick={() => setEditStatusReasonMessage(true)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  {canEditStatusReason && (
                    <Text
                      onClick={() => setEditStatusReasonMessage(true)}
                      style={{ cursor: 'pointer' }}
                      className={s.statusReasonEdit}
                    >
                      Edit
                    </Text>
                  )}
                </div>
                <div>
                  <Text className={s.message}>{statusReasonMessage}</Text>
                </div>
                {statusReasonMessage && (
                  <Text className={s.statusReasonEdit}>
                    Last edited by {statusReasonUser} at{' '}
                    {pad(dateTime.getHours())}:{pad(dateTime.getMinutes())}:
                    {pad(dateTime.getSeconds())} {pad(dateTime.getDate())}-
                    {pad(dateTime.getMonth() + 1)}-{dateTime.getFullYear()}
                  </Text>
                )}
              </div>
            )}
          </div>
        )}
        <div className={s.bottom}>
          <div className={s.left}></div>
          <div className={s.right}>
            <Button className={s.cancelButton} onClick={onCancel}>
              {cancelButtonText}
            </Button>
            {canEditBusinessJustification || canEditStatusReason ? (
              <Button className={s.submitButton} onClick={onSubmitClicked}>
                Submit
              </Button>
            ) : (
              <Button className={s.submitButton} onClick={onCancel}>
                OK
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  justificationModalMessage: state.seats.justificationModalMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
  onJustificationModalSubmitClicked: (seatId: any, changes: any) =>
    dispatch(onJustificationModalSubmitClicked(seatId, changes)),
  displayJustificationModalMessage: (message: any) =>
    dispatch(displayJustificationModalMessage(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JustificationModalMessage);
