import React from 'react';
import { connect } from 'react-redux';
import s from './ModalToolTip.scss';

const ToolTip = (props: any) => {
  const { tooltipText, tooltipX, tooltipY } = props;
  if (!tooltipText || !tooltipX || !tooltipY) {
    return null;
  }
  return (
    <div
      className={s.tooltip}
      style={{
        position: 'absolute',
        top: `${Number(tooltipY)}px`,
        left: `${Number(tooltipX)}px`,
        zIndex: 1000,
      }}
    >
      {tooltipText}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  tooltipY: state.requestModal.tooltipY,
  tooltipX: state.requestModal.tooltipX,
  tooltipText: state.requestModal.tooltipText,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ToolTip);
