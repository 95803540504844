import React, { useState, useEffect } from 'react';
import s from './RequesterText.scss';
import { connect } from 'react-redux';
import { showTooltip } from '../../../redux/actions/tooltipActions';
import { MAX_CHARS } from '../../../../../Client/config';

const RequesterText = (props: any) => {
  const { value, avatarsHash, col, showTooltip, data } = props;
  const maxChars: number = col.maxChars || MAX_CHARS;

  useEffect(() => {
    setAvatarImage(avatarUrl());
  }, [data]);

  const renderTooltip = (e: any) => {
    if (value.length <= maxChars) {
      return;
    }
    const rect = e.target.getBoundingClientRect();
    showTooltip(value, rect.x, rect.y - 40);
  };

  const avatarUrl = () => {
    if (avatarsHash && avatarsHash[value]) {
      return avatarsHash[value];
    }
    return 'https://i.stack.imgur.com/l60Hf.png';
  };
  const [avatarImage, setAvatarImage] = useState(avatarUrl());

  return (
    <div className={s.main}>
      {avatarImage && (
        <img
          className={s.avatar}
          src={avatarImage}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = 'https://i.stack.imgur.com/l60Hf.png';
          }}
          width="20"
          height="20"
        ></img>
      )}
      <div onMouseEnter={renderTooltip}>
        {value && value.length > maxChars
          ? `${value.substring(0, maxChars - 3)}...`
          : value}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  avatarsHash: state.dropdowns.avatarsHash,
});

const mapDispatchToProps = (dispatch: any) => ({
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequesterText);
