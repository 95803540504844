import React, { useState } from 'react';
import s from './UserSettings.scss';
import { connect } from 'react-redux';
import { displayUserSettingsModal } from '../../../redux/actions/authActions';
import { permissions } from '../../../../../Client/config';
import { ChevronDownSmall } from 'wix-ui-icons-common';
import { Text } from "@wix/design-system";

const UserSettings = (props: any) => {
  const { avatarsHash, user, displayUserSettingsModal } = props;

  const avatarUrl = () => {
    if (avatarsHash && avatarsHash[user.name]) {
      return avatarsHash[user.name];
    }
    return 'https://i.stack.imgur.com/l60Hf.png';
  };

  const [avatarImage, setAvatarImage] = useState(avatarUrl());

  const handleUserSettingsClicked = () => {
    displayUserSettingsModal(true);
  };

  return (
    <div className={s.main} onClick={handleUserSettingsClicked}>
      <div>
        {avatarImage && (
          <img
            className={s.avatar}
            src={avatarImage}
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src = 'https://i.stack.imgur.com/l60Hf.png';
            }}
            width="37.5"
            height="36"
          ></img>
        )}
      </div>
      <section>
        <Text className={`${s.groupText} ${s.bold}`}>{user.name}</Text>
        <Text className={s.groupText}>{permissions[user.permission]}</Text>
      </section>
      <ChevronDownSmall style={{ marginLeft: '16px' }}></ChevronDownSmall>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  avatarsHash: state.dropdowns.avatarsHash,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  displayUserSettingsModal: (isShow: any) =>
    dispatch(displayUserSettingsModal(isShow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
