import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import s from './EmployeesTable.scss';
import { Divider, Text, TextButton } from "@wix/design-system";
import ColumnGroup from './ColumnGroup/ColumnGroup';
import { ChevronDown, ChevronUp, InfoCircleSmall } from 'wix-ui-icons-common';
import {
  tableModes,
  SECOND_HEIRACHY_HC_TARGET_TEAMS,
} from '../../../Client/config';
import { setTableMode } from '../redux/actions/tableActions';
import { showTooltip, hideTooltip } from '../redux/actions/tooltipActions';
import { setDataFilters } from '../redux/actions/filterAction';
import GroupEmployeesTable from './GroupEmployeesTable/GroupEmployeesTable';

const EmployeesTable = (props: any) => {
  const {
    columnGroups,
    sortCol,
    setCurrentView,
    employeesData,
    setTableMode,
    employeesSearchResult,
    showTooltip,
    hideTooltip,
    setDataFilters,
  } = props;

  const [sortFunc, setSortFunc] = useState('Asc');
  const [companiesShown, setCompaniesShown] = useState<any>([]);
  const [subTeamsShown, setSubTeamsShown] = useState<any>([]);

  useEffect(() => {
    if (employeesSearchResult) {
      const employeesTableIds: any = [];
      for (const group of employeesSearchResult) {
        employeesTableIds.push(employeesSearchResult.indexOf(group));
      }
      setCompaniesShown(employeesTableIds);
    } else {
      setCompaniesShown([]);
    }
  }, [employeesSearchResult]);

  const renderTooltip = (event: any, tooltip: any) => {
    const rect = event.target.getBoundingClientRect();
    showTooltip(tooltip, rect.x, rect.y - 40);
  };

  const onSeatsClicked = (
    type: any,
    name: any,
    statuses: any,
    positionTypes: any,
    subTeamsRows: any,
  ) => {
    const namesToFilter = [name];
    if (
      type === 'company' &&
      (SECOND_HEIRACHY_HC_TARGET_TEAMS.includes(name) || name.includes('\\'))
    ) {
      type = 'subTeam';
      if (subTeamsRows && subTeamsRows.length > 1) {
        for (const subTeam of subTeamsRows) {
          if (!namesToFilter.includes(subTeam.name)) {
            namesToFilter.push(subTeam.name);
          }
        }
      }
    }
    const filters: any = {
      'Position Details': { [type]: namesToFilter },
      Status: { status: statuses },
    };
    if (positionTypes) {
      filters['Student / intern'] = { position: positionTypes };
    }
    setDataFilters(filters);
    setTableMode(tableModes.teamRequests);
  };

  const onTeamClicked = (entityName: any, type: any) => {
    if (type === 'company') {
      const employeesShownCopy: any = [...companiesShown];
      if (employeesShownCopy.includes(entityName)) {
        const entityIndex = employeesShownCopy.indexOf(entityName);
        employeesShownCopy.splice(entityIndex, 1);
      } else {
        employeesShownCopy.push(entityName);
      }
      setCompaniesShown(employeesShownCopy);
    } else {
      const subTeamsCopy: any = [...subTeamsShown];
      if (subTeamsCopy.includes(entityName)) {
        const entityIndex = subTeamsCopy.indexOf(entityName);
        subTeamsCopy.splice(entityIndex, 1);
      } else {
        subTeamsCopy.push(entityName);
      }
      setSubTeamsShown(subTeamsCopy);
    }
  };

  const renderColumnsGroups = () => {
    const groups = columnGroups.map((group: any, i: any) => {
      if (group.filtered || group.formOnly) {
        return null;
      }
      let size: number = 0;
      for (const column of group.columns) {
        if (!column.filtered) {
          size += column.size;
        }
      }
      size -= 1;
      return <ColumnGroup key={i} size={size} group={group} />;
    });
    return <div className={s.columnGroupsContainer}>{groups}</div>;
  };

  const renderHeader = () => {
    const headers = [];
    for (const group of columnGroups) {
      for (const col of group.columns) {
        headers.push(
          <th
            key={`${group.title}${col.title}`}
            style={{ minWidth: col.size, maxWidth: col.size }}
            className={`${s.headerCell} `}
            onClick={() => {
              setCurrentView(null);
              sortCol(col, sortFunc);
              setSortFunc(sortFunc === 'Desc' ? 'Asc' : 'Desc');
            }}
          >
            <div className={s.tableHeaderContainer}>
              <Text className={s.tableHeader}>
                {!col.hideTitle && (
                  <Fragment>
                    {col.title}
                    <InfoCircleSmall
                      size="18px"
                      className={s.infoIcon}
                      onMouseEnter={(event: any) =>
                        renderTooltip(event, col.tooltip)
                      }
                      onMouseLeave={hideTooltip}
                    />
                  </Fragment>
                )}
              </Text>
            </div>
          </th>,
        );
      }
    }
    return <tr>{headers}</tr>;
  };

  const isShowTargetCount = (
    rowData: any,
    rowType: any,
    companyHeirachy: any,
  ) => {
    if (rowType === 'company' || !companyHeirachy) {
      return rowData.target;
    }
    return false;
  };

  const getTableRow = (
    rowData: any,
    rowType: any,
    isLast: any = false,
    companyHeirachy: any = true,
  ) => {
    return (
      <Fragment key={rowData.name}>
        <tr
          key={rowData.name}
          className={
            rowType === 'company'
              ? companiesShown.includes(rowData.name)
                ? s.companyRowExpanded
                : s.companyRow
              : subTeamsShown.includes(rowData.name)
              ? s.tableRowExpanded
              : s.tableRow
          }
        >
          <td>
            <Text
              className={rowType === 'subTeam' ? s.subTeamText : s.teamsText}
            >
              <div className={s.chevronContainer}>
                {rowType === 'subTeam' && companyHeirachy && (
                  <Fragment>
                    <div
                      className={
                        isLast
                          ? s.halfDividerContainer
                          : s.verticalDividerContainer
                      }
                    >
                      <Divider direction="vertical" />
                    </div>
                    <div className={s.horizontalDividerContainer}>
                      <Divider />
                    </div>
                  </Fragment>
                )}
                {(rowType === 'company' &&
                  companiesShown.includes(rowData.name)) ||
                (rowType === 'subTeam' &&
                  subTeamsShown.includes(rowData.name)) ? (
                  <div
                    className={
                      rowType === 'company' ? s.companyChevronUpContainer : ''
                    }
                  >
                    <ChevronUp
                      height="19px"
                      width="30px"
                      viewBox="0 0 10 24"
                      style={{ cursor: 'pointer' }}
                      onClick={() => onTeamClicked(rowData.name, rowType)}
                    />
                    {rowType === 'company' && (
                      <div className={s.companyDividerContainer}>
                        <Divider direction="vertical" />
                      </div>
                    )}
                  </div>
                ) : (
                  <ChevronDown
                    height="19px"
                    width="30px"
                    viewBox="0 0 10 24"
                    style={{ cursor: 'pointer' }}
                    onClick={() => onTeamClicked(rowData.name, rowType)}
                  />
                )}
              </div>
              {rowData.type === 'subGuild'
                ? rowData.name + ' Guild'
                : rowData.name}
              {rowType === 'subTeam' && companyHeirachy ? (
                <div style={{ marginRight: '50px' }} />
              ) : (
                <div style={{ marginRight: '30px' }} />
              )}
            </Text>
          </td>
          <td>
            <Text className={s.tableText}>
              {rowData.nonStudentEmployeesCount}
            </Text>
          </td>
          <td>
            <Text className={s.tableText}>
              {isShowTargetCount(rowData, rowType, companyHeirachy)
                ? rowData.target
                : '-'}
            </Text>
          </td>
          <td>
            {rowData.nonStudentsOpenAndFocusSeatsCount > 0 ? (
              <div className={s.textButton}>
                <TextButton
                  className={s.textButton}
                  onClick={() =>
                    onSeatsClicked(
                      rowData.type,
                      rowData.name,
                      [2, 5, 9, 13],
                      ['No'],
                      rowData.subTeamsRows,
                    )
                  }
                >
                  {rowData.nonStudentsOpenAndFocusSeatsCount}
                </TextButton>
              </div>
            ) : (
              <Text className={s.tableText}>
                {rowData.nonStudentsOpenAndFocusSeatsCount}
              </Text>
            )}
          </td>
          <td>
            {rowData.pendingSeatsCount > 0 ? (
              <div className={s.textButton}>
                <TextButton
                  className={s.textButton}
                  onClick={() =>
                    onSeatsClicked(
                      rowData.type,
                      rowData.name,
                      [1],
                      ['No'],
                      rowData.subTeamsRows,
                    )
                  }
                >
                  {rowData.pendingSeatsCount}
                </TextButton>
              </div>
            ) : (
              <Text className={s.tableText}>{rowData.pendingSeatsCount}</Text>
            )}
          </td>
          <td>
            <Text className={s.tableText}>
              {isShowTargetCount(rowData, rowType, companyHeirachy)
                ? rowData.remaining
                : '-'}
            </Text>
          </td>
          <td></td>
          <td>
            <Text className={s.tableText}>{rowData.studentEmployeesCount}</Text>
          </td>
          <td>
            {rowData.studentsOpenAndFocusSeatsCount > 0 ? (
              <div className={s.textButton}>
                <TextButton
                  className={s.textButton}
                  onClick={() =>
                    onSeatsClicked(
                      rowData.type,
                      rowData.name,
                      [2, 5, 9, 13],
                      ['Student', 'Intern', 'Student & Intern'],
                      rowData.subTeamsRows,
                    )
                  }
                >
                  {rowData.studentsOpenAndFocusSeatsCount}
                </TextButton>
              </div>
            ) : (
              <Text className={s.tableText}>
                {rowData.studentsOpenAndFocusSeatsCount}
              </Text>
            )}
          </td>
          <td></td>
        </tr>
        {rowType === 'company' &&
          companiesShown.includes(rowData.name) &&
          rowData.subTeamsRows.map((subTeamData: any, subTeamIndex: number) => {
            if (subTeamIndex === rowData.subTeamsRows.length - 1) {
              return getTableRow(subTeamData, 'subTeam', true);
            }
            return getTableRow(subTeamData, 'subTeam');
          })}
        {rowType === 'subTeam' && subTeamsShown.includes(rowData.name) && (
          <tr className={s.groupEmployeesTable}>
            <td colSpan={10} className={s.groupEmployeesTable}>
              <GroupEmployeesTable
                groupEmployeesData={rowData.employees}
              ></GroupEmployeesTable>
            </td>
          </tr>
        )}
      </Fragment>
    );
  };

  const renderTable = () => {
    const total = {
      name: 'Total',
      nonStudentEmployeesCount: 0,
      studentEmployeesCount: 0,
      target: 0,
      studentsOpenAndFocusSeatsCount: 0,
      nonStudentsOpenAndFocusSeatsCount: 0,
      pendingSeatsCount: 0,
      remaining: 0,
      index: 0,
    };
    const data = employeesSearchResult || employeesData;
    const tableRows = data.map((rowData: any, index: number) => {
      total.nonStudentEmployeesCount += rowData.nonStudentEmployeesCount;
      total.studentEmployeesCount += rowData.studentEmployeesCount;
      total.target += rowData.target;
      total.nonStudentsOpenAndFocusSeatsCount +=
        rowData.nonStudentsOpenAndFocusSeatsCount;
      total.studentsOpenAndFocusSeatsCount +=
        rowData.studentsOpenAndFocusSeatsCount;
      total.pendingSeatsCount += rowData.pendingSeatsCount;
      total.remaining += rowData.remaining;
      total.index += index;
      if (rowData.name === 'Misc.') {
        return rowData.subTeamsRows.map((subTeamData: any) => {
          return getTableRow(subTeamData, 'subTeam', false, false);
        });
      }
      return getTableRow(rowData, 'company');
    });
    tableRows.push(
      <tr key={total.index} style={{ background: '#F0F6FF' }}>
        <td>
          <Text className={s.tableText}>{total.name}</Text>
        </td>
        <td>
          <Text className={s.tableText}>{total.nonStudentEmployeesCount}</Text>
        </td>
        <td>
          <Text className={s.tableText}>{total.target}</Text>
        </td>
        <td>
          <Text className={s.tableText}>
            {total.nonStudentsOpenAndFocusSeatsCount}
          </Text>
        </td>
        <td>
          <Text className={s.tableText}>{total.pendingSeatsCount}</Text>
        </td>
        <td>
          <Text className={s.tableText}>{total.remaining}</Text>
        </td>
        <td></td>
        <td>
          <Text className={s.tableText}>{total.studentEmployeesCount}</Text>
        </td>
        <td>
          <Text className={s.tableText}>
            {total.studentsOpenAndFocusSeatsCount}
          </Text>
        </td>
        <td></td>
      </tr>,
    );
    return tableRows;
  };

  return (
    <div id="table-container" className={s.tableContainer}>
      <div className={s.columnGroupsContainerSticky} style={{ width: 1600 }}>
        {renderColumnsGroups()}
      </div>
      <div>
        <table id="table" className={s.table}>
          <thead>{renderHeader()}</thead>
          {(!employeesSearchResult ||
            (employeesSearchResult && employeesSearchResult.length !== 0)) && (
            <tbody>{renderTable()}</tbody>
          )}
        </table>
      </div>
      {employeesSearchResult && employeesSearchResult.length === 0 && (
        <div className={s.noResultsContainer}>
          <Text className={s.noResultsText}>No results found</Text>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  columnGroups: state.employeesTable.columnGroups,
  employeesData: state.employeesTable.employeesData,
  employeesSearchResult: state.employeesTable.employeesSearchResult,
});

const mapDispatchToProps = (dispatch: any) => ({
  setTableMode: (mode: tableModes) => dispatch(setTableMode(mode)),
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
  hideTooltip: () => dispatch(hideTooltip()),
  setDataFilters: (dataFilters: any) => dispatch(setDataFilters(dataFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesTable);
