import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/authReducer';
import seatsReducer from '../reducers/seatsReducer';
import filterReducer from '../reducers/filterReducer';
import requestModalReducer from '../reducers/requestModalReducer';
import dropdownsReducer from '../reducers/dropdownsReducer';
import tooltipReducer from '../reducers/tooltipReducer';
import tableReducer from '../reducers/tableReducer';
import employeesTableReducer from '../reducers/employeesTableReducer';
import headCountReducer from '../reducers/headCountReducer';
import modalsReducer from '../reducers/modalsReducer';
import { reducer as formReducer } from 'redux-form';

const middleWare = [thunk];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    auth: authReducer,
    seats: seatsReducer,
    filter: filterReducer,
    requestModal: requestModalReducer,
    dropdowns: dropdownsReducer,
    form: formReducer,
    tooltip: tooltipReducer,
    table: tableReducer,
    headCount: headCountReducer,
    employeesTable: employeesTableReducer,
    modals: modalsReducer,
  }),
  // composeEnhancers(applyMiddleware(...middleWare)),
  applyMiddleware(...middleWare),
);

export default store;
