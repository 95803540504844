import React, { useState } from 'react';
import { connect } from 'react-redux';
import s from './SortBy.scss';
import { Text, Button, Search } from "@wix/design-system";
import { sortCol } from '../../../redux/actions/seatsActions';
import { setCurrentView } from '../../../redux/actions/filterAction';
import SortAscending from 'wix-ui-icons-common/SortAscending';
import SortDescending from 'wix-ui-icons-common/SortDescending';

const SortBy = (props: any) => {
  const {
    setSortByDropdown,
    sortCol,
    columnGroups,
    setCurrentView,
    sort,
  } = props;
  const [ascending, setAscending] = useState(sort.type !== 'Desc');
  const [selectedColumn, setSelectedColumn] = useState<any>(sort.column);
  let num = 0;

  const handleApplyFilter = () => {
    const sortType = ascending ? 'Asc' : 'Desc';
    sortCol(selectedColumn, sortType);
    setCurrentView(null);
    setSortByDropdown(false);
  };
  const columns = [];
  for (const group of columnGroups) {
    if (!group.formOnly) {
      columns.push(...group.columns);
    }
  }
  const options = columns.map((col) => {
    return {
      id: num++,
      value: col.title,
      source: col.source,
    };
  });
  const handleOptionSelect = (opt: any) => {
    setSelectedColumn({ title: opt.value, source: opt.source });
  };
  // return !selectedColumn.title ? (
  //   <div onClick={(event: any) => event.stopPropagation()}>
  //     <CustomDropdown
  //       title={'Select Column'}
  //       options={options}
  //       onSelect={handleOptionSelect}
  //     />
  //   </div>
  // ) : (
  return (
    <div className={s.main} onClick={(event: any) => event.stopPropagation()}>
      <div className={s.header}>
        <Text className={s.headerText}>Sort by</Text>
      </div>
      <Search
        size="small"
        className={s.search}
        onSelect={handleOptionSelect}
        onFocus={() => {
          setSelectedColumn({});
        }}
        value={
          selectedColumn.title !== 'Recently Added' &&
          selectedColumn.title !== 'Last Modified'
            ? selectedColumn.title
            : ''
        }
        customInput="input"
        noLeftBorderRadius={true}
        noRightBorderRadius={true}
        placeholder="Search columns"
        clearButton={false}
        options={options}
      />
      <div className={s.columns}>
        <div
          className={s.option}
          onClick={() => {
            setSelectedColumn({ title: 'Recently Added', source: 'createdAt' });
          }}
        >
          <Text
            className={
              selectedColumn.source === 'createdAt'
                ? `${s.optionText} ${s.active}`
                : s.optionText
            }
          >
            Recently Added
          </Text>
        </div>
        <div
          className={s.option}
          onClick={() => {
            setSelectedColumn({ title: 'Last Modified', source: 'updatedAt' });
          }}
        >
          <Text
            className={
              selectedColumn.source === 'updatedAt'
                ? `${s.optionText} ${s.active}`
                : s.optionText
            }
          >
            Last Modified
          </Text>
        </div>
        {/* <div
          className={s.option}
          onClick={() => {
            setSelectedColumn({});
          }}
        >
          <Text
            className={
              selectedColumn.title !== 'Recently Added' &&
              selectedColumn.title !== 'Last Modified'
                ? `${s.optionText} ${s.active}`
                : s.optionText
            }
          >
            {selectedColumn.title !== 'Recently Added' &&
            selectedColumn.title !== 'Last Modified'
              ? selectedColumn.title
              : 'By Column'}
          </Text>
          <ChevronRight />
        </div> */}
      </div>
      <div className={s.sortType}>
        <div className={s.sortOption} onClick={() => setAscending(true)}>
          <SortAscending
            className={ascending ? `${s.sortIcon} ${s.active}` : s.sortIcon}
            size="16px"
          />
          <Text
            className={ascending ? `${s.optionText} ${s.active}` : s.optionText}
          >
            Ascending
          </Text>
        </div>
        <div className={s.sortOption} onClick={() => setAscending(false)}>
          <SortDescending
            className={ascending ? s.sortIcon : `${s.sortIcon} ${s.active}`}
            size="16px"
          />
          <Text
            className={ascending ? s.optionText : `${s.optionText} ${s.active}`}
          >
            Descending
          </Text>
        </div>
      </div>
      <div className={s.footer}>
        <Button
          size="tiny"
          className={s.cancelButton}
          onClick={() => setSortByDropdown(false)}
        >
          Cancel
        </Button>
        <Button
          size="tiny"
          className={s.filterButton}
          onClick={() => handleApplyFilter()}
        >
          Apply
        </Button>
      </div>
    </div>
  );
  // );
};

const mapStateToProps = (state: any) => ({
  columnGroups: state.filter.columnGroups,
  sort: state.seats.sort,
});

const mapDispatchToProps = (dispatch: any) => ({
  sortCol: (name: any, sort: any) => dispatch(sortCol(name, sort)),
  setCurrentView: (value: any) => dispatch(setCurrentView(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortBy);
