export const columns: any = [
  {
    title: 'Company/Department',
    filtered: false,
    display: true,
    columns: [
      {
        title: 'Teams',
        source: 'name',
        size: 240,
        hideTitle: true,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
      },
    ],
  },
  {
    title: 'Head Count',
    filtered: false,
    display: true,
    columns: [
      {
        title: 'Current HC',
        source: 'employees',
        size: 170,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
        tooltip: 'Current team members.',
      },
      {
        title: 'Target',
        source: 'target',
        size: 170,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
        tooltip: 'Number of employees the team should have.',
      },
    ],
  },
  {
    title: 'Seats Requests',
    filtered: false,
    display: true,
    columns: [
      {
        title: 'Approved/focus/DIY',
        source: 'seats',
        size: 170,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
        tooltip:
          'Number of position requests in Approved, Focus or DIY status (excluding students and interns).',
      },
      {
        title: 'Pending Seats',
        source: 'remaining',
        size: 170,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
        tooltip: 'Number of seats waiting for approval.',
      },
      {
        title: 'Balance',
        source: 'remaining',
        size: 170,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
        tooltip:
          'The gap between the team’s target and the current HC + open Seats.',
      },
    ],
  },
  {
    title: '',
    filtered: false,
    display: true,
    columns: [
      {
        title: '',
        source: 'none',
        size: 1,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
        hideTitle: true,
      },
    ],
  },
  {
    title: 'Students',
    filtered: false,
    display: true,
    columns: [
      {
        title: 'Students HC',
        source: 'employees',
        size: 170,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
        tooltip: 'Current students and interns team members.',
      },
      {
        title: 'Students Approved/focus/DIY',
        source: 'seats',
        size: 170,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
        tooltip:
          'Number of Student or intern position requests in Approved, Focus or DIY status.',
      },
    ],
  },
  {
    title: '',
    filtered: false,
    display: true,
    columns: [
      {
        title: '',
        source: 'none',
        size: 349,
        type: 'text',
        edit: false,
        filtered: false,
        display: true,
        hideTitle: true,
      },
    ],
  },
];
