import { httpClient } from '../../../../Client/httpClient';
import { SEATS, FILTERS, TABLE } from './constants';
import lodash from 'lodash';
import {
  statusNamesWithError as statusNames,
  guildPriorityStatuses,
  rejectedReasonStatuses,
  isGuildPriorityActive,
  seatsSegmentSize,
} from '../../../../Client/config';
import { updateGuildPriorityDropdownData } from './dropdownsActions';
import { getSeatsSpreadsheet, isPrePending } from '../../../../Client/utils';
import { allColumns } from '../reducers/columns/columns';

export const setSeats = (
  viewChanged: any = false,
  fetchStart: any = 0,
  fetchEnd: any = seatsSegmentSize * 2,
) => async (dispatch: any, getState: any) => {
  try {
    const { seatId } = getState().seats;
    if (seatId) {
      try {
        const { data } = await httpClient.get(`/seats/approver-seat/${seatId}`);
        const seatArr = [data.seat];

        dispatch(setSearchResults(seatArr, true));
        dispatch({
          type: SEATS.SET_SEATS,
          payload: seatArr,
        });
        // dispatch(setSearchResults(seatArr, true));
        dispatch(setSeatId(null));
        dispatch(setSeats());
        return;
      } catch (error: any) {
        console.log(error);
      }
    }
    const { seats, seatsCount } = getState().seats;
    if (fetchEnd >= seatsCount?.filtered && !viewChanged) {
      return;
    }
    const userViewSettings: any = getUserViewSettings(getState);
    userViewSettings.viewStart = fetchStart;
    userViewSettings.viewEnd = fetchEnd;
    console.time('Get seats');
    const { data } = await httpClient.post(`/seats/seats`, userViewSettings);
    if (data.amountOfSeatsByTeamAndStatus) {
      dispatch({
        type: SEATS.SET_AMOUNT_OF_SEATS_BY_TEAM_AND_STATUS,
        payload: data.amountOfSeatsByTeamAndStatus,
      });
    }
    if (userViewSettings.viewId !== data.userViewSettings.viewId) {
      dispatch({
        type: SEATS.SET_VIEW_ID,
        payload: data.userViewSettings.viewId,
      });
    }
    console.timeEnd('Get seats');
    // dispatch(setSearchResults(data.seats, true));
    if (!isSeatsResponseValid(data.userViewSettings, getState)) {
      return;
    }
    const seatsCopy = lodash.cloneDeep(seats);
    seatsCopy.push(...data.seats);
    const refreshView =
      viewChanged || fetchEnd < seatsCopy.length || data.seats.length === 0;
    const { refreshSeatsTable, showSeatsTableLoader } = getState().table;
    if (refreshSeatsTable !== refreshView) {
      dispatch(setRefreshSeatsTable(refreshView));
    }
    dispatch({
      type: SEATS.SET_SEATS,
      payload: refreshView ? data.seats : seatsCopy,
    });
    dispatch({
      type: SEATS.SEARCH_SEATS,
      payload: refreshView ? data.seats : seatsCopy,
    });
    dispatch({
      type: SEATS.SET_VIEW_END,
      payload: fetchEnd,
    });
    dispatch({
      type: SEATS.SET_SEATS_COUNT,
      payload: data.amountOfSeats,
    });
    if (showSeatsTableLoader) {
      dispatch({
        type: TABLE.SHOW_SEATS_TABLE_LOADER,
        payload: false,
      });
    }
  } catch (error: any) {
    console.error(error);
  }
};

const isSeatsResponseValid = (userViewSettings: any, getState: any) => {
  const { dataFilters, searchValue } = getState().filter;
  const { sort } = getState().seats;
  if (
    userViewSettings.searchValue !== searchValue ||
    JSON.stringify(userViewSettings.filters) !== JSON.stringify(dataFilters) ||
    JSON.stringify(userViewSettings.sort) !== JSON.stringify(sort)
  ) {
    return false;
  }
  return true;
};

export const setRefreshSeatsTable = (isRefresh: any) => async (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    type: TABLE.REFRESH_SEATS_TABLE,
    payload: isRefresh,
  });
};

const getUserViewSettings = (getState: any) => {
  try {
    const { dataFilters, searchValue } = getState().filter;
    const { viewId, sort } = getState().seats;
    return {
      viewId,
      sort,
      searchValue,
      filters: dataFilters,
    };
  } catch (error: any) {
    console.error(error);
  }
};

export const fetchMoreSeats = () => async (dispatch: any, getState: any) => {
  try {
    const { viewEnd } = getState().seats;
    const fetchStart = viewEnd;
    const fetchEnd = viewEnd + seatsSegmentSize;
    dispatch(setSeats(false, fetchStart, fetchEnd));
  } catch (error: any) {
    console.error(error);
  }
};

export const setSeatId = (seatId: any) => async (
  dispatch: any,
  getState: any,
) => {
  try {
    dispatch({
      type: SEATS.SET_SEAT_ID,
      payload: seatId,
    });
  } catch (error: any) {
    console.error(error);
  }
};
export const setSeatsLocal = (data: any) => async (
  dispatch: any,
  getState: any,
) => {
  try {
    dispatch({
      type: SEATS.SET_SEATS,
      payload: data,
    });
    const { columnGroups, dataFilters, searchValue } = getState().filter;
    let filteredData: any = applyFilters(data, dataFilters);
    if (searchValue) {
      filteredData = applySearch(
        filteredData,
        columnGroups,
        searchValue,
        dispatch,
      );
    }
    dispatch(setSearchResults(filteredData));
  } catch (error: any) {
    console.error(error);
  }
};

export const updateSeats = (changedSeats: any) => async (
  dispatch: any,
  getState: any,
) => {
  try {
    const res = await httpClient.put(`/seats/seats`, changedSeats, {});
    return res;
  } catch (error: any) {
    console.log(error);
  }
};
export const deleteSeat = (seatId: any) => async (
  dispatch: any,
  getState: any,
) => {
  try {
    const res = await httpClient.delete(`/seats/seat/${seatId}`);
    return res;
  } catch (error: any) {
    console.log(error);
  }
};

export const searchSeats = (value: any) => async (
  dispatch: any,
  getState: any,
) => {
  value = value.trim();
  dispatch({
    type: FILTERS.SET_SEARCH_VALUE,
    payload: value,
  });
  dispatch(setSeats(true));
  // dispatch(filterResults());
};
export const applyDataFilters = () => (dispatch: any, getState: any) => {
  dispatch(filterResults());
};

export const setSearchResults = (searchResults: any, sorted?: any) => (
  dispatch: any,
  getState: any,
) => {
  const { sort } = getState().seats;
  const sortedSeatchResults = sorted
    ? searchResults
    : sortSearchResults(searchResults, sort);
  dispatch({
    type: SEATS.SEARCH_SEATS,
    payload: sortedSeatchResults,
  });
  // dispatch(updateDropdownFilterOptionsInstances(sortedSeatchResults));
};

export const setPagination = (pagination: number) => (dispatch: any) => {
  dispatch({
    type: SEATS.SET_PAGINATION,
    payload: pagination,
  });
};

export const sortCol = (column: any, type: any) => (
  dispatch: any,
  getState: any,
) => {
  // const descending = type === 'Desc';
  // const { searchResults, pagination } = getState().seats;
  // pagination > 0 && dispatch(setPagination(0));
  // const tableData = [...searchResults];
  // tableData.sort(getSortFunction(column.source, descending));
  dispatch({
    type: SEATS.SET_SORT,
    payload: { column, type },
  });
  dispatch(setSeats(true));
  // dispatch(setSearchResults(tableData, true));
};

export const setRef = (ref: any) => (dispatch: any, getState: any) => {
  setTimeout(() => {
    dispatch({
      type: SEATS.SET_REF,
      payload: ref,
    });
  }, 20);
};
export const clearRef = () => (dispatch: any, getState: any) => {
  dispatch({
    type: SEATS.SET_REF,
    payload: null,
  });
};

export const setChangedSeats = (changedSeats: any) => (
  dispatch: any,
  getState: any,
) => {
  if (isGuildPriorityActive) {
    dispatch(updateGuildPriorityDropdownData(changedSeats));
  }
  dispatch({
    type: SEATS.SET_CHANGED_SEATS,
    payload: changedSeats,
  });
};
export const setMessage = (message: any, ms: any) => (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    type: SEATS.SET_MESSAGE,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: SEATS.SET_MESSAGE,
      payload: null,
    });
  }, ms);
};
export const displayModalMessage = (message: any) => (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    type: SEATS.SET_MODAL_MESSAGE,
    payload: message,
  });
};
export const displayJustificationModalMessage = (message: any) => (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    type: SEATS.SET_JUSTIFICATION_MODAL_MESSAGE,
    payload: message,
  });
};

// const getBaseRoute = (permission: any) => {
//   const routeMap: any = {
//     1: 'requestor',
//     2: 'approver',
//     3: 'super-admin',
//     4: 'super-admin',
//     5: 'super-admin',
//   };
//   return routeMap[permission];
// };

const sortSearchResults = (searchResults: any, sort: any) => {
  const { type, column } = sort;
  const descending = type === 'Desc';
  searchResults.sort((a: any, b: any) => {
    const val1 = a[column.source];
    const val2 = b[column.source];
    if (!val1 || val1 === '-') {
      return 1;
    }
    if (!val2 || val2 === '-') {
      return -1;
    }
    if (Number(val1) && Number(val2)) {
      return descending
        ? Number(val2) - Number(val1)
        : Number(val1) - Number(val2);
    }
    if (
      a[column.source].toString().toLowerCase().trim() >
      b[column.source].toString().toLowerCase().trim()
    ) {
      return descending ? -1 : 1;
    }
    return descending ? 1 : -1;
  });
  return searchResults;
};

export const getReplacement = (seatId: number) => async (
  dispatch: any,
  getState: any,
) => {
  try {
    const replacementData: any = await httpClient.get(
      `/seats/replacement-name/${seatId}`,
    );
    const replacementNameResponse = replacementData.data;

    return replacementNameResponse;
  } catch (error: any) {
    console.error(`Failed to get replacemnet name, Error: ${error}`);
    return null;
  }
};

export const getSortFunction = (columnName: string, descending: boolean) => {
  switch (columnName) {
    case 'qApproved':
    case 'qRequest':
      return (a: any, b: any) => {
        if (!a[columnName] || a[columnName] === '-') {
          return descending ? 1 : -1;
        }
        if (!b[columnName] || b[columnName] === '-') {
          return descending ? -1 : 1;
        }

        const aValue = a[columnName].split('/').reverse().join('');
        const bValue = b[columnName].split('/').reverse().join('');

        if (aValue > bValue) {
          return descending ? -1 : 1;
        }
        return descending ? 1 : -1;
      };
    default:
      return (a: any, b: any) => {
        const val1 = a[columnName];
        const val2 = b[columnName];
        if (!val1 || val1 === '-') {
          return 1;
        }
        if (!val2 || val2 === '-') {
          return -1;
        }
        if (Number(val1) && Number(val2)) {
          return descending
            ? Number(val2) - Number(val1)
            : Number(val1) - Number(val2);
        }
        if (
          val1.toString().toLowerCase().trim() >
          val2.toString().toLowerCase().trim()
        ) {
          return descending ? -1 : 1;
        }
        return descending ? 1 : -1;
      };
  }
};

const filterResults = () => (dispatch: any, getState: any) => {
  const { seats } = getState().seats;
  const { columnGroups, dataFilters, searchValue } = getState().filter;
  let results: any = applyFilters(seats, dataFilters);
  if (searchValue) {
    results = applySearch(results, columnGroups, searchValue, dispatch);
  }
  dispatch(setSearchResults(results));
  dispatch(setPagination(0));
};

const applyFilters = (seats: any, dataFilters: any) => {
  const results: any = [];
  for (const row of seats) {
    let allFiltersValid = true;
    for (const filter in dataFilters) {
      for (const source in dataFilters[filter]) {
        if (
          !dataFilters[filter][source].includes(row[source]) &&
          !(
            source === 'status' &&
            dataFilters[filter][source].includes(12) &&
            row.pendingChanges &&
            Object.keys(row.pendingChanges).length > 0
          )
        ) {
          allFiltersValid = false;
          break;
        }
      }
      if (!allFiltersValid) {
        break;
      }
    }
    if (allFiltersValid) {
      results.push(row);
    }
  }
  return results;
};
const applySearch = (
  results: any,
  columnGroups: any,
  searchValue: any,
  dispatch: any,
) => {
  searchValue = searchValue.trim();
  if (searchValue.length === 0) {
    return results;
  }
  const words = searchValue.toString().toLowerCase().split(' ');
  const columns = [];
  const filteredResults = [];
  for (const group of columnGroups) {
    if (!group.formOnly) {
      columns.push(...group.columns);
    }
  }
  const filteringColumns = [];
  for (const row of results) {
    let containsAll = true;
    for (const word of words) {
      if (word) {
        let containsWord = false;
        for (const column of columns) {
          const source: any = column.source;
          const data: any =
            column.title === 'Status'
              ? statusNames[row[source]].label
              : row[source];
          if (data?.toString().toLowerCase().includes(word)) {
            containsWord = true;
            filteringColumns.push(column.title);
            break;
          }
        }
        if (!containsWord) {
          containsAll = false;
          break;
        }
      }
    }
    if (containsAll) {
      filteredResults.push(row);
    }
  }
  setFilteringColumns(filteringColumns, dispatch);
  return filteredResults;
};

const setFilteringColumns = (value: any, dispatch: any) => {
  dispatch({
    type: SEATS.SET_FILTERING_COLUMNS,
    payload: value,
  });
};

export const onJustificationModalSubmitClicked = (
  seatId: any,
  changes: any,
) => async (dispatch: any, getState: any) => {
  const { changedSeats } = getState().seats;
  let newChangedSeats: any = { ...changedSeats };
  let changed: any = false;
  if (changes?.businessJustificationMessage) {
    newChangedSeats = {
      ...newChangedSeats,
      [seatId]: {
        ...newChangedSeats[seatId],
        businessJustification: changes.businessJustificationMessage,
      },
    };
    changed = true;
  }
  if (changes?.statusReasonMessage) {
    newChangedSeats = {
      ...newChangedSeats,
      [seatId]: {
        ...newChangedSeats[seatId],
        statusReason: changes.statusReasonMessage,
      },
    };
    changed = true;
  }
  if (changed) {
    dispatch(setChangedSeats(newChangedSeats));
  }
};

export const setStatusReasons = () => async (dispatch: any, getState: any) => {
  const { data } = await httpClient.get(`/seats/statusReasons`);
  const { reasons } = data;
  dispatch({
    type: SEATS.SET_STATUS_JUSTIFICATIONS,
    payload: reasons,
  });
};

export const setIsPrepApproved = () => async (dispatch: any, getState: any) => {
  const { seats } = getState().seats;
  const newSeats = lodash.cloneDeep(seats);
  newSeats.forEach((seat: any) => {
    if (isPrePending(seat) && seat.status !== 10 && seat.status !== 11) {
      seat.isPrePendingApproved = true;
    }
  });
  dispatch({
    type: SEATS.SET_SEATS,
    payload: newSeats,
  });
};

export const onRejectStatusChange = (
  data: any,
  blockEdit: any,
  user: any,
) => async (dispatch: any, getState: any) => {
  const { changedSeats, statusJustifications } = getState().seats;
  let statusReason = {
    reason: data.statusReason || statusJustifications[data._id]?.reason,
    username:
      data.statusReasonUpdater || statusJustifications[data._id]?.updater,
    time:
      data.statusReasonUpdateTime ||
      new Date(statusJustifications[data._id]?.updatedTime) ||
      null,
  };
  if (changedSeats[data._id] && changedSeats[data._id].statusReason) {
    statusReason = {
      ...statusReason,
      reason: changedSeats[data._id].statusReason,
    };
  }
  const currentStatus =
    (changedSeats[data._id] && changedSeats[data._id].status) || data.status;
  dispatch(
    displayJustificationModalMessage({
      seatId: data._id,
      requester: data.requester,
      businessJustificationMessage: data.businessJustification,
      statusReason,
      showStatusReason: true,
      editStatusReason: true,
      canEditBusinessJustification: !blockEdit,
      canEditStatusReason: true,
      user,
      status: currentStatus,
    }),
  );
};

export const onGuildPriorityChange = (
  data: any,
  newChangedSeats: any,
  blockEdit: any,
  user: any,
) => async (dispatch: any, getState: any) => {
  const { seats } = getState().seats;
  const mergedSeats = getMergedSeats(seats, newChangedSeats);
  const changedSeat = mergedSeats.find((seat: any) => seat._id == data._id);
  if (newChangedSeats[data._id].pendingStatus) {
    if (
      changedSeat.status === 6 &&
      newChangedSeats[data._id].pendingStatus !== 6
    ) {
      newChangedSeats[data._id].status =
        newChangedSeats[data._id].pendingStatus;
      changedSeat.status = newChangedSeats[data._id].pendingStatus;
      delete newChangedSeats[data._id].pendingStatus;
      delete changedSeat.pendingStatus;
      dispatch(
        applyGuildPriorities(changedSeat, newChangedSeats, blockEdit, user),
      );
      return;
    }
    newChangedSeats[data._id].status = newChangedSeats[data._id].pendingStatus;
    changedSeat.status = newChangedSeats[data._id].pendingStatus;
    delete newChangedSeats[data._id].pendingStatus;
    delete changedSeat.pendingStatus;
  }
  const lowerPriority =
    (newChangedSeats[data._id].status === 6 && changedSeat.guildPriority) ||
    Number(newChangedSeats[data._id].pendingGuildPriority) >
    Number(changedSeat.guildPriority);
  const lesserPrioritySubGuildSeats = getLesserPrioritySubGuildSeats(
    data,
    mergedSeats,
    lowerPriority,
  );

  if (!lesserPrioritySubGuildSeats || !lesserPrioritySubGuildSeats.length) {
    dispatch(
      applyGuildPriorities(changedSeat, newChangedSeats, blockEdit, user),
    );
    return;
  }

  const modalMessage =
    lowerPriority || changedSeat.guildPriority === '-'
      ? 'Please note: this action will raise the priority of lower priority Seats'
      : 'Please note: this action will push down current Seats in equal & lower priority.';
  dispatch(
    displayModalMessage({
      title: 'Guild priority',
      message: modalMessage,
      type: 'confirm',
      buttonText: 'Submit',
      onSubmit: () => {
        dispatch(
          onConfirmGuildPriorityChange(
            data,
            newChangedSeats,
            lesserPrioritySubGuildSeats,
            mergedSeats,
            seats,
            blockEdit,
            user,
          ),
        );
      },
      cancelButtonText: 'Return',
    }),
  );
};

export const getMergedSeats = (seats: any, newChangedSeats: any) => {
  const mergedSeats: any = lodash.cloneDeep(seats);
  for (const seatId in newChangedSeats) {
    const currentSeat = newChangedSeats[seatId];
    const originalSeat = mergedSeats.find((seat: any) => seat._id == seatId);
    const originalSeatIndex = mergedSeats.findIndex(
      (seat: any) => seat._id == seatId,
    );

    for (const param in currentSeat) {
      originalSeat[param] = currentSeat[param];
    }

    mergedSeats.splice(originalSeatIndex, 1, originalSeat);
  }
  return mergedSeats;
};

const getLesserPrioritySubGuildSeats = (
  data: any,
  mergedSeats: any,
  lowerPriority: any,
) => {
  const changedSeat = mergedSeats.find((seat: any) => seat._id == data._id);

  const subGuild = changedSeat.subGuild;
  const site = changedSeat.site;
  const guildPriority = changedSeat.guildPriority;
  const pendingGuildPriority = changedSeat.pendingGuildPriority;
  if (!guildPriority) {
    return null;
  }
  const lesserPrioritySubGuildSeats: any = [];

  let subGuildPrioritySeats = mergedSeats.filter(
    (seat: any) =>
      seat._id != data._id &&
      seat.site === site &&
      seat.subGuild === subGuild &&
      seat.guildPriority &&
      guildPriorityStatuses.includes(seat.status),
  );
  if (pendingGuildPriority && pendingGuildPriority !== '-') {
    if (lowerPriority) {
      subGuildPrioritySeats = subGuildPrioritySeats.filter(
        (seat: any) =>
          Number(seat.guildPriority) > Number(guildPriority) &&
          Number(seat.guildPriority) <= Number(pendingGuildPriority),
      );
    } else {
      subGuildPrioritySeats = subGuildPrioritySeats.filter(
        (seat: any) =>
          Number(seat.guildPriority) < Number(guildPriority) &&
          Number(seat.guildPriority) >= Number(pendingGuildPriority),
      );
    }
  } else {
    subGuildPrioritySeats = subGuildPrioritySeats.filter(
      (seat: any) => Number(seat.guildPriority) >= Number(guildPriority),
    );
  }
  if (subGuildPrioritySeats.length) {
    for (const seat of subGuildPrioritySeats) {
      lesserPrioritySubGuildSeats.push({
        _id: Number(seat._id),
        guildPriority: Number(seat.guildPriority),
        status: seat.status,
      });
    }
  }
  return lesserPrioritySubGuildSeats;
};

const onConfirmGuildPriorityChange = (
  data: any,
  newChangedSeats: any,
  lesserPrioritySubGuildSeats: any,
  mergedSeats: any,
  seats: any,
  blockEdit: any,
  user: any,
) => async (dispatch: any, getState: any) => {
  lesserPrioritySubGuildSeats.sort(function (a: any, b: any) {
    return a.guildPriority - b.guildPriority;
  });

  const changedSeat = mergedSeats.find((seat: any) => seat._id == data._id);
  const lowerPriority =
    Number(
      newChangedSeats[data._id].pendingGuildPriority >
      Number(changedSeat.guildPriority),
    ) ||
    (newChangedSeats[data._id].pendingGuildPriority === '-' &&
      changedSeat.guildPriority !== '-') ||
    (newChangedSeats[data._id].status === 6 && changedSeat.guildPriority);

  for (const seat of lesserPrioritySubGuildSeats) {
    const originalSeat = seats.find((element: any) => element._id == seat._id);
    const newGuildPriority = lowerPriority
      ? Number(seat.guildPriority) - 1
      : Number(seat.guildPriority) + 1;
    newChangedSeats = {
      ...newChangedSeats,
      [seat._id]: {
        ...newChangedSeats[seat._id],
        guildPriority: newGuildPriority,
      },
    };
    const newStatus = newChangedSeats[seat._id].guildPriority > 10 ? 5 : 9;
    if (newStatus !== originalSeat.status) {
      newChangedSeats = {
        ...newChangedSeats,
        [seat._id]: {
          ...newChangedSeats[seat._id],
          status: newStatus,
        },
      };
    }
  }
  dispatch(applyGuildPriorities(changedSeat, newChangedSeats, blockEdit, user));
};

const applyGuildPriorities = (
  changedSeat: any,
  newChangedSeats: any,
  blockEdit: any,
  user: any,
) => async (dispatch: any) => {
  if (changedSeat.pendingGuildPriority) {
    newChangedSeats = {
      ...newChangedSeats,
      [changedSeat._id]: {
        ...newChangedSeats[changedSeat._id],
        guildPriority: changedSeat.pendingGuildPriority,
      },
    };
    delete newChangedSeats[changedSeat._id].pendingGuildPriority;
  }

  if (changedSeat.pendingSubGuild) {
    newChangedSeats[changedSeat._id].subGuild =
      newChangedSeats[changedSeat._id].pendingSubGuild;
    delete newChangedSeats[changedSeat._id].pendingSubGuild;
  }

  if (changedSeat.pendingSite) {
    newChangedSeats[changedSeat._id].site =
      newChangedSeats[changedSeat._id].pendingSite;
    delete newChangedSeats[changedSeat._id].pendingSite;
  }
  const newStatus = newChangedSeats[changedSeat._id].guildPriority > 10 ? 5 : 9;
  if (
    newChangedSeats[changedSeat._id].guildPriority &&
    newChangedSeats[changedSeat._id].guildPriority !== '-' &&
    newChangedSeats[changedSeat._id].status !== 6 &&
    newStatus !== changedSeat.status
  ) {
    newChangedSeats = {
      ...newChangedSeats,
      [changedSeat._id]: {
        ...newChangedSeats[changedSeat._id],
        status: newStatus,
      },
    };
  }
  if (
    newChangedSeats[changedSeat._id] &&
    newChangedSeats[changedSeat._id].status === 2
  ) {
    newChangedSeats = {
      ...newChangedSeats,
      [changedSeat._id]: {
        ...newChangedSeats[changedSeat._id],
        approvedDate: new Date().toISOString(),
      },
    };
  }
  dispatch(setChangedSeats(newChangedSeats));
  if (
    newChangedSeats[changedSeat._id] &&
    newChangedSeats[changedSeat._id].status &&
    rejectedReasonStatuses.includes(newChangedSeats[changedSeat._id].status)
  ) {
    dispatch(onRejectStatusChange(changedSeat, blockEdit, user));
  }
};

// const setMergeSeats =
//   (changedSeats: any) => async (dispatch: any, getState: any) => {
//     const { seats } = getState().seats;
//     const mergedSeats = getMergedSeats(seats, changedSeats);
//     dispatch({
//       type: SEATS.SET_MERGED_SEATS,
//       payload: mergedSeats,
//     });
//   };

export const downloadSpreadSheet = () => async (
  dispatch: any,
  getState: any,
) => {
  const { columnGroups } = getState().filter;
  const { amountOfSeatsByTeamAndStatus } = getState().seats;

  const filteredColumns = columnGroups
    .filter(
      (columnGroup: any) =>
        columnGroup.display === true && columnGroup.filtered === false,
    )
    .map((displayedGroups: any) => displayedGroups.columns)
    .flat()
    .filter((column: any) => column.filtered === false)
    .map((column: any) => column.source);

  const userViewSettings: any = getUserViewSettings(getState);
  userViewSettings.allColumns = allColumns;
  userViewSettings.filteredColumns = filteredColumns;
  userViewSettings.amountOfSeatsByTeamAndStatus = amountOfSeatsByTeamAndStatus;

  const { data } = await httpClient.post(
    `/seats/getSpreadsheet`,
    userViewSettings,
  );
  if (!data.CSV || data.CSV === '') {
    return;
  }
  getSeatsSpreadsheet(data.CSV, 'TEST');
};
