import s from './SaveViewModal.scss';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Text, Button, Modal, CloseButton, Input } from "@wix/design-system";
import {
  setShowSaveViewModal,
  addView,
} from '../../../../redux/actions/filterAction';

const SaveViewModal = (props: any) => {
  const { showSaveViewModal, setShowSaveViewModal, addView } = props;

  const [viewName, setViewName] = useState('');

  const onCancel = () => {
    setShowSaveViewModal(false);
    setViewName('');
  };

  const onSubmit = () => {
    addView(viewName);
    setShowSaveViewModal(false);
    setViewName('');
  };

  return (
    <Modal isOpen={showSaveViewModal}>
      <div className={s.main}>
        <div>
          <div className={s.top}>
            <CloseButton
              size="small"
              className={s.closeButton}
              onClick={onCancel}
            ></CloseButton>
          </div>
        </div>
        <Text className={s.title}>Name your view</Text>
        <div className={s.nameBox}>
          <Text className={s.text}>Name</Text>
          <Input
            className={s.input}
            placeholder="value"
            size="small"
            onChange={(e) => setViewName(e.target.value)}
          />
        </div>
        <div className={s.bottom}>
          <div className={s.left}></div>
          <div className={s.right}>
            <Button className={s.cancelButton} onClick={onCancel}>
              Cancel
            </Button>
            {viewName ? (
              <Button className={s.submitButton} onClick={onSubmit}>
                Save
              </Button>
            ) : (
              <Button className={s.disabledButton} disabled>
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  showSaveViewModal: state.filter.showSaveViewModal,
});

const mapDispatchToProps = (dispatch: any) => ({
  setShowSaveViewModal: (value: any) => dispatch(setShowSaveViewModal(value)),
  addView: (view: any) => dispatch(addView(view)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveViewModal);
