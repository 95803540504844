import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  setSeats,
  setSeatId,
  clearRef,
  setStatusReasons,
} from '../redux/actions/seatsActions';
import {
  clearInputRef,
  handleOpenEOEReplacement,
} from '../redux/actions/requestModalActions';
import { setDropdownData } from '../redux/actions/dropdownsActions';
import {
  applyFilters,
  setFiltersByPermissions,
} from '../redux/actions/filterAction';
import Table from '../Table/Table';
import SceletonTable from '../SkeletonTable/SceletonTable';
import Header from '../Header/Header';
import Filter from '../Filter/Filter';
import RequestModal from '../RequestModal/RequestModal';
import s from './Main.scss';
import MessageModal from '../MessageModal/MessgaeModal';
import ToolTip from '../ToolTip/ToolTip';
import JustificationModalMessage from '../Table/subComponents/Justification/JustificationModal/JustificationModalMessage';
import SaveViewModal from '../Filter/subComponents/SaveView/SaveViewModal/SaveViewModal';
import { Box, Text } from "@wix/design-system";
import TopHeader from '../TopHeader/TopHeader';
import UserSettingsModal from '../TopHeader/subComponents/UserSettings/UserSettingsModal/UserSettingsModal';
import { permissions, tableModes } from '../../../Client/config';
import EmployeesTable from '../EmployeesTable/EmployeesTable';
import EmployeesTableHeader from '../EmployeesTableHeader/EmployeesTableHeader';
import { setDelegates } from '../redux/actions/authActions';
import { initEmployeesTable } from '../redux/actions/employeesTableActions';
import { setHeadCount } from '../redux/actions/headCountActions';
import { filterPrePendingSeats } from '../redux/actions/tableActions';
import Footer from '../Footer/Footer';
import ContactUsModal from '../ContactUsModal/ContactUsModal';

const onUnload = (event: any) => {
  event.preventDefault();
  event.returnValue = '';
};

const Main = (props: any) => {
  const {
    setSeats,
    setSeatId,
    setDropdownData,
    applyFilters,
    setFiltersByPermissions,
    clearRef,
    clearInputRef,
    handleOpenEOEReplacement,
    changedSeats,
    tooltipX,
    tooltipY,
    tooltipText,
    tableMode,
    employeesData,
    user,
    targetHeadCount,
    setDelegates,
    setHeadCount,
    initEmployeesTable,
    showSeatsTableLoader,
    setStatusReasons,
    filterPrePendingSeats,
    dropdowns,
    amountOfSeatsByTeamAndStatus,
  } = props;
  const [showLoader, setShowLoader] = useState(true);

  // const message =
  //   'The Seats system is currently closed for 2021 adjustments. It will reopen on 04/02/2021. \n For any special request, contact position@wix.com or your HR/recruiting partner.';
  const params: any = useParams();
  if (Number.isInteger(params.seatId)) {
    setSeatId(params.seatId);
  }

  useEffect(() => {
    if (
      showLoader &&
      !showSeatsTableLoader &&
      Object.keys(dropdowns).length > 0
    ) {
      setShowLoader(false);
    }
  }, [showSeatsTableLoader, dropdowns]);

  useEffect(() => {
    if (
      permissions[user.permission] === 'Head Of' &&
      user.headOf &&
      user.headOf.type === 'Chairperson'
    ) {
      permissions[user.permission] = 'Budget Owner';
    }
    setDropdownData();
    const promises = [applyFilters(), setFiltersByPermissions()];
    if (user.permission === 3 || user.permission === 4) {
      promises.push(filterPrePendingSeats());
    } else {
      promises.push(setSeats());
    }
    setStatusReasons();
    if (
      user.permission === 3 ||
      user.permission === 4 ||
      user.permission === 5 ||
      user.permission === 7 ||
      user.permission === 8 ||
      user.permission === 9
    ) {
      promises.push(setHeadCount());
    }
    const res = Promise.all(promises).then(async () => {
      if (
        user.permission === 5 ||
        user.permission === 7 ||
        user.permission === 8 ||
        user.permission === 9
      ) {
        initEmployeesTable();
      }
      if (user.permission === 7) {
        setDelegates();
      }
      if (window.location.pathname.includes('/eoe')) {
        const queryParams: any = new URLSearchParams(window.location.search);
        handleOpenEOEReplacement(queryParams);
      }
    });
  }, []);

  useEffect(() => {
    if (
      amountOfSeatsByTeamAndStatus &&
      (user.permission === 3 || user.permission === 4)
    ) {
      initEmployeesTable();
    }
  }, [amountOfSeatsByTeamAndStatus]);

  useEffect(() => {
    if (Object.keys(changedSeats).length !== 0) {
      window.addEventListener('beforeunload', onUnload);
    } else {
      window.removeEventListener('beforeunload', onUnload);
    }
  }, [changedSeats]);

  const handlePageClick = (event: any) => {
    clearRef();
    clearInputRef();
  };

  return (
    <div>
      <div className={s.topHeaderContainer} onClick={handlePageClick}>
        <TopHeader showLoader={showLoader} />
      </div>
      <div className={s.mainContainer} onClick={handlePageClick}>
        <ContactUsModal />
        <Box width="100%" minHeight="30px" />
        <UserSettingsModal />
        {tableMode === tableModes.myEmployees && <EmployeesTableHeader />}
        {tableMode === tableModes.teamRequests && <Header />}
        {tableMode === tableModes.teamRequests && <Filter />}
        {showLoader ? (
          <SceletonTable />
        ) : tableMode === tableModes.teamRequests ? (
          <Table />
        ) : !employeesData || targetHeadCount.length === 0 ? (
          <SceletonTable />
        ) : (
          <EmployeesTable />
        )}
        <RequestModal />
        <MessageModal />
        <JustificationModalMessage />
        <SaveViewModal />
        <ToolTip text={tooltipText} x={tooltipX} y={tooltipY} />
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  tooltipY: state.tooltip.tooltipY,
  tooltipX: state.tooltip.tooltipX,
  tooltipText: state.tooltip.tooltipText,
  changedSeats: state.seats.changedSeats,
  amountOfSeatsByTeamAndStatus: state.seats.amountOfSeatsByTeamAndStatus,
  tableMode: state.table.tableMode,
  user: state.auth.user,
  employeesData: state.employeesTable.employeesData,
  targetHeadCount: state.headCount.targetHeadCount,
  showSeatsTableLoader: state.table.showSeatsTableLoader,
  dropdowns: state.dropdowns.dropdowns,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSeats: () => dispatch(setSeats()),
  setSeatId: (seatId: any) => dispatch(setSeatId(seatId)),
  setDropdownData: () => dispatch(setDropdownData()),
  applyFilters: () => dispatch(applyFilters()),
  setFiltersByPermissions: () => dispatch(setFiltersByPermissions()),
  clearRef: () => dispatch(clearRef()),
  clearInputRef: () => dispatch(clearInputRef()),
  handleOpenEOEReplacement: (queryParams: any) =>
    dispatch(handleOpenEOEReplacement(queryParams)),
  setDelegates: () => dispatch(setDelegates()),
  setHeadCount: () => dispatch(setHeadCount()),
  initEmployeesTable: () => dispatch(initEmployeesTable()),
  filterPrePendingSeats: () => dispatch(filterPrePendingSeats()),
  setStatusReasons: () => dispatch(setStatusReasons()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
