export const rejectedReasonStatuses = [3, 4, 7, 8, 11];

export const guildPriorityStatuses = [5, 9];

export const headOfEditStatuses: any = [1, 2, 5, 9, 10, 13];

export const headOfPermissions: any = [7, 8];

export const approvedStatuses: any = [2, 5, 9, 13];

export const hrApprovedStatuses = [2, 9, 13];

export const defaultStatusReasonMessage =
  'It was decided to disapprove your request due to the fact that you have similar open requests in the system.\n\nWe advise you to first recruit the open position, and then submit an additional request to be examined.';

export const defaultRejectedPrePendingMessage =
  'We are sorry to decline your request. If any other details are needed, please reach out to your business operations or HR.';

export const statusNames: any = {
  1: { label: 'Pending', skin: 'general' },
  2: { label: 'Approved', skin: 'neutralStandard' },
  3: { label: 'Rejected', skin: 'danger' },
  4: { label: 'Freeze', skin: 'neutral' },
  5: { label: 'Open', skin: 'standard' },
  6: { label: 'Hired', skin: 'neutralSuccess' },
  7: { label: 'Pause', skin: 'neutralLight' },
  8: { label: 'Canceled', skin: 'neutralDanger' },
  9: { label: 'Focus Position', skin: 'success' },
  10: { label: 'PreP', skin: 'warningLight' },
  11: { label: 'Rejected Prep', skin: 'urgent' },
  12: { label: 'Modified', skin: 'general' },
  13: { label: 'DIY', skin: 'premium' },
};

export const prePendingStatuses: any = {
  1: { label: 'Pending', skin: 'general' },
  10: { label: 'PreP', skin: 'warningLight' },
  11: { label: 'Rejected Prep', skin: 'urgent' },
};

export const availableStatusesForHr = {
  2: { label: 'Approved', skin: 'neutralStandard' },
  9: { label: 'Focus Position', skin: 'success' },
  13: { label: 'DIY', skin: 'premium' },
}

export const availableStatusesForChairman = {
  2: { label: 'Approved', skin: 'neutralStandard' },
  3: { label: 'Rejected', skin: 'danger' },
}

export const statusNamesWithError: any = {
  ...statusNames,
  error: { label: 'Error', skin: 'danger' },
};

export const hiddenSubGuilds = ['300000004457043'];

export const hiddenTeams = ['300000004416948'];

export const isGuildPriorityActive = false;

export const PRE_PENDING_GUILDS = ['300000026096438'];

export const PRE_PENDING_COMPANIES = ['300000026111036'];

export const PRE_PENDING_GUILDS_NAMES: any = [];

export const PRE_PENDING_COMPANIES_NAMES: any = [];

export const PRE_PENDING_PERMISSION = 6;

export const HR_PERMIAAION = 5;
export const PERMITED_PREMISSIONS_FOR_EDIT_PRE_PENDING_STATUS = [3, 4]
export const BUDGET_OWNER = 9;

export enum tableModes {
  teamRequests,
  myEmployees,
}

export const permissions: any = {
  1: 'Requester',
  2: 'Approver',
  3: 'Admin',
  4: 'Admin',
  5: 'HR',
  6: 'CC Approver',
  7: 'Head Of',
  8: 'Head Of Delegate',
  9: 'Budget Owner'
};

export const DEFAULT_SUCCESS_MESSAGE =
  'The changes were submitted successfully.';
export const PENDING_SUCCESS_MESSAGE = 'The changes were sent to approval.';
export const SETTINGS_SAVED_MESSAGE = 'Your settings were saved successfully.';
export const DEFAULT_FAILURE_MESSAGE = 'Something went wrong.';

export const monthsKey = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const seatsSegmentSize = 50;

export const mobileMsg =
  'Log into our desktop version for the best Seats experience';

export const SECOND_HEIRACHY_HC_TARGET_TEAMS = [
  'R&D',
  'Developer Platform',
  'Site Operations',
];

export const MAX_CHARS = 40;
