import React, { useState } from 'react';
import { connect } from 'react-redux';
import s from './FieldsFilter.scss';
import { Text, Checkbox, Button, TextButton, Collapse } from "@wix/design-system";
import {
  setFilters,
  setFieldsFilter,
  setCurrentView,
} from '../../../redux/actions/filterAction';
import ChevronRight from 'wix-ui-icons-common/ChevronRight';

const FieldsFilter = (props: any) => {
  const {
    columnGroups,
    setFieldsDropdown,
    setFilters,
    fieldsFilter,
    setFieldsFilter,
    setCurrentView,
  } = props;

  const filtered =
    fieldsFilter &&
    ((fieldsFilter.filteredGroups && fieldsFilter.filteredGroups.length) ||
      (fieldsFilter.filteredColumns && fieldsFilter.filteredColumns.length));

  const [savedFilters, setSavedFilters] = useState([
    {
      name: 'All Columns',
      active: !filtered,
      filteredGroups: [],
      filteredColumns: [],
    },
    // {
    //   name: 'Dors Filter',
    //   active: false,
    //   filteredGroups: ['Actions', 'Requester'],
    //   filteredColumns: ['Guild'],
    // },
  ]);

  // const [newFilterName, setNewFilterName] = useState<any>('');
  // const [filterNameToggle, setFilterNameToggle] = useState(false);
  const [filterColumnGroups, setFilterColumnGroups] = useState(
    JSON.parse(JSON.stringify(columnGroups)),
  );
  const [groupExpand, setGroupExpand] = useState<any>({
    'Position Details': false,
    Site: false,
    'Position Type': false,
    'Replacement Details': false,
    Priority: false,
    Q: false,
    'Recruitment Details': false,
    Status: false,
  });

  const toggleGroupCollapse = (title: any) => {
    const newView = { ...groupExpand };
    newView[title] = !newView[title];
    setGroupExpand(newView);
  };
  const toggleColumnFilter = (title: string, command?: any) => {
    let column = null;
    for (const group of filterColumnGroups) {
      if (group.formOnly) {
        continue;
      }
      for (const col of group.columns) {
        if (col.title === title) {
          column = col;
          break;
        }
      }
      if (column) {
        break;
      }
    }
    column.filtered = command ? command.set : !column.filtered;

    const newColumnGroups = [...filterColumnGroups];
    setFilterColumnGroups(newColumnGroups);
  };
  const toggleGroupFilter = (title: string, command?: any) => {
    const group = filterColumnGroups.find(
      (groupObj: any) => groupObj.title === title,
    );
    group.filtered = command ? command.set : !group.filtered;
    for (const col of group.columns) {
      toggleColumnFilter(col.title, { set: group.filtered });
    }
    const newColumnGroups = [...filterColumnGroups];
    setFilterColumnGroups(newColumnGroups);
  };
  const handleAll = (bool: Boolean) => {
    const newColumnGroups = [...filterColumnGroups];
    for (const group of filterColumnGroups) {
      group.filtered = bool;
      for (const col of group.columns) {
        col.filtered = bool;
      }
    }
    savedFilters[0].active = !bool;
    setFilterColumnGroups(newColumnGroups);
  };
  // const handleSavedFilterClick = (filter: any) => {
  //   handleAll(false);
  //   for (const groupName of filter.filteredGroups) {
  //     toggleGroupFilter(groupName, { set: true });
  //   }
  //   for (const colName of filter.filteredColumns) {
  //     toggleColumnFilter(colName, { set: true });
  //   }
  // };
  const SaveFilterChanges = () => {
    const filter: any = {
      filteredGroups: [],
      filteredColumns: [],
    };
    for (const group of filterColumnGroups) {
      let allFiltered = true;
      if (group.formOnly) {
        continue;
      }
      for (const column of group.columns) {
        if (column.filtered) {
          filter.filteredColumns.push(column.title);
        } else {
          allFiltered = false;
        }
      }
      group.filtered = allFiltered;
      if (allFiltered) {
        filter.filteredGroups.push(group.title);
      }
    }
    setFieldsFilter(filter);
    setFilters(filterColumnGroups);
    setCurrentView(null);
    setFieldsDropdown(false);
  };

  return (
    <div className={s.main} onClick={(event) => event.stopPropagation()}>
      <div className={s.header}>
        <Text className={s.headerText}>Show fields</Text>
        <TextButton
          className={s.clearAllButton}
          onClick={() => handleAll(true)}
        >
          Clear All
        </TextButton>
      </div>
      <div className={s.filtersContainer}>
        <div className={s.savedFilters}>
          {savedFilters.map((filter: any, i: any) => {
            return (
              <div key={i} className={s.savedFilter}>
                <Checkbox
                  checked={filter.active}
                  className={
                    filter.active ? s.groupCheckboxChecked : s.groupCheckbox
                  }
                  onChange={() => handleAll(filter.active)}
                ></Checkbox>
                <Text className={s.groupText}>{filter.name}</Text>
              </div>
            );
          })}
        </div>
        {filterColumnGroups.map((group: any, i: any) => {
          if (group.formOnly) {
            return null;
          }
          return (
            <div key={i} className={s.groupContainer}>
              <div
                className={s.groupFilter}
                onClick={() => {
                  toggleGroupCollapse(group.title);
                }}
              >
                <Checkbox
                  checked={!group.filtered}
                  className={
                    !group.filtered ? s.groupCheckboxChecked : s.groupCheckbox
                  }
                  onChange={() => {
                    toggleGroupFilter(group.title);
                  }}
                ></Checkbox>
                {group.columns.length > 1 && (
                  <ChevronRight size="19px" viewBox="5 0 24 24" />
                )}
                <Text className={s.groupText} key={i}>
                  {group.title}
                </Text>
              </div>
              {group.columns.length > 1 ? (
                <div className={s.collapseList}>
                  <Collapse open={groupExpand[group.title]}>
                    {group.columns.map((column: any, i: any) => {
                      return (
                        <div key={i} className={s.columnFilter}>
                          <Checkbox
                            checked={!column.filtered}
                            className={
                              !column.filtered
                                ? s.groupCheckboxChecked
                                : s.groupCheckbox
                            }
                            onChange={() => {
                              toggleColumnFilter(column.title);
                            }}
                          ></Checkbox>
                          <Text className={s.groupText} key={i}>
                            {column.title}
                          </Text>
                        </div>
                      );
                    })}
                  </Collapse>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className={s.footer}>
        <div className={s.footerRightButtons}>
          <Button
            className={s.saveFilterButton}
            onClick={() => setFieldsDropdown(false)}
          >
            Cancel
          </Button>
          <Button className={s.applyButton} onClick={() => SaveFilterChanges()}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  columnGroups: state.filter.columnGroups,
  fieldsFilter: state.filter.fieldsFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
  setFilters: (columnGroups: any) => dispatch(setFilters(columnGroups)),
  setFieldsFilter: (fieldsFilter: any) =>
    dispatch(setFieldsFilter(fieldsFilter)),
  setCurrentView: (value: any) => dispatch(setCurrentView(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldsFilter);
