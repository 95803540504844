import React from 'react';
import { connect } from 'react-redux';
import SeatsIcon from './subComponents/seatsIcon';
import UserSettings from './subComponents/UserSettings/UserSettings';
import { setTableMode } from '../redux/actions/tableActions';
import { tableModes, permissions } from '../../../Client/config';
import { Text } from "@wix/design-system";
import s from './TopHeader.scss';

const TopHeader = (props: any) => {
  const { showLoader, setTableMode, tableMode, user } = props;

  return (
    <div className={s.headerContainer}>
      <section className={s.headerSection}>
        <section className={s.headerSubSection}>
          <SeatsIcon />
          {(permissions[user.permission] === 'Admin' ||
            permissions[user.permission] === 'HR' ||
            permissions[user.permission] === 'Head Of' ||
            permissions[user.permission] === 'Budget Owner' ||
            permissions[user.permission] === 'Head Of Delegate') && (
            <div className={s.toggleTablesContainer}>
              <div
                className={`${s.tableOption} ${
                  tableMode === tableModes.teamRequests
                    ? s.activeTableOption
                    : ''
                }`}
                onClick={() => setTableMode(tableModes.teamRequests)}
              >
                <Text
                  className={`${s.tableOptionText} ${
                    tableMode !== tableModes.teamRequests
                      ? s.inactiveTableOptionText
                      : s.activeTableOptionText
                  }`}
                >
                  Requests
                </Text>
              </div>
              <div
                className={`${s.tableOption} ${
                  tableMode === tableModes.myEmployees
                    ? s.activeTableOption
                    : ''
                }`}
                onClick={() => setTableMode(tableModes.myEmployees)}
              >
                <Text
                  className={`${s.tableOptionText} ${
                    tableMode !== tableModes.myEmployees
                      ? s.inactiveTableOptionText
                      : s.activeTableOptionText
                  }`}
                >
                  My HC Dashboard
                </Text>
              </div>
            </div>
          )}
        </section>
        <section className={s.headerSubSection}>
          {!showLoader && <UserSettings />}
        </section>
      </section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  tableMode: state.table.tableMode,
});

const mapDispatchToProps = (dispatch: any) => ({
  setTableMode: (mode: tableModes) => dispatch(setTableMode(mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
