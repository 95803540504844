import { EMPLOYEES_TABLE } from '../actions/constants';
import { columns } from './employeesTableColumns/columns';

const initState: any = {
  columnGroups: columns,
  employeesData: null,
  employeesSearchResult: null,
  searchValue: null,
  filteredHeadCountAmount: null,
};

export default (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case EMPLOYEES_TABLE.COLUMN_GROUPS:
      return { ...state, columnGroups: payload };
    case EMPLOYEES_TABLE.EMPLOYEES_DATA:
      return { ...state, employeesData: payload };
    case EMPLOYEES_TABLE.EMPLOYEES_SEARCH_RESULT:
      return { ...state, employeesSearchResult: payload };
    case EMPLOYEES_TABLE.SEARCH_VALUE:
      return { ...state, searchValue: payload };
    case EMPLOYEES_TABLE.FILTERED_HEAD_COUNT_AMOUNT:
      return { ...state, filteredHeadCountAmount: payload };
    default:
      return state;
  }
};
