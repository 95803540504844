import React from 'react';
import s from './SkeletonTable.scss';

const SkeletonHeader = (props: any) => {
  return (
    <div className={s.skeletonHeader}>
      <div className={s.skeletonHeaderGroupsRow}>
        <div className={s.skeleton} style={{ width: '100px' }}></div>
        <div className={s.skeleton} style={{ width: '200px' }}></div>
        <div className={s.skeleton} style={{ width: '100px' }}></div>
        <div className={s.skeleton} style={{ width: '200px' }}></div>
        <div className={s.skeleton} style={{ width: '200px' }}></div>
        <div className={s.skeleton} style={{ width: '100px' }}></div>
        <div className={s.skeleton} style={{ width: '100px' }}></div>
        <div className={s.skeleton} style={{ width: '100px' }}></div>
      </div>
      <div className={s.skeletonHeaderRow}>
        {/* <div className={s.skeletonHeaderRowSmall}></div> */}
        <div className={s.skeletonHeaderRowLarge}></div>
      </div>
    </div>
  );
};

const SkeletonBody = (props: any) => {
  return (
    <div className={s.skeletonBody}>
      {Array(11)
        .fill(1)
        .map((value, index) => (
          <div key={index} className={s.skeletonRow}></div>
        ))}
    </div>
  );
};

export default function SceletonTable() {
  return (
    <div className={s.skeletonTable}>
      <SkeletonHeader />
      <SkeletonBody />
    </div>
  );
}
