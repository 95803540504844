import { Button, Card, Text } from "@wix/design-system";
import React, { useState, useEffect } from 'react';
import { getSeat } from '../../../Server/services/getSeat';
import { approveSeat } from '../../../Server/services/approveSeat';

export default function ApproveSeat() {
  const [text, setText] = useState('please wait...');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [seat, setSeat] = useState();
  const [showButton, setShowButton] = useState(false);

  const enableOperation = (enable, msg) => {
    if (enable) {
      setButtonDisabled(false);
      setShowButton(true);
    } else {
      setButtonDisabled(true);
      setShowButton(false);
    }
    setText(msg);
  };

  const handleClick = async () => {
    try {
      enableOperation(false, 'Please wait...');
      if (seat) {
        const res = await approveSeat(seat);
        if (res.data?.success) {
          enableOperation(false, 'Approved success');
        } else {
          setText('Operation failed');
        }
      }
    } catch (error) {
      console.error('approveSeat handleClick failed -origin error-' + error);
      setText('Operation failed');
    }
  };

  useEffect(() => {
    getSeat(getSeatID()).then((res) => {
      if (res) {
        if (res.status !== 2) {
          enableOperation(true, 'Click Enter to approve seat');
          setSeat(res);
        } else {
          enableOperation(false, 'Seat already approved');
        }
      } else {
        enableOperation(false, 'Seat does not exist');
      }
    });
  }, []);

  return (
    <Card flexDirection="column">
      <Text>{text}</Text>
      <div>
        {showButton ? (
          <Button size="medium" disabled={buttonDisabled} onClick={handleClick}>
            Enter
          </Button>
        ) : (
          <div></div>
        )}
      </div>
    </Card>
  );
}

function getSeatID() {
  const pathArray = window.location.pathname.split('/');
  return pathArray[pathArray.length - 1];
}
