import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './Replacement.scss';
import { Visible } from 'wix-ui-icons-common';
import { getReplacement } from '../../../redux/actions/seatsActions';
import { getPendingChangeTooltip } from '../../../../../Client/utils';
import { showTooltip } from '../../../redux/actions/tooltipActions';

const Replacement = (props: any) => {
  const { data, value, getReplacement, showTooltip } = props;
  const [display, setDisplay] = useState(false);
  const [name, setName] = useState<any>('Loading');
  const [pendingName, setPendingName] = useState<any>(null);

  useEffect(() => {
    setName('Loading');
  }, [data]);
  const handleDisplayReplacement = async () => {
    if (name === 'Loading') {
      setDisplay(true);
      const replacementNameResponse: any = await getReplacement(data._id);
      if (replacementNameResponse.pendingReplacementName) {
        setPendingName(replacementNameResponse.pendingReplacementName);
      }
      setName(replacementNameResponse.replacementName);
    } else {
      setDisplay(true);
    }
  };

  const renderTooltip = (e: any) => {
    if (!pendingName) {
      return;
    }
    const display = getPendingChangeTooltip(
      pendingName,
      name,
      'replacementName',
    );
    const rect = e.target.getBoundingClientRect();
    showTooltip(display, rect.x, rect.y - 40);
  };

  return value ? (
    <div
      className={s.main}
      onMouseEnter={handleDisplayReplacement}
      onMouseLeave={() => setDisplay(false)}
    >
      <Visible size="18px" className={display ? s.visible : s.nonVisible} />
      {display ? (
        <div
          className={pendingName ? s.pendingChange : ''}
          onMouseEnter={renderTooltip}
        >
          {pendingName || name}
        </div>
      ) : (
        <div className={s.stars}>********</div>
      )}
    </div>
  ) : null;
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  getReplacement: (seatId: number) => dispatch(getReplacement(seatId)),
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Replacement);
