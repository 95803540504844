import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './Status.scss';
import {
  setRef,
  setChangedSeats,
  onRejectStatusChange,
  onGuildPriorityChange,
} from '../../../redux/actions/seatsActions';
import { v4 as uuidv4 } from 'uuid';
import { Badge } from "@wix/design-system";
import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
import { CircleSmallFilled } from 'wix-ui-icons-common';
import {
  rejectedReasonStatuses,
  isGuildPriorityActive,
  statusNamesWithError as statusNames,
  prePendingStatuses,
  PRE_PENDING_PERMISSION,
  HR_PERMIAAION,
  availableStatusesForHr,
  BUDGET_OWNER,
  availableStatusesForChairman,
} from '../../../../../Client/config';
import { showTooltip } from '../../../redux/actions/tooltipActions';
import {
  getPendingChangeTooltip,
  isPrePending,
} from '../../../../../Client/utils';

const Status = (props: any) => {
  const {
    value,
    data,
    col,
    blockEdit,
    changedSeats,
    setChangedSeats,
    onGuildPriorityChange,
    currentCellRef,
    setRef,
    onRejectStatusChange,
    user,
    showTooltip,
  } = props;
  const [myId, setMyId] = useState(uuidv4());
  const [focus, setFocus] = useState(false);
  const [edited, setEdited] = useState(
    changedSeats[data._id] && changedSeats[data._id][col.source],
  );
  const [pendingChange, setPendingChange] = useState<any>(null);

  useEffect(() => {
    setPendingChange(data.pendingChanges ? 12 : null);
  }, [data, changedSeats]);

  useEffect(() => {
    setEdited(changedSeats[data._id] && changedSeats[data._id][col.source]);
  }, [changedSeats]);

  const handleCellClick = () => {
    setFocus(true);
    setRef(myId);
  };
  const handleOptionSelect = (option: any) => {
    if (
      isGuildPriorityActive &&
      (data.guildPriority ||
        (changedSeats &&
          changedSeats[data._id] &&
          changedSeats[data._id].guildPriority))
    ) {
      let newChangedSeats = {
        ...changedSeats,
        [data._id]: {
          ...changedSeats[data._id],
          pendingStatus: option.value,
        },
      };
      if (option.value !== 6) {
        newChangedSeats = {
          ...newChangedSeats,
          [data._id]: {
            ...newChangedSeats[data._id],
            pendingGuildPriority: '-',
          },
        };
      }
      onGuildPriorityChange(data, newChangedSeats, blockEdit, user);
      return;
    }
    const previousStatus =
      changedSeats && changedSeats[data._id] && changedSeats[data._id].status
        ? changedSeats[data._id].status
        : data.status;
    // const isPrePendingApproved =
    //   changedSeats &&
    //   changedSeats[data._id] &&
    //   changedSeats[data._id].isPrePendingApproved
    //     ? changedSeats[data._id].isPrePendingApproved
    //     : data.isPrePendingApproved;
    let newChangedSeats = {
      ...changedSeats,
      [data._id]: { ...changedSeats[data._id], [col.source]: option.value },
    };
    if (
      option.value !== 10 &&
      option.value !== 11 &&
      (previousStatus === 10 || previousStatus === 11)
    ) {
      newChangedSeats = {
        ...newChangedSeats,
        [data._id]: {
          ...newChangedSeats[data._id],
          isPrePendingApproved: true,
        },
      };
    }
    if (option.value === 10 || option.value === 11) {
      newChangedSeats = {
        ...newChangedSeats,
        [data._id]: {
          ...newChangedSeats[data._id],
          isPrePendingApproved: false,
        },
      };
    }
    if (option.value === 2) {
      if (user.permission !== HR_PERMIAAION) {
        newChangedSeats = {
          ...newChangedSeats,
          [data._id]: {
            ...newChangedSeats[data._id],
            approvedDate: new Date().toISOString(),
          },
        };
      } else {
        newChangedSeats = {
          ...newChangedSeats,
          [data._id]: {
            ...newChangedSeats[data._id],
          },
        };
      }
    }
    setChangedSeats(newChangedSeats);
    setEdited(option.value);
    setFocus(false);
    if (rejectedReasonStatuses.includes(option.value)) {
      onRejectStatusChange(data, blockEdit, user);
    }
  };
  const getStatusOptions = () => {
    let validStatuses = { ...statusNames };
    if (user.permission === PRE_PENDING_PERMISSION) {
      validStatuses = { ...prePendingStatuses };
    }
    if (user.permission === HR_PERMIAAION) {
      validStatuses = { ...availableStatusesForHr };
    }

    if (user.permission === BUDGET_OWNER) {
      validStatuses = { ...availableStatusesForChairman };
    }
    if (!isPrePending(data)) {
      delete validStatuses[10];
      delete validStatuses[11];
    }
    delete validStatuses[12];
    const errorStatus = 'error';
    delete validStatuses[errorStatus];
    const options = Object.entries(validStatuses).map((statusName: any) => {
      const [key, value] = statusName;
      return {
        label: (
          <Badge size="small" skin={value.skin} onClick={() => {}}>
            {value.label}
          </Badge>
        ),
        value: parseInt(key, 10),
      };
    });
    return options;
  };

  const renderTooltip = (e: any) => {
    if (!pendingChange) {
      return;
    }
    const display = getPendingChangeTooltip(
      statusNames[pendingChange].label,
      statusNames[value].label,
      col.source,
    );
    const rect = e.target.getBoundingClientRect();
    showTooltip(display, rect.x, rect.y - 40);
  };

  return (
    <div>
      {focus && currentCellRef === myId && !blockEdit && !data.delegated ? (
        <div style={{ position: 'relative' }}>
          <CustomDropdown
            title={`Select ${col.title}`}
            options={getStatusOptions()}
            onSelect={handleOptionSelect}
            style={{ position: 'absolute' }}
          />
        </div>
      ) : (
        <div className={s.nonFocus} onMouseEnter={renderTooltip}>
          {edited && <CircleSmallFilled className={s.edited} size="18px" />}
          <Badge
            size="small"
            skin={statusNames[edited || pendingChange || value]?.skin}
            onClick={handleCellClick}
          >
            {statusNames[edited || pendingChange || value].label}
          </Badge>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentCellRef: state.seats.currentCellRef,
  changedSeats: state.seats.changedSeats,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setChangedSeats: (changedSeats: any) =>
    dispatch(setChangedSeats(changedSeats)),
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  onGuildPriorityChange: (
    data: any,
    newChangedSeats: any,
    blockEdit: any,
    user: any,
  ) => dispatch(onGuildPriorityChange(data, newChangedSeats, blockEdit, user)),
  onRejectStatusChange: (data: any, blockEdit: any, user: any) =>
    dispatch(onRejectStatusChange(data, blockEdit, user)),
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
