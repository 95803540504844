import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './MessageModal.scss';
import { Modal, Text, Button, CloseButton, NumberInput } from "@wix/design-system";
import { displayModalMessage } from '../redux/actions/seatsActions';

const MessageModal = (props: any) => {
  const { modalMessage, displayModalMessage } = props;
  const {
    title,
    message,
    type,
    buttonText,
    onSubmit,
    cancelButtonText,
    modalWidth,
  } = modalMessage || {};

  const [amount, setAmount] = useState<any>(1);

  useEffect(() => {
    setAmount(message?.initValue);
  }, [message?.initValue]);

  const submitClicked = () => {
    if (message?.type === 'numberInput') {
      onSubmit(amount);
    } else {
      onSubmit();
    }
    displayModalMessage(null);
  };
  return (
    <Modal
      isOpen={modalMessage || false}
      onRequestClose={() => displayModalMessage(null)}
    >
      <div style={{ width: `${modalWidth}px` }} className={s.main}>
        <div>
          <div className={s.top}>
            <div></div>
            <CloseButton
              size="small"
              className={s.closeButton}
              onClick={() => displayModalMessage(null)}
            ></CloseButton>
          </div>
          <Text className={s.title}>{title}</Text>
        </div>
        {message?.type === 'numberInput' ? (
          <div className={s.number}>
            <NumberInput
              value={amount}
              min={message.minValue}
              max={message.maxValue}
              strict={true}
              onChange={(e) => setAmount(e)}
            ></NumberInput>
          </div>
        ) : (
          <Text className={s.message}>{message}</Text>
        )}
        <div className={s.bottom}>
          <div className={s.left}></div>
          <div className={s.right}>
            <Button
              className={s.cancelButton}
              onClick={() => displayModalMessage(null)}
            >
              {cancelButtonText || 'Cancel'}
            </Button>
            <Button
              className={type === 'delete' ? s.deleteButton : s.submitButton}
              onClick={submitClicked}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalMessage: state.seats.modalMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
  displayModalMessage: (message: any) => dispatch(displayModalMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
