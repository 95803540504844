import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './Table.scss';
import {
  setSearchResults,
  setPagination,
  sortCol,
  applyDataFilters,
  fetchMoreSeats,
  setRefreshSeatsTable,
} from '../redux/actions/seatsActions';
import { Text } from "@wix/design-system";
import { setCurrentView } from '../redux/actions/filterAction';
import TableRow from './subComponents/TableRow/TableRow';
import ColumnGroup from './subComponents/ColumnGroup/ColumnGroup';
import {
  ArrowDownSmall,
  ArrowUpSmall,
  SortDescendingSmall,
  ContentFilterSmall,
  InfoCircleSmall,
} from 'wix-ui-icons-common';
import { showTooltip, hideTooltip } from '../redux/actions/tooltipActions';

const Table = (props: any) => {
  const {
    searchResults,
    pagination,
    setPagination,
    pageSize,
    columns,
    columnGroups,
    sortCol,
    dataFilters,
    applyDataFilters,
    sort,
    filteringColumns,
    setCurrentView,
    showTooltip,
    hideTooltip,
    fetchMoreSeats,
    refreshSeatsTable,
    setRefreshSeatsTable,
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [sortFunc, setSortFunc] = useState('Asc');
  const [columnsToFilter, setColumnsToFilter] = useState<any>();

  useEffect(() => {
    const tableContainer = document.getElementById('table-container');
    if (!tableContainer) {
      return;
    }
    tableContainer.addEventListener('scroll', handleScroll);
    return () => tableContainer.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) {
      return;
    }
    fetchMoreListItems();
  }, [isFetching]);

  useEffect(() => {
    const tableContainer = document.getElementById('table-container');
    if (tableContainer && refreshSeatsTable) {
      tableContainer.scrollTop = 0;
      setRefreshSeatsTable(false);
    }
  }, [refreshSeatsTable]);

  useEffect(() => {
    applyDataFilters();
  }, [dataFilters]);

  useEffect(() => {
    setColumnsToFilter(filteringColumns);
  }, [filteringColumns]);

  const handleScroll = () => {
    const tableContainer = document.getElementById('table-container');
    if (!tableContainer) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = tableContainer;
    if (scrollHeight - scrollTop > clientHeight + clientHeight) {
      return;
    }
    setIsFetching(true);
  };

  const fetchMoreListItems = () => {
    const sum: number = Number(pagination) + Number(pageSize);
    fetchMoreSeats();
    setPagination(sum);
    setIsFetching(false);
  };

  const renderColumnsGroups = () => {
    const groups = columnGroups.map((group: any, i: any) => {
      if (group.filtered || group.formOnly) {
        return null;
      }
      let size: number = 0;
      let length = 0;
      for (const column of group.columns) {
        if (!column.filtered) {
          size += column.size;
          length++;
        }
      }
      size += length - 1;
      return <ColumnGroup key={i} size={size} group={group} />;
    });
    return <div className={s.columnGroupsContainer}>{groups}</div>;
  };

  const renderTooltip = (event: any, tooltip: any) => {
    const rect = event.target.getBoundingClientRect();
    showTooltip(tooltip, rect.x, rect.y - 40);
  };

  const renderHeader = () => {
    const headers = [];
    for (const group of columnGroups) {
      if (group.filtered || group.formOnly) {
        continue;
      }
      if (!group.display) {
        headers.push(<th key={group.title} className={s.headerDivider}></th>);
        continue;
      }
      for (const col of group.columns) {
        if (!col.filtered) {
          headers.push(
            <th
              key={`${group.title}${col.title}`}
              style={{ minWidth: col.size, maxWidth: col.size }}
              className={`${s.headerCell} `}
              onClick={() => {
                setCurrentView(null);
                sortCol(col, sortFunc);
                setSortFunc(sortFunc === 'Desc' ? 'Asc' : 'Desc');
              }}
            >
              <div className={s.tableHeaderContainer}>
                {sort && sort.column.source === col.source ? (
                  sort.type === 'Asc' ? (
                    <ArrowUpSmall className={s.arrowIconHidden} />
                  ) : (
                    <ArrowDownSmall className={s.arrowIconHidden} />
                  )
                ) : (
                  <ArrowDownSmall className={s.arrowIconHoverHidden} />
                )}
                <Text className={s.tableHeader}>
                  {col.hideTitle
                    ? col.title !== 'Actions' && <SortDescendingSmall />
                    : col.title}
                  {col.tooltip ? (
                    <InfoCircleSmall
                      size="18px"
                      className={s.infoIcon}
                      onMouseEnter={(event: any) =>
                        renderTooltip(event, col.tooltip)
                      }
                      onMouseLeave={hideTooltip}
                    />
                  ) : (
                    ''
                  )}
                </Text>
                {columnsToFilter && columnsToFilter.includes(col.title) && (
                  <ContentFilterSmall />
                )}
                {sort && sort.column.source === col.source ? (
                  sort.type === 'Asc' ? (
                    <ArrowUpSmall className={s.arrowIcon} />
                  ) : (
                    <ArrowDownSmall className={s.arrowIcon} />
                  )
                ) : (
                  <ArrowDownSmall className={s.arrowIconHover} />
                )}
              </div>
            </th>,
          );
        }
      }
    }
    return <tr>{headers}</tr>;
  };

  const paginator = (
    searchResults: any,
    pageSize: any,
    paginationStart: any,
  ) => {
    const size: number = pageSize;
    const pagination: number = paginationStart;
    return searchResults.slice(0, pagination + size);
  };

  const renderTable = () => {
    return paginator(searchResults, pageSize, pagination).map(
      (rowData: any, index: number) => {
        return (
          <TableRow
            data={rowData}
            key={index}
            index={index}
            columns={columns}
          />
        );
      },
    );
  };

  return (
    <div id="table-container" className={s.tableContainer}>
      <div className={s.columnGroupsContainerSticky} style={{ width: 2082 }}>
        {renderColumnsGroups()}
      </div>
      <div>
        <table id="table" className={s.table}>
          <thead>{renderHeader()}</thead>
          <tbody>{renderTable()}</tbody>
        </table>
      </div>
    </div>
  );
};

const getColumns = (state: any) => {
  const columnGroups = state.filter.columnGroups;
  const columns = [];

  for (const group of columnGroups) {
    if (group.formOnly) {
      continue;
    }
    const mainIndex = group.columns.findIndex(
      (col: any) => col.filtered !== true,
    );
    if (mainIndex > -1) {
      group.columns[mainIndex].main = true;
    }
    columns.push(...group.columns);
  }

  return columns;
};

const mapStateToProps = (state: any) => ({
  seats: state.seats.seats,
  searchResults: state.seats.searchResults,
  pagination: state.seats.pagination,
  pageSize: state.seats.pageSize,
  columns: getColumns(state),
  columnGroups: state.filter.columnGroups,
  dataFilters: state.filter.dataFilters,
  sort: state.seats.sort,
  filteringColumns: state.seats.filteringColumns,
  refreshSeatsTable: state.table.refreshSeatsTable,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSearchResults: (searchResults: any) =>
    dispatch(setSearchResults(searchResults)),
  setPagination: (pagination: number) => dispatch(setPagination(pagination)),
  sortCol: (col: any, sort: any) => dispatch(sortCol(col, sort)),
  applyDataFilters: () => dispatch(applyDataFilters()),
  setCurrentView: (value: any) => dispatch(setCurrentView(value)),
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
  hideTooltip: () => dispatch(hideTooltip()),
  fetchMoreSeats: () => dispatch(fetchMoreSeats()),
  setRefreshSeatsTable: (isRefresh: any) =>
    dispatch(setRefreshSeatsTable(isRefresh)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
