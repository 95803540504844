import { DROPDOWNS } from '../actions/constants';

const initState: any = {
  dropdowns: {},
  dropdownsFilterOptions: null,
  avatarsHash: null,
};

export default (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case DROPDOWNS.SET_DROPDOWNS:
      return { ...state, dropdowns: payload };
    case DROPDOWNS.SET_DROPDOWNS_FILTER_OPTIONS:
      return { ...state, dropdownsFilterOptions: payload };
    case DROPDOWNS.SET_GUILD_PRIORITY_DROPDOWN_DATA:
      return { ...state, guildPriorities: payload };
    case DROPDOWNS.SET_AVATARS:
      return { ...state, avatarsHash: payload };
    default:
      return state;
  }
};
