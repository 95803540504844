import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  More,
  DuplicateSmall,
  DeleteSmall,
  LinkSmall,
  DocumentSmall,
} from 'wix-ui-icons-common';
import { v4 as uuidv4 } from 'uuid';
import {
  openModal,
  setRequest,
  setMode,
} from '../../../redux/actions/requestModalActions';
import {
  setRef,
  deleteSeat,
  setSeatsLocal,
  setMessage,
  displayModalMessage,
} from '../../../redux/actions/seatsActions';
import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
import s from './Actions.scss';
import { isPrePending } from '../../../../../Client/utils';
import {
  headOfEditStatuses,
  approvedStatuses,
  headOfPermissions,
  prePendingStatuses,
} from '../../../../../Client/config';

const Actions = (props: any) => {
  const {
    currentCellRef,
    openModal,
    setRequest,
    setRef,
    setMode,
    data,
    deleteSeat,
    seats,
    setSeatsLocal,
    setMessage,
    displayModalMessage,
    user,
    statusJustifications,
  } = props;

  const [myId, setMyId] = useState(uuidv4());
  const [focus, setFocus] = useState(false);
  const [toggleUp, setToggleUp] = useState(false);

  const blockEdit =
    data.delegated ||
    (user.permission === 1 &&
      ((isPrePending(data) && data.status !== 10) ||
        (data.status !== 1 && !isPrePending(data)))) ||
    (user.permission === 3 && !data.mySeat) ||
    (user.permission === 5 &&
      (!data.mySeat ||
        (isPrePending(data) && data.status !== 10) ||
        (data.status !== 1 && !isPrePending(data))) &&
      !approvedStatuses.includes(data.status)) ||
    (user.permission === 6 &&
      (!Object.keys(prePendingStatuses).includes(data.status.toString()) ||
        (!isPrePending(data) && data.status !== 1))) ||
    (headOfPermissions.includes(user.permission) &&
      !headOfEditStatuses.includes(data.status));

  const actions = blockEdit
    ? [
        {
          value: 'View Details',
          icon: <DocumentSmall className={s.optionIcon} />,
        },
        {
          value: 'Copy To Clipboard',
          icon: <LinkSmall className={s.optionIcon} />,
        },
        {
          value: 'Duplicate',
          icon: <DuplicateSmall className={s.optionIcon} />,
        },
      ]
    : [
        // { value: 'Submit Request', icon: <Check /> },
        // { value: 'Share to others', icon: <Share /> },
        {
          value: 'View Details',
          icon: <DocumentSmall className={s.optionIcon} />,
        },
        {
          value: 'Copy To Clipboard',
          icon: <LinkSmall className={s.optionIcon} />,
        },
        {
          value: 'Duplicate',
          icon: <DuplicateSmall className={s.optionIcon} />,
        },
        {
          value: 'Delete Request',
          icon: <DeleteSmall className={s.optionIcon} />,
        },
      ];

  const shouldToggleUp = (event: any) => {
    if (!event) {
      return false;
    }
    const { y } = event.target.getBoundingClientRect();
    const dropdownHeight = 300;

    return Number(y || 0) + dropdownHeight > window.innerHeight;
  };

  const escFunction = (event: any) => {
    if (event.keyCode === 27) {
      closeDropdown();
    }
  };

  const closeDropdown = () => {
    setFocus(false);
    document.removeEventListener('keydown', escFunction, false);
  };

  const handleCellClick = (event: any) => {
    setToggleUp(shouldToggleUp(event));
    // if (focus) {
    //   return closeDropdown();
    // }
    document.addEventListener('keydown', escFunction, false);
    setRef(myId);
    setFocus(true);
  };

  const handleOptionSelect = async (option: any) => {
    const { value } = option;

    switch (value) {
      case 'Submit Request': {
        submitClicked();
        break;
      }
      case 'Share to others': {
        shareClicked();
        break;
      }
      case 'Copy To Clipboard': {
        sendToApproversClicked();
        break;
      }
      case 'View Details': {
        viewDetailsClicked();
        break;
      }
      case 'Duplicate': {
        duplicateClicked();
        break;
      }
      case 'Delete Request': {
        displayModalMessage({
          title: 'Delete Seat',
          message: `Are you sure you want to delete seat number ${data._id}`,
          type: 'delete',
          buttonText: 'Delete',
          onSubmit: deleteClicked,
        });
        break;
      }
      default: {
        console.log('unknow option clicked.');
      }
    }

    closeDropdown();
  };

  const submitClicked = () => {
    console.log('submiting reuquest');
  };

  const sendToApproversClicked = () => {
    const baseUrl = getBaseUrl();
    const res = navigator.clipboard.writeText(`${baseUrl}${data._id}`);
    setMessage(
      {
        type: 'success',
        text: `The seat ${data._id} was copied to clipboard.`,
      },
      3000,
    );
  };

  const shareClicked = () => {
    console.log('sharing to others');
  };

  const viewDetailsClicked = () => {
    data.statusReason =
      data.statusReason || statusJustifications[data._id]?.reason;
    setRequest(data);
    setMode(blockEdit ? 'View' : 'Edit');
    openModal();
  };

  const duplicateClicked = () => {
    data.statusReason =
      data.statusReason || statusJustifications[data._id]?.reason;
    setRequest(data);
    setMode('New');
    openModal();
  };

  const deleteClicked = async () => {
    closeDropdown();
    try {
      const res = await deleteSeat(data._id);
      if (res?.data?.success) {
        const newSeats: any = seats.filter(
          (seat: any) => seat._id !== data._id,
        );
        // setting the new wixpriorities based on the change
        if (res.data.wixPriorityChanges) {
          for (const seat of newSeats) {
            seat.wixPriority =
              res.data.wixPriorityChanges[seat._id] || seat.wixPriority;
          }
          // refreshGuildPriority(seats);
        }
        setSeatsLocal(newSeats);
        setMessage(
          {
            type: 'success',
            text: `The seat ${data._id} was deleted.`,
          },
          2000,
        );
        return;
      }
      setMessage({ type: 'fail', text: 'Something went wrong' }, 2000);
    } catch (error: any) {
      setMessage({ type: 'fail', text: 'Something went wrong' }, 2000);
    }
  };

  const getBaseUrl = () => {
    const currentUrl = window.location.href;
    const charIndex = currentUrl.lastIndexOf('/');
    if (charIndex === currentUrl.length - 1) {
      return currentUrl;
    }
    if (currentUrl[charIndex + 1] >= '0' && currentUrl[charIndex + 1] <= '9') {
      return currentUrl.substring(0, charIndex + 1);
    }
    return currentUrl + '/';
  };

  return (
    <div className={s.optionsContainer}>
      <More onClick={handleCellClick} className={s.moreIcon} />
      {focus && currentCellRef === myId ? (
        <CustomDropdown
          title="Request Menu"
          style={{
            width: '227px',
            height: `${actions.length * 30 + 59}px`,
            position: 'absolute',
            display: 'block',
            top: 0,
            zIndex: 1,
          }}
          onSelect={handleOptionSelect}
          options={actions}
          headerStyle={{
            paddingLeft: '15px',
          }}
          optionsStyle={{
            height: '30px',
            alignItems: 'center',
            paddingLeft: '19px',
          }}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  searchResults: state.seats.searchResults,
  currentCellRef: state.seats.currentCellRef,
  seats: state.seats.seats,
  user: state.auth.user,
  statusJustifications: state.seats.statusJustifications,
});

const mapDispatchToProps = (dispatch: any) => ({
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  setRequest: (request: any) => dispatch(setRequest(request)),
  openModal: () => dispatch(openModal()),
  setMode: (mode: string) => dispatch(setMode(mode)),
  deleteSeat: (seatId: any) => dispatch(deleteSeat(seatId)),
  setSeatsLocal: (seats: any) => dispatch(setSeatsLocal(seats)),
  setMessage: (message: any, ms: any) => dispatch(setMessage(message, ms)),
  displayModalMessage: (message: any) => dispatch(displayModalMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
