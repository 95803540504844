import { TOOLTIP } from '../actions/constants';

const initState: any = {
  tooltipY: null,
  tooltipX: null,
  tooltipText: null,
};

export default (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case TOOLTIP.SHOW_TOOLTIP:
      return {
        ...state,
        tooltipY: payload.tooltipY,
        tooltipX: payload.tooltipX,
        tooltipText: payload.tooltipText,
      };
    case TOOLTIP.HIDE_TOOLTIP:
      return { ...state, tooltipY: null, tooltipX: null, tooltipText: null };
    default:
      return state;
  }
};
