import React from 'react';

const SeatsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="154px"
      height="41px"
      viewBox="0 0 154 41"
      version="1.1"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Sub-Brands"
          transform="translate(-1085.000000, -2370.000000)"
          fill="#000000"
        >
          <g id="Blogs" transform="translate(33.000000, 1876.000000)">
            <g
              id="Wix-Photography-Blog-Copy"
              transform="translate(1052.000000, 494.000000)"
            >
              <text
                id="Seats"
                fontFamily="Helvetica Neue,Helvetica,Arial,sans-serif"
                fontSize="32.514048"
                fontWeight="normal"
                letterSpacing="-0.5"
              >
                <tspan x="63.3863097" y="33">
                  Seats
                </tspan>
              </text>
              <g id="Wix" transform="translate(0.000000, 9.754214)">
                <path
                  d="M34.2851944,0.4358525 C33.0956914,1.05915429 32.6522327,2.11300992 32.6522327,5.02342042 C32.6522327,5.02342042 33.2549031,4.44017015 34.1484891,4.11558595 C34.7995065,3.88028327 35.3554971,3.51981958 35.6772547,3.2845169 C36.682539,2.5477358 36.8384164,1.598181 36.8384164,0.00112662046 C36.8384164,0.00112662046 35.1996197,-0.0439313402 34.2851944,0.4358525"
                  id="Fill-2-Copy-20"
                ></path>
                <path
                  d="M27.0765928,1.12360629 C26.1139136,1.98406124 25.8327186,3.35525407 25.8327186,3.35525407 L22.6461182,15.8746219 L19.9954423,5.62799569 C19.7374045,4.53942793 19.2717788,3.19255596 18.5357096,2.28178201 C17.5978418,1.12192899 15.6915054,1.04896644 15.4888796,1.04896644 C15.2854268,1.04896644 13.3790904,1.12192899 12.4420496,2.28178201 C11.7051534,3.19255596 11.2403547,4.53942793 10.9814899,5.62799569 L8.33164103,15.8746219 L5.14421354,3.35525407 C5.14421354,3.35525407 4.86384563,1.98406124 3.90199349,1.12360629 C2.34053446,-0.271068733 0,0.0266520334 0,0.0266520334 L6.10275751,23.423311 C6.10275751,23.423311 8.11495551,23.5709134 9.12312212,23.0501117 C10.4463925,22.3657733 11.0749459,21.8382624 11.8763515,18.6547469 C12.5900905,15.8175937 14.5857476,7.48057364 14.7718325,6.88680941 C14.8636344,6.59328189 14.9810746,5.8930091 15.4888796,5.8930091 C16.0074362,5.8930091 16.1149519,6.59328189 16.2050997,6.88680941 C16.3878764,7.48141229 18.3868417,15.8175937 19.1005807,18.6547469 C19.9019863,21.8382624 20.5321938,22.3657733 21.8554642,23.0501117 C22.8619767,23.5709134 24.8750017,23.423311 24.8750017,23.423311 L30.9777592,0.0266520334 C30.9777592,0.0266520334 28.6372248,-0.271068733 27.0765928,1.12360629"
                  id="Fill-3-Copy-20"
                ></path>
                <path
                  d="M36.8367492,4.18618368 C36.8367492,4.18618368 36.4516404,4.77408847 35.5738922,5.25906875 C35.0103995,5.57031246 34.4694132,5.78110186 33.8892491,6.05611614 C32.9164741,6.51721794 32.6522327,7.03019368 32.6522327,7.81324334 L32.6522327,8.07508329 L32.6522327,9.28465211 L32.6522327,9.31676455 L32.6522327,9.58930865 L32.6522327,23.406718 C32.6522327,23.406718 34.2051718,23.6002161 35.2204589,23.0888871 C36.5266616,22.4309937 36.8267464,21.7969787 36.8384164,18.9406177 L36.8384164,5.0178137 L36.8367492,5.0186371 L36.8367492,4.18618368 Z"
                  id="Fill-4-Copy-20"
                ></path>
                <path
                  d="M52.5904092,11.7620241 L60.281045,0.10433265 C60.281045,0.10433265 57.0350561,-0.459128019 55.4280821,1.03282876 C54.3994873,1.98644198 53.2493014,3.70194111 53.2493014,3.70194111 L50.4190226,7.85882405 C50.2818219,8.07566849 50.1027215,8.31093216 49.815175,8.31093216 C49.5284501,8.31093216 49.3485282,8.07566849 49.2113274,7.85882405 L46.3810486,3.70194111 C46.3810486,3.70194111 45.2316843,1.98644198 44.2030895,1.03282876 C42.5961155,-0.459128019 39.3501266,0.10433265 39.3501266,0.10433265 L47.0407623,11.7620241 L39.3698441,23.3837143 C39.3698441,23.3837143 42.7497474,23.8182405 44.357543,22.3271209 C45.3853162,21.3735077 46.3810486,19.8221071 46.3810486,19.8221071 L49.2113274,15.6652242 C49.3485282,15.4483797 49.5284501,15.2139533 49.815175,15.2139533 C50.1027215,15.2139533 50.2818219,15.4483797 50.4190226,15.6652242 L53.2493014,19.8221071 C53.2493014,19.8221071 54.3033646,21.3735077 55.3319594,22.3271209 C56.9389334,23.8182405 60.260506,23.3837143 60.260506,23.3837143 L52.5904092,11.7620241 Z"
                  id="Fill-5-Copy-20"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SeatsIcon;
