import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Text, Button } from "@wix/design-system";
import s from './Header.scss';
import { signOut } from '../redux/actions/authActions';
import { v4 as uuidv4 } from 'uuid';
import {
  setChangedSeats,
  setSeatsLocal,
  setMessage,
  updateSeats,
  applyDataFilters,
  displayModalMessage,
  setRef,
  setRefreshSeatsTable,
} from '../redux/actions/seatsActions';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import { sendReplacementReport, syncGoogleSheets } from '../../../Client/utils';
import {
  ReplaceSmall,
  LinkSmall,
  UploadExportSmall,
  SendSmall,
  More,
} from 'wix-ui-icons-common';
import {
  DEFAULT_SUCCESS_MESSAGE,
  PENDING_SUCCESS_MESSAGE,
  DEFAULT_FAILURE_MESSAGE,
} from '../../../Client/config';

const Header = (props: any) => {
  const {
    seats,
    changedSeats,
    setChangedSeats,
    setSeatsLocal,
    message,
    setMessage,
    updateSeats,
    applyDataFilters,
    displayModalMessage,
    setRef,
    currentCellRef,
    user,
    seatsCount,
    setRefreshSeatsTable,
  } = props;

  const [buttonLoader, setButtonLoader] = useState(false);
  const [isSubmitChangesActive, setIsSubmitChangesActive] = useState(false);
  const [myId, setMyId] = useState(uuidv4());

  let seatChanges = 0;
  for (const seatId in changedSeats) {
    for (const key in changedSeats[seatId]) {
      if (key !== 'requesterEmail') {
        seatChanges++;
      }
    }
  }

  useEffect(() => {
    setIsSubmitChangesActive(Object.keys(changedSeats).length > 0);
  }, [changedSeats]);

  const clearAllChanges = () => {
    setChangedSeats({});
  };
  const submitAllChanges = () => {
    setButtonLoader(true);
    try {
      if (!isDataValidated(changedSeats)) {
        return;
      }
      // if (!validatePriorityDuplicates(changedSeats)) {
      //   return;
      // }
      const a = updateSeats(changedSeats).then((res: any) => {
        if (res?.data?.success) {
          let successMessage = DEFAULT_SUCCESS_MESSAGE;
          for (const changedSeatId in res.data.seats) {
            const changedSeat = res.data.seats[changedSeatId];
            const localSeat = seats.find(
              (seat: any) => seat._id == changedSeatId,
            );
            for (const key in changedSeat) {
              if (key === 'pendingChanges') {
                localSeat.pendingChanges = {
                  ...localSeat.pendingChanges,
                  ...changedSeat.pendingChanges,
                };
                successMessage = PENDING_SUCCESS_MESSAGE;
                continue;
              }
              localSeat[key] = changedSeat[key];
              if (key === 'statusReason') {
                localSeat.statusReasonUpdater = user.email;
                localSeat.statusReasonUpdateTime = new Date();
              }
              if (user.permission === 3 || user.permission === 4) {
                if (key === 'status') {
                  delete localSeat.pendingChanges;
                  continue;
                }
                if (localSeat.pendingChanges && localSeat.pendingChanges[key]) {
                  delete localSeat.pendingChanges[key];
                }
              }
              if (
                localSeat.pendingChanges &&
                Object.keys(localSeat.pendingChanges).length === 0
              ) {
                delete localSeat.pendingChanges;
              }
            }
            localSeat.updatedAt = new Date().toISOString();
            const localSeatIndex = seats.findIndex(
              (seat: any) => seat._id == changedSeatId,
            );
            if (localSeatIndex === 0) {
              seats.splice(0, 1, localSeat);
            }
          }
          // setting the new wixpriorities based on the change
          // if (res.data.wixPriorityChanges) {
          //   for (const seat of seats) {
          //     seat.wixPriority =
          //       res.data.wixPriorityChanges[seat._id] || seat.wixPriority;
          //   }
          //   refreshGuildPriority(seats);
          // }
          setSeatsLocal(seats);
          setRefreshSeatsTable(true);
          applyDataFilters();
          setChangedSeats({});
          setButtonLoader(false);
          setMessage(
            {
              type: 'success',
              text: successMessage,
            },
            5000,
          );
          return;
        }
        setButtonLoader(false);
        setMessage({ type: 'fail', text: DEFAULT_FAILURE_MESSAGE }, 5000);
      });
    } catch (error: any) {
      console.log(error);
      setMessage({ type: 'fail', text: DEFAULT_FAILURE_MESSAGE }, 5000);
      setButtonLoader(false);
    }
  };

  const moreClick = () => {
    if (currentCellRef === myId) {
      setRef(null);
      return;
    }
    setRef(myId);
  };
  const handleMoreOptionClick = (option: any) => {
    switch (option.action) {
      case 'Sync':
        displayModalMessage({
          title: 'Sync Google Sheets',
          message: `Are you sure you want to sync Google Sheets`,
          type: 'confirm',
          buttonText: 'Yes',
          onSubmit: syncGoogleSheets,
        });
        break;
      case 'Link':
        window.open(
          'https://docs.google.com/spreadsheets/d/1rrlU0VyCY1k-ByucS9_YFG3Pas0DezMjRXDQobqdSVA/edit#gid=0',
          'blank',
        );
        break;
      case 'Upload':
        console.log(option.action);
        break;
      case 'Replacement':
        displayModalMessage({
          title: 'Send Replacement Name Report',
          message: `Are you sure you want to send Replacement Name Report`,
          type: 'confirm',
          buttonText: 'Yes',
          onSubmit: sendReplacementReport,
        });
        break;
      default:
        console.log(111, option.action);
    }
  };

  // function validatePriorityDuplicates(chnagedSeats: any) {
  //   const priorityHash: any = {};
  //   for (const seatId in chnagedSeats) {
  //     const seatChanges = chnagedSeats[seatId];
  //     const originalSeat = seats.find(
  //       (seat: any) => seat._id.toString() === seatId,
  //     );
  //     if (
  //       (seatChanges.status === 2 ||
  //         seatChanges.status === 5 ||
  //         seatChanges.status === 9) &&
  //       originalSeat.status !== 2 &&
  //       seatChanges.status !== 5 &&
  //       seatChanges.status !== 9
  //     ) {
  //       if (priorityHash[seatChanges.wixPriority || originalSeat.wixPriority]) {
  //         displayModalMessage({
  //           title: 'Wix Priority Error',
  //           message: `Seat number ${seatId} and seat number ${
  //             priorityHash[seatChanges.wixPriority || originalSeat.wixPriority]
  //           } was set with the same priority ${
  //             seatChanges.wixPriority || originalSeat.wixPriority
  //           }`,
  //           type: 'delete',
  //           buttonText: 'OK',
  //           onSubmit: () => {},
  //         });
  //         setButtonLoader(false);
  //         return false;
  //       }
  //       if ((seatChanges.wixPriority || originalSeat.wixPriority) === '-') {
  //         continue;
  //       }
  //       priorityHash[seatChanges.wixPriority || originalSeat.wixPriority] =
  //         seatId;
  //       continue;
  //     }
  //     if (priorityHash[seatChanges.wixPriority]) {
  //       displayModalMessage({
  //         title: 'Wix Priority Error',
  //         message: `Seat number ${seatId} and seat number ${
  //           priorityHash[seatChanges.wixPriority]
  //         } was set with the same priority ${seatChanges.wixPriority}`,
  //         type: 'delete',
  //         buttonText: 'OK',
  //         onSubmit: () => {},
  //       });
  //       setButtonLoader(false);
  //       return false;
  //     }
  //     if (seatChanges.wixPriority && seatChanges.wixPriority !== '-') {
  //       priorityHash[seatChanges.wixPriority] = seatId;
  //     }
  //   }
  //   return true;
  // }

  function isDataValidated(changedSeats: any) {
    for (const seatId in changedSeats) {
      const seatChanges = changedSeats[seatId];
      const originalSeat = seats.find(
        (seat: any) => seat._id.toString() === seatId,
      );
      const status = seatChanges.status || originalSeat.status;
      const employeeName =
        seatChanges.employeeName || originalSeat.employeeName;
      let invalidReasonMessage;
      if (seatChanges.subGuild === '-') {
        invalidReasonMessage = `Sub Guild must be specified.`;
      }
      if (seatChanges.subTeam === '-') {
        invalidReasonMessage = `Team must be specified.`;
      }
      if (status === 6 && (!employeeName || employeeName === '-')) {
        invalidReasonMessage = `Recruited Employee Name must be specified for Hired status.`;
      }
      if (status !== 6 && employeeName && employeeName !== '-') {
        invalidReasonMessage = `Recruited Employee Name can be specified only for Hired status.`;
      }
      if (invalidReasonMessage) {
        displayModalMessage({
          title: 'Invalid Data',
          message: `Seat number ${seatId} has invalid data.\n
            ${invalidReasonMessage}`,
          type: 'delete',
          buttonText: 'OK',
          onSubmit: () => {},
        });
        setButtonLoader(false);
        return false;
      }
    }
    return true;
  }

  return (
    <div className={s.headerContainer}>
      <section className={s.headerSection}>
        <section className={s.headerSubSection}>
          <Text className={s.requests}>
            Team Requests (
            {(seatsCount.filtered || seatsCount.filtered === 0) &&
            seatsCount.filtered !== seatsCount.total
              ? `${seatsCount.filtered}/${seatsCount.total}`
              : seatsCount.total}
            )
          </Text>
        </section>
        {message ? (
          <section className={s.headerSubSection}>
            <div className={s[`${message.type}Message`]}>
              <Text className={s.messageText}>{message.text}</Text>
            </div>
          </section>
        ) : null}
        <section className={s.headerSubSection}>
          {user.permission === 4 && (
            <div
              className={s.moreContainer}
              style={{ marginTop: currentCellRef === myId ? '150px' : '0px' }}
            >
              <More className={s.moreButton} onClick={moreClick} />
              {currentCellRef === myId && (
                <CustomDropdown
                  style={{
                    paddingTop: '14px',
                    width: '253px',
                    height: '136px',
                    zIndex: 25,
                    position: 'relative',
                  }}
                  className={s.moreDropDown}
                  onSelect={handleMoreOptionClick}
                  options={[
                    {
                      value: 'Sync to Drive',
                      icon: <ReplaceSmall />,
                      action: 'Sync',
                    },
                    {
                      value: 'Link to Drive',
                      icon: <LinkSmall />,
                      action: 'Link',
                    },
                    {
                      value: 'Upload Priorities',
                      icon: <UploadExportSmall />,
                      action: 'Upload',
                    },
                    {
                      value: 'Send Replacement Name Report',
                      icon: <SendSmall />,
                      action: 'Replacement',
                    },
                  ]}
                />
              )}
            </div>
          )}

          <Button
            onClick={clearAllChanges}
            className={`${s.headerButton} ${s.clearChangesButton}`}
          >
            Clear All Changes
          </Button>
          {isSubmitChangesActive ? (
            <Button
              onClick={submitAllChanges}
              className={`${s.headerButton} ${s.submitChangesButton}`}
              disabled={buttonLoader}
            >
              {buttonLoader
                ? 'Saving changes'
                : seatChanges
                ? `Submit All Changes (${seatChanges})`
                : 'Submit All Changes'}
            </Button>
          ) : (
            <Button
              className={`${s.headerButton} ${s.submitChangesButton}`}
              disabled={true}
            >
              Submit All Changes
            </Button>
          )}
        </section>
      </section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  seats: state.seats.seats,
  seatsCount: state.seats.seatsCount,
  changedSeats: state.seats.changedSeats,
  message: state.seats.message,
  currentCellRef: state.seats.currentCellRef,
  user: state.auth.user,
  columnGroups: state.filter.columnGroups,
});

const mapDispatchToProps = (dispatch: any) => ({
  signOut: () => dispatch(signOut()),
  setChangedSeats: (changedSeats: any) =>
    dispatch(setChangedSeats(changedSeats)),
  setSeatsLocal: (seats: any) => dispatch(setSeatsLocal(seats)),
  setMessage: (message: any, ms: any) => dispatch(setMessage(message, ms)),
  updateSeats: (changedSeats: any) => dispatch(updateSeats(changedSeats)),
  applyDataFilters: () => dispatch(applyDataFilters()),
  displayModalMessage: (message: any) => dispatch(displayModalMessage(message)),
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  setRefreshSeatsTable: (isRefresh: any) =>
    dispatch(setRefreshSeatsTable(isRefresh)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
