import React from 'react';
import { connect } from 'react-redux';
import FormItem from '../FormItem/FormItem';
import s from '../Form.scss';
import { formValueSelector, change } from 'redux-form';
import { formName } from '../../../redux/actions/requestModalActions';

const GridGroup = (props: any) => {
  const { title, columns, hidden, order, mode, tableOnly, change } = props;

  const renderInputs = () => {
    return columns.map((col: any, index: number) => {
      if (!col.editModeOnly && !col.edit) {
        return null;
      }
      if (col.editModeOnly && mode === 'New') {
        return null;
      }
      return <FormItem key={index} {...col} />;
    });
  };
  if (hidden || tableOnly) {
    hidden && columns.forEach((col: any) => change(col.source, null));
    return null;
  }
  return (
    <article
      className={order === 1 ? s.topFormGroup : s.formGridGroup}
      style={{ order: order || 'auto' }}
    >
      <h3 className={s.gridGroupTitle}>{title}</h3>
      <div className={s.gridGroup}>{renderInputs()}</div>
    </article>
  );
};

const selector = formValueSelector(formName);

const isHidden = (state: any, ownProps: any) => {
  const { hidder } = ownProps;
  if (!hidder) {
    return false;
  }

  const { source, showValues } = hidder;

  for (const sourceName of source) {
    const hidderValue = selector(state, sourceName);
    if (hidderValue && showValues.includes(hidderValue)) {
      return false;
    }
  }
  return true;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  hidden: isHidden(state, ownProps),
  mode: state.requestModal.mode,
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  change: (field: string, value: string, form = formName) =>
    dispatch(change(form, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridGroup);
