import React from 'react';
import GoogleIcon from '../GoogleIcon';
import s from './CustomGoogleLogin.scss';

const CustomGoogleLogin = (props: any) => {
  const { onClick, disabled } = props;
  return (
    <button
      className={s.loginBtnContainer}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={s.loginBtnGoogleIcon}>
        <GoogleIcon width="18" height="18" />
      </div>
      <div className={s.loginBtnText}>Sign in with Google</div>
    </button>
  );
};

export default CustomGoogleLogin;
