import React, { useState } from 'react';
import { connect } from 'react-redux';
import s from './GroupEmployeesTable.scss';
import { Text } from "@wix/design-system";

const typeHash: any = {
  FTE: 'Full time',
  PTE: 'Part time',
};

const GroupEmployeesTable = (props: any) => {
  const { groupEmployeesData, avatarsHash } = props;

  const getAvatar = (name: string) => {
    return (
      <img
        className={s.avatar}
        src={avatarsHash[name] || 'https://i.stack.imgur.com/l60Hf.png'}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = 'https://i.stack.imgur.com/l60Hf.png';
        }}
      />
    );
  };

  const renderHeader = () => {
    return (
      <tr>
        <th className={s.headerCell} style={{ minWidth: '221px' }}>
          <Text className={s.tableHeader}>Employee</Text>
        </th>
        <th className={s.headerCell}>
          <Text className={s.tableHeader}>Guild</Text>
        </th>
        <th className={s.headerCell}>
          <Text className={s.tableHeader}>Sub guild</Text>
        </th>
        <th className={s.headerCell}>
          <Text className={s.tableHeader}>Department</Text>
        </th>
        <th className={s.headerCell}>
          <Text className={s.tableHeader}>Team</Text>
        </th>
        <th className={s.headerCell}>
          <Text className={s.tableHeader}>Profession</Text>
        </th>
        <th className={s.headerCell} style={{ minWidth: '148px' }}>
          <Text className={s.tableHeader}>Type</Text>
        </th>
        <th className={s.headerCell} style={{ minWidth: '148px' }}>
          <Text className={s.tableHeader}>City</Text>
        </th>
      </tr>
    );
  };

  const renderTable = () => {
    return groupEmployeesData.map((rowData: any, index: number) => {
      return (
        <tr key={index} className={s.tableRow}>
          <td className={s.tableCell}>
            <div className={s.employeeNameCell}>
              {getAvatar(rowData.name)}
              <Text className={s.tableText}>{rowData.name}</Text>
            </div>
          </td>
          <td className={s.tableCell}>
            <Text className={s.tableText}>{rowData.guild}</Text>
          </td>
          <td className={s.tableCell}>
            <Text className={s.tableText}>{rowData.subGuild}</Text>
          </td>
          <td className={s.tableCell}>
            <Text className={s.tableText}>{rowData.company}</Text>
          </td>
          <td className={s.tableCell}>
            <Text className={s.tableText}>{rowData.subTeam}</Text>
          </td>
          <td className={s.tableCell}>
            <Text className={s.tableText}>{rowData.profession}</Text>
          </td>
          <td className={s.tableCell}>
            <Text className={s.tableText}>
              {rowData.studentIntern
                ? rowData.studentIntern
                : typeHash[rowData.type]}
            </Text>
          </td>
          <td className={s.tableCell}>
            <Text className={s.tableText}>{rowData.location}</Text>
          </td>
        </tr>
      );
    });
  };

  return (
    <div id="table-container" className={s.tableContainer}>
      <table id="table" className={s.table}>
        <thead>{renderHeader()}</thead>
        <tbody>{renderTable()}</tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  avatarsHash: state.dropdowns.avatarsHash,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupEmployeesTable);
