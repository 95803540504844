import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { formatDate } from '../../../../../Client/utils';


const FormatDate = (props: any) => {
    const { value } = props;

    const getValue = () => {
        return formatDate(value);
    }

    return (
        <div>{getValue()}</div>
    );
};

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatDate);
