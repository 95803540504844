import { MODALS } from '../actions/constants';

const initState: any = {
  showContactUsModal: false,
};

export default (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case MODALS.SHOW_CONTACT_US_MODAL:
      return { ...state, showContactUsModal: payload };
    default:
      return state;
  }
};
