import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { showTooltip } from '../../../redux/actions/tooltipActions';
import CircleSmallFilled from 'wix-ui-icons-common/CircleSmallFilled';
import s from './Text.scss';
import { getPendingChangeTooltip } from '../../../../../Client/utils';
import { Badge } from "@wix/design-system";
import { MAX_CHARS } from '../../../../../Client/config';

const Text = (props: any) => {
  const { value, col, data, showTooltip, changedSeats, dropdowns } = props;
  const [isGuildPriority, setIsGuildPriority] = useState(
    data.status === 6 && col.source === 'guildPriority' && value !== '-',
  );
  const [edited, setEdited] = useState(
    changedSeats[data._id] && changedSeats[data._id][col.source],
  );

  const [pendingChange, setPendingChange] = useState<any>(null);

  const isPriorityPlanning = props.col.title === "Priority Status";

  useEffect(() => {
    setPendingChange(
      data.pendingChanges && data.pendingChanges[col.source]
        ? data.pendingChanges[col.source]
        : null,
    );
  }, [data, changedSeats]);

  useEffect(() => {
    setEdited(changedSeats[data._id] && changedSeats[data._id][col.source]);
    if (
      changedSeats[data._id] &&
      changedSeats[data._id].status === 6 &&
      col.source === 'guildPriority' &&
      value !== '-'
    ) {
      setIsGuildPriority(true);
    }
  }, [changedSeats]);

  const maxChars: number = col.maxChars || MAX_CHARS;

  const renderTooltip = (e: any) => {
    const display =
      edited ||
      getPendingChangeTooltip(pendingChange, value, col.source) ||
      value + '';
    if (!display || display.length <= maxChars) {
      return;
    }
    const rect = e.target.getBoundingClientRect();
    showTooltip(display, rect.x, rect.y - 40);
  };

  const getValue = () => {
    let text = edited || pendingChange || value || '-'
    if(text.length > maxChars){
      text =  `${text.substring(0, maxChars - 3)}...`;
    }
    if(isPriorityPlanning){
      const priorityObj = dropdowns[col.source]?.data?.find((priority: any) => priority.value === text);
      return (<Badge
        size="small"
        skin={priorityObj?.skin}
      >
        {text}
      </Badge>)
    }
    return text;
  }

  return (
    <div
      className={
        pendingChange && !edited
          ? s.pendingChange
          : isGuildPriority
            ? s.guildPriority
            : s.main
      }
      onMouseEnter={renderTooltip}
    >
      {edited ? <CircleSmallFilled className={s.edited} size="18px" /> : null}
      {getValue()}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  changedSeats: state.seats.changedSeats,
  dropdowns: state.dropdowns.dropdowns,
});

const mapDispatchToProps = (dispatch: any) => ({
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Text);
