import { DEFAULT_FAILURE_MESSAGE } from '../../../../Client/config';
import { httpClient } from '../../../../Client/httpClient';
import { MODALS } from './constants';
import { setMessage } from './seatsActions';

export const setShowContactUsModal = (isShow: any) => (dispatch: any) => {
  dispatch({
    payload: isShow,
    type: MODALS.SHOW_CONTACT_US_MODAL,
  });
};

export const sendFeedback = (feedbackData: any) => async (dispatch: any) => {
  try {
    const formData = new FormData();
    for (const key in feedbackData) {
      formData.append(key, feedbackData[key]);
    }
    const res = await httpClient.post('/data/sendFeedback', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res?.status === 200) {
      dispatch(
        setMessage(
          {
            type: 'success',
            text: 'Your feedback was submitted successfully.',
          },
          2000,
        ),
      );
    } else {
      dispatch(
        setMessage({ type: 'fail', text: DEFAULT_FAILURE_MESSAGE }, 5000),
      );
    }
  } catch (error) {
    dispatch(setMessage({ type: 'fail', text: DEFAULT_FAILURE_MESSAGE }, 5000));
  }
};
