import { REQUEST } from '../actions/constants';

const initState: any = {
  isModalOpen: false,
  request: null,
  currentInputRef: null,
  openNew: false,
  mode: 'New',
  amountOfCopies: 1,
};

export default (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST.SET_REQUEST:
      return {
        ...state,
        request: payload,
      };
    case REQUEST.SET_AMOUNT_OF_COPIES:
      return { ...state, amountOfCopies: payload };
    case REQUEST.SUBMIT_REQUEST:
      return { ...state, currentInputRef: null, isModalOpen: false };
    case REQUEST.SUBMIT_SUCCESS:
      return { ...state, request: null, isModalOpen: payload, mode: 'New' };
    case REQUEST.SUBMIT_FAILURE:
      return { ...state, isModalOpen: true };
    case REQUEST.OPEN_MODAL:
      return { ...state, isModalOpen: true };
    case REQUEST.CLOSE_MODAL:
      return { ...state, isModalOpen: false };
    case REQUEST.SET_MODE:
      return { ...state, mode: payload };
    case REQUEST.SET_INPUT_REF:
      return { ...state, currentInputRef: payload };
    case REQUEST.SET_CURRENT_DROPDOWN_VALUE:
      return { ...state, currentDropdownValue: payload };
    case REQUEST.SET_OPEN_NEW:
      return { ...state, openNew: payload };
    case REQUEST.SHOW_MODAL_TOOLTIP:
      return {
        ...state,
        tooltipY: payload.tooltipY,
        tooltipX: payload.tooltipX,
        tooltipText: payload.tooltipText,
      };
    case REQUEST.HIDE_MODAL_TOOLTIP:
      return { ...state, tooltipY: null, tooltipX: null, tooltipText: null };
    default:
      return state;
  }
};
