import React, { useState, useEffect } from 'react';
import s from './CustomCheckBox.scss';
import { Text, Checkbox } from "@wix/design-system";
import { change } from 'redux-form';
import { connect } from 'react-redux';

const CustomCheckBox = (props: any) => {
  const { title, source, change, dummy, initValue, mode } = props;
  const [checkBoxValue, setCheckBoxValue] = useState(
    initValue === 'Y' || Number(initValue) === 1 ? true : false,
  );
  const [disabled, setDisabled] = useState(mode === 'View');

  useEffect(() => {
    setCheckboxValueHandler(checkBoxValue);
  }, []);

  const setCheckboxValueHandler = (value: boolean) => {
    if (dummy) {
      const temp = value ? 'Y' : 'N';
      change(source, temp);
    } else {
      change(source, value);
    }
  };

  const handleSetValue = (value: boolean) => {
    setCheckboxValueHandler(value);
    setCheckBoxValue(value);
  };

  return (
    <div className={s.checkBoxContainer}>
      {disabled ? (
        <Checkbox
          className={`${
            checkBoxValue ? s.groupCheckboxChecked : s.groupCheckbox
          } ${disabled ? s.disabled : ''}`}
          checked={checkBoxValue}
          onChange={() => {}}
          disabled
        ></Checkbox>
      ) : (
        <Checkbox
          className={`${
            checkBoxValue ? s.groupCheckboxChecked : s.groupCheckbox
          }`}
          checked={checkBoxValue}
          onChange={() => handleSetValue(!checkBoxValue)}
        ></Checkbox>
      )}
      <Text className={s.formInputLabel}>{title}</Text>
    </div>
  );
};

const formName = 'requestModalForm';

const mapStateToProps = (state: any) => ({
  mode: state.requestModal.mode,
});

const mapDispatchToProps = (dispatch: any) => ({
  change: (field: string, value: string, form = formName) =>
    dispatch(change(form, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomCheckBox);
