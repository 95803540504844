import { FILTERS } from './constants';
import { sortCol, searchSeats, setSeats } from './seatsActions';
import { filterHeadCount } from './employeesTableActions';
import lodash from 'lodash';
import { httpClient } from '../../../../Client/httpClient';

export const toggleColumn = (name: string) => (
  dispatch: any,
  getState: any,
) => {
  const { columnGroups } = getState().filter;
  let column = null;
  for (const group of columnGroups) {
    for (const col of group.columns) {
      if (col.source === name) {
        column = col;
        break;
      }
    }
    if (column) {
      break;
    }
  }
  column.display = !column.display;
  const newColumnGroups = [...columnGroups];
  dispatch({
    type: FILTERS.SET_COLUMN_GROUPS,
    payload: newColumnGroups,
  });
};
export const toggleGroup = (title: string) => (
  dispatch: any,
  getState: any,
) => {
  const { columnGroups } = getState().filter;
  const group = columnGroups.find((groupObj: any) => groupObj.title === title);
  group.display = !group.display;
  const newColumnGroups = [...columnGroups];
  dispatch({
    type: FILTERS.SET_COLUMN_GROUPS,
    payload: newColumnGroups,
  });
};

export const setFilters = (columnGroups: any) => (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    type: FILTERS.SET_COLUMN_GROUPS,
    payload: columnGroups,
  });
};
export const setFieldsFilter = (fieldsFilter: any) => (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    type: FILTERS.SET_FIELDS_FILTER,
    payload: fieldsFilter,
  });
  dispatch(saveFiltersToDb(fieldsFilter));
};
export const applyFilters = () => async (dispatch: any, getState: any) => {
  try {
    const { data } = await httpClient.get(`/user/filters`);
    const { filters } = data;
    if (filters) {
      const { columnGroups } = getState().filter;
      const newColumnGroups = [...columnGroups];
      dispatch(setFieldsFilter(filters));
      for (const group of newColumnGroups) {
        if (
          filters.filteredGroups &&
          filters.filteredGroups.includes(group.title)
        ) {
          group.filtered = true;
        }
        for (const column of group.columns) {
          if (
            filters.filteredColumns &&
            filters.filteredColumns.includes(column.title)
          ) {
            column.filtered = true;
          }
        }
      }
      dispatch(setFilters(newColumnGroups));
    }
  } catch (error: any) {
    console.error(error);
  }
};
export const saveFiltersToDb = (filters: any) => async (
  dispatch: any,
  getState: any,
) => {
  try {
    const response = await httpClient.post('/user/filters', filters, {});
  } catch (error: any) {
    console.error(error);
  }
};

export const setDataFilters = (dataFilters: any) => (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    type: FILTERS.SET_DATA_FILTERS,
    payload: dataFilters,
  });
  dispatch(setSeats(true));
};

export const clearDataFilter = (filterName: any) => (
  dispatch: any,
  getState: any,
) => {
  dispatch(setCurrentView(null));
  const { dataFilters } = getState().filter;
  const temp = { ...dataFilters };

  delete temp[filterName];

  dispatch(setDataFilters(temp));
};

export const setFiltersByPermissions = () => (dispatch: any, getState: any) => {
  const { permission } = getState().auth.user;
  dispatch({
    type: FILTERS.SET_PERMISSIONS,
    payload: permission,
  });
};

export const setShowSaveViewModal = (isShown: any) => (dispatch: any) => {
  dispatch({
    type: FILTERS.SET_SHOW_SAVE_VIEW_MODAL,
    payload: isShown,
  });
};

export const saveViewsToDB = (isShown: any) => (dispatch: any) => {
  dispatch({
    type: FILTERS.SET_SHOW_SAVE_VIEW_MODAL,
    payload: isShown,
  });
};

const setViews = (views: any) => async (dispatch: any) => {
  dispatch({
    type: FILTERS.SET_VIEWS,
    payload: views,
  });
  try {
    const response = await httpClient.post('/user/views', views, {});
  } catch (error: any) {
    console.error(error);
  }
};

export const addView = (viewName: any) => (dispatch: any, getState: any) => {
  const { views, fieldsFilter, searchValue, dataFilters } = getState().filter;
  const { sort } = getState().seats;
  const filteredColumns = { columnsFilters: fieldsFilter };
  const sortOrder = { sort };
  const filteredData = { dataFilters };
  const search = { searchValue };
  const newViews = views ? views : [];
  const newView = {
    [viewName]: {
      ...filteredColumns,
      ...sortOrder,
      ...search,
      ...filteredData,
    },
  };
  newViews.push(newView);
  dispatch(setViews(newViews));
  dispatch({
    type: FILTERS.SET_CURRENT_VIEW,
    payload: newView,
  });
};

export const removeView = (view: any) => (dispatch: any, getState: any) => {
  const { views, currentView } = getState().filter;
  const newViews = [...views];
  const viewIndex = views.findIndex((element: any) => element === view);
  newViews.splice(viewIndex, 1);
  dispatch(setViews(newViews));
  if (currentView === view) {
    dispatch(setCurrentView(null));
  }
};

export const resetView = () => (dispatch: any, getState: any) => {
  const fieldsFilter = { filteredGroups: [], filteredColumns: [] };
  const filteredColumns = { columnsFilters: fieldsFilter };
  const sort = {
    column: { title: 'Last Modified', source: 'updatedAt' },
    type: 'Desc',
  };
  const sortOrder = { sort };
  const filteredData = {};
  const search = {};
  const view = {
    '-Select-': {
      ...filteredColumns,
      ...sortOrder,
      ...search,
      ...filteredData,
    },
  };
  dispatch(setCurrentView(view));
};

export const setCurrentView = (selectedView: any) => (
  dispatch: any,
  getState: any,
) => {
  if (selectedView) {
    const originalValues: any = Object.values(selectedView)[0];
    const values = lodash.cloneDeep(originalValues);
    const { fieldsFilter, searchValue, dataFilters } = getState().filter;
    const { sort } = getState().seats;

    const { columnGroups } = getState().filter;
    const newColumnGroups = [...columnGroups];

    if (
      values.columnsFilters &&
      JSON.stringify(values.columnsFilters) !== JSON.stringify(fieldsFilter)
    ) {
      dispatch(setFieldsFilter(values.columnsFilters));
      for (const group of newColumnGroups) {
        group.filtered = values.columnsFilters?.filteredGroups.includes(
          group.title,
        );
        for (const column of group.columns) {
          column.filtered = values.columnsFilters?.filteredColumns.includes(
            column.title,
          );
        }
      }
      dispatch(setFilters(newColumnGroups));
    }

    if (values.sort && JSON.stringify(values.sort) !== JSON.stringify(sort)) {
      dispatch(sortCol(values.sort.column, values.sort.type));
    }

    const selectedSearchValue = values.searchValue || '';
    const currentSearchValue = searchValue || '';
    if (selectedSearchValue !== currentSearchValue) {
      dispatch(searchSeats(selectedSearchValue));
    }

    const selectedDataFilters = values.dataFilters || '';
    const currentDataFilters = dataFilters || '';
    if (selectedDataFilters !== currentDataFilters) {
      dispatch(setDataFilters(selectedDataFilters));
    }
  }
  dispatch({
    type: FILTERS.SET_CURRENT_VIEW,
    payload: selectedView,
  });
};

export const initUserViews = () => async (dispatch: any, getState: any) => {
  try {
    const response = await httpClient.get(`/user/views`);
    let views: any = [];
    if (response && response.data) {
      views = response.data.views;
    }
    dispatch({
      type: FILTERS.SET_VIEWS,
      payload: views,
    });
  } catch (error: any) {
    console.error(error);
  }
};

export const clearFiltersAndSearch = () => async (
  dispatch: any,
  getState: any,
) => {
  try {
    dispatch({
      type: FILTERS.SET_SEARCH_VALUE,
      payload: '',
    });
    dispatch({
      type: FILTERS.SET_DATA_FILTERS,
      payload: null,
    });
    dispatch(setSeats(true));
  } catch (error: any) {
    console.error(error);
  }
};

export const setHcdFilters = (hcdFilters: any) => (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    type: FILTERS.HCD_DATA_FILTERS,
    payload: hcdFilters,
  });
  dispatch(filterHeadCount(hcdFilters));
};
