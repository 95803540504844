import React, { useState, Fragment, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Text } from "@wix/design-system";
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
import { Field } from 'redux-form';
import s from './FormRequestorDropdown.scss';
import { formName } from '../../../redux/actions/requestModalActions';
import { connect } from 'react-redux';

const validateRequired = (value: string) => (value ? undefined : 'Required');

const renderField = ({
  input,
  placeholder,
  aditionalClass,
  disabled,
  meta: { touched, error, warning },
}: any) => {
  return (
    <input
      {...input}
      autoComplete="off"
      className={`${s.formInput} ${disabled ? s.disabled : ''} ${
        aditionalClass ? aditionalClass : ''
      } ${touched && error ? s.errorInput : ''}`}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

const FormRequestorDropdown = (props: any) => {
  const {
    title,
    placeholder,
    currentInputRef,
    aditionalClass,
    setRef,
    currentDropdownValue,
    setCurrentDropdownValue,
    source,
    dropdownName,
    dropdowns,
    change,
    initValue,
    required,
    addititonalValue,
    form,
    avatarsHash,
    mode,
  } = props;

  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(initValue || '');
  const [initialValue, setInitialValue] = useState(initValue || '');
  const [optionSelected, setOptionSelected] = useState(false);
  const [selected, setSelected] = useState(false);
  const [myId, setMyId] = useState(uuidv4());
  const [options, setOptions] = useState([]);
  const [pendingChange, setPendingChange] = useState(null);
  const [disabled, setDisabled] = useState(mode === 'View');
  const [avatarImage, setAvatarImage] = useState(
    'https://i.stack.imgur.com/l60Hf.png',
  );

  useEffect(() => {
    if (form?.values?.pendingChanges && form.values.pendingChanges[source]) {
      setPendingChange(form.values.pendingChanges[source]);
      change(source, form.values.pendingChanges[source]);
      setAvatarImage(
        avatarsHash[form.values.pendingChanges[source]] ||
          'https://i.stack.imgur.com/l60Hf.png',
      );
    }
  }, []);

  useEffect(() => {
    if (pendingChange) {
      setAvatarImage(
        avatarsHash[pendingChange] || 'https://i.stack.imgur.com/l60Hf.png',
      );
    }
  }, [pendingChange]);

  useEffect(() => {
    if (
      !optionSelected &&
      selected &&
      !currentInputRef &&
      currentDropdownValue &&
      currentDropdownValue[source]
    ) {
      setSelected(false);
      handleSetValue(currentDropdownValue[source]);
    }
  }, [currentInputRef]);

  useEffect(() => {
    if (value) {
      const data = dropdowns[source].data;
      const initItem = data.find((item: any) => item.value === value);
      if (initItem) {
        setAvatarImage(initItem.icon || 'https://i.stack.imgur.com/l60Hf.png');
        if (addititonalValue) {
          const email: any = initItem.email || initItem.oracleMail;
          change(addititonalValue, email);
        }
      }
    }
  }, [value]);

  useEffect(() => {
    if (dropdowns[source]) {
      const defaultValue = dropdowns[source].defaultValue;
      if (!initValue && defaultValue) {
        const email: any = defaultValue.email || defaultValue.oracleMail;
        const value: any = defaultValue.value;
        setAvatarImage(
          defaultValue.icon || 'https://i.stack.imgur.com/l60Hf.png',
        );
        handleSetValue(value);
        if (addititonalValue) {
          change(addititonalValue, email);
        }
      }
    }
  }, [dropdowns]);

  const handleOptionSelect = (option: any) => {
    setAvatarImage(option.icon || 'https://i.stack.imgur.com/l60Hf.png');
    handleSetValue(option.value);
    setSelected(false);
    setOptionSelected(true);
    if (addititonalValue) {
      const email: any = option.email || option.oracleMail;
      change(addititonalValue, email);
    }
    setFocus(false);
  };

  const handleInputClick = (event: any) => {
    scrollTo();
    const val =
      pendingChange && value === initialValue && !optionSelected
        ? pendingChange
        : value;
    if (val !== '') {
      const currentValue = { ...currentDropdownValue, [source]: val };
      setCurrentDropdownValue(currentValue);
    }
    setOptionSelected(false);
    setSelected(true);
    setOptionValues();
    setRef(myId);
    setAvatarImage('https://i.stack.imgur.com/l60Hf.png');
    handleSetValue('');
    setFocus(true);
  };

  const handleSetValue = (value: string) => {
    change(source, value);
    setValue(value);
  };

  const scrollTo = () => {
    const element = document.getElementById(source);
    const form = document.getElementById(formName);

    if (
      form &&
      element &&
      element.offsetTop + 200 > form.clientHeight + form.scrollTop
    ) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const setOptionValues = () => {
    const temp = dropdowns[dropdownName || source]
      ? dropdowns[dropdownName || source].data
      : [];
    setOptions(temp);
  };

  const isFocused = focus && currentInputRef === myId;

  return (
    <div className={s.mainContainer}>
      {title && (
        <Text className={s.formInputLabel}>
          {title}
          {required && '*'}
        </Text>
      )}
      <div className={`${s.formInputGroup} ${aditionalClass}`}>
        {isFocused ? (
          <Fragment>
            <div onClick={handleInputClick}>
              <Field
                name={source}
                component={renderField}
                aditionalClass={aditionalClass}
                validate={required ? [validateRequired] : null}
                onChange={(e: any) => setValue(e.target.value)}
              />
            </div>
            <CustomDropdown
              filter={value}
              title={placeholder}
              options={options}
              onSelect={handleOptionSelect}
              style={{
                position: 'absolute',
                zIndex: 1,
                width: 'inherit',
                marginTop: '9px',
              }}
            />
          </Fragment>
        ) : (
          <div
            className={s.formInputBox}
            onClick={!disabled ? handleInputClick : () => {}}
          >
            <Field
              name={source}
              component={renderField}
              aditionalClass={aditionalClass}
              validate={required ? [validateRequired] : null}
              placeholder={placeholder}
              onChange={(e: any) => setValue(e.target.value)}
              disabled={disabled}
            />
            <img
              src={avatarImage}
              className={s.avatar}
              width="18"
              height="18"
            />
            <div className={s.chevronBox}>
              {!disabled && <ChevronDown size="18" />}
            </div>
          </div>
        )}
      </div>
      {pendingChange && <div></div>}
      {pendingChange && (
        <div>
          <Text className={s.changedFromText}>Changed from:</Text>
          <Text className={s.pendingChangeText}>{initialValue}</Text>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  avatarsHash: state.dropdowns.avatarsHash,
  guildPriorities: state.dropdowns.guildPriorities,
  form: state.form[formName],
  mode: state.requestModal.mode,
});

export default connect(mapStateToProps)(FormRequestorDropdown);
