import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './FilterMenu.scss';
import { Button, Text, Checkbox, Input, TextButton } from "@wix/design-system";
import Search from 'wix-ui-icons-common/Search';
import { ChevronRight, ArrowLeftSmall } from 'wix-ui-icons-common';
import {
  setDataFilters,
  clearDataFilter,
  setCurrentView,
  setHcdFilters,
} from '../../../redux/actions/filterAction';
import { statusNames, tableModes } from '../../../../../Client/config';

const FilterMenu = (props: any) => {
  const {
    group,
    dropdowns,
    setMenuOpen,
    dataFilters,
    setDataFilters,
    clearDataFilter,
    setCurrentView,
    dropdownsFilterOptions,
    employeesData,
    setHcdFilters,
    hcdFilters,
    tableMode,
  } = props;
  const [filterValue, setFilterValue] = useState<any>(null);
  const [columnView, setColumnView] = useState<any>(null);
  const [activeFilters, setActiveFilters] = useState<any>(
    tableMode === tableModes.teamRequests
      ? dataFilters && dataFilters[group.title]
      : hcdFilters && hcdFilters[group.title],
  );

  const getGroupOptions = (group: any) => {
    if (columnView) {
      return getColumnOptions(columnView);
    }
    if (group.columns.length === 1) {
      const column = group.columns[0];
      setColumnView(column);
    }
    return group.columns.filter(
      (col: any) =>
        col.type !== 'datepicker' &&
        col.type !== 'inputText' &&
        col.source !== 'businessJustification' &&
        !col.filtered,
    );
  };
  const getColumnOptions = (column: any) => {
    const validDropdowns = dropdownsFilterOptions || dropdowns;
    const options = [];
    if (column.type === 'checkbox') {
      return [
        { value: 'Y', label: 'Y' },
        { value: 'N', label: 'N' },
      ];
    }
    switch (column.source) {
      case 'subTeam':
        for (const company of validDropdowns.company.data) {
          options.push(...company.subs);
        }
        return options;
      case 'subGuild':
        for (const guild of validDropdowns.guild.data) {
          options.push(...guild.subs);
        }
        return options;
      case 'name':
        return employeesData;
      default:
        return validDropdowns[column.source].data;
    }
  };
  const checkActiveColumn = (source: any) => {
    return activeFilters ? !!activeFilters[source]?.length : false;
  };
  const checkActiveValue = (value: any) => {
    return activeFilters
      ? !!activeFilters[columnView.source]?.includes(value)
      : false;
  };
  const handleFilter = (option: any) => {
    const current = checkActiveValue(option.value);
    current ? disableFilter(option) : activateFilter(option);
  };
  const activateFilter = (option: any) => {
    const newActiveFilters = activeFilters ? { ...activeFilters } : {};
    if (!newActiveFilters[columnView.source]) {
      newActiveFilters[columnView.source] = [];
    }
    newActiveFilters[columnView.source].push(option.value);
    setActiveFilters(newActiveFilters);
  };
  const disableFilter = (option: any) => {
    const newActiveFilters = activeFilters ? { ...activeFilters } : {};
    const index = newActiveFilters[columnView.source].indexOf(option.value);
    if (index > -1) {
      newActiveFilters[columnView.source].splice(index, 1);
    }
    if (!newActiveFilters[columnView.source].length) {
      delete newActiveFilters[columnView.source];
    }
    setActiveFilters(newActiveFilters);
  };

  const handleApplyFilter = () => {
    if (tableMode === tableModes.teamRequests) {
      const newDataFilters = { ...dataFilters };
      const emptyFilter = Object.keys(activeFilters)?.length === 0;
      newDataFilters[group.title] = emptyFilter ? null : activeFilters;
      setDataFilters(newDataFilters);
    } else {
      const newDataFilters = { ...hcdFilters };
      const emptyFilter = Object.keys(activeFilters)?.length === 0;
      newDataFilters[group.title] = emptyFilter ? null : activeFilters;
      setHcdFilters(newDataFilters);
    }
    setCurrentView(null);
    setMenuOpen(false);
  };

  const renderOptions = () => {
    const options = getGroupOptions(group)
      .filter((option: any) => {
        if (!filterValue || !filterValue.length) {
          return true;
        }
        const searchValue =
          group.title === 'Status'
            ? statusNames[option.value]?.label
            : option.value;
        if (!searchValue) {
          return false;
        }
        return searchValue.toLowerCase().includes(filterValue.toLowerCase());
      })
      .slice(0, 20)
      .map((option: any, i: any) => {
        return columnView ? (
          <div key={i} className={s.option}>
            <Checkbox
              checked={checkActiveValue(option.value)}
              className={
                checkActiveValue(option.value) ? s.checkboxChecked : s.checkbox
              }
              onChange={() => handleFilter(option)}
            />
            <Text size="tiny">{option.label}</Text>
          </div>
        ) : (
          <div
            key={i}
            className={s.groupOption}
            onClick={() => {
              setFilterValue(null);
              setColumnView(option);
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={checkActiveColumn(option.source)}
                className={
                  checkActiveColumn(option.source)
                    ? s.checkboxChecked
                    : s.checkbox
                }
              />
              <Text size="tiny">{option.title}</Text>
            </div>
            <ChevronRight size="18px" />
          </div>
        );
      });
    return options;
  };

  const selectAllFilters = () => {
    const allFilters = {} as any;
    const validDropdowns = dropdownsFilterOptions || dropdowns;
    group.columns
      .filter((col: any) => !col.filtered)
      .map((col: any) => col.source)
      .forEach((name: any) => {
        if (validDropdowns[name]) {
          allFilters[name] = validDropdowns[name].data.map(
            (item: any) => item.label,
          );
        }
        if (name === 'subTeam') {
          const temp: any = [];
          validDropdowns.company.data.forEach((item: any) =>
            temp.push(...item.subs.map((sub: any) => sub.label)),
          );
          allFilters[name] = temp;
        }
        if (name === 'subGuild') {
          const temp: any = [];
          validDropdowns.guild.data.forEach((item: any) =>
            temp.push(...item.subs.map((sub: any) => sub.label)),
          );
          allFilters[name] = temp;
        }
        if (name === 'status') {
          allFilters[name] = Object.keys(statusNames).map((name: string) =>
            Number(name),
          );
        }
      });

    setActiveFilters(allFilters);
  };

  const selectColumnViewFilters = () => {
    const allFilters = {} as any;
    const name = columnView.source;
    const validDropdowns = dropdownsFilterOptions || dropdowns;
    if (validDropdowns[name]) {
      allFilters[name] = validDropdowns[name].data.map(
        (item: any) => item.label,
      );
    }
    if (name === 'subTeam') {
      const temp: any = [];
      validDropdowns.company.data.forEach((item: any) =>
        temp.push(...item.subs.map((sub: any) => sub.label)),
      );
      allFilters[name] = temp;
    }
    if (name === 'subGuild') {
      const temp: any = [];
      validDropdowns.guild.data.forEach((item: any) =>
        temp.push(...item.subs.map((sub: any) => sub.label)),
      );
      allFilters[name] = temp;
    }
    if (name === 'status') {
      allFilters[name] = Object.keys(statusNames).map((name: string) =>
        Number(name),
      );
    }
    if (name === 'name') {
      allFilters[name] = employeesData.map((item: any) => item.label);
    }
    setActiveFilters(allFilters);
  };

  const selectFilters = () =>
    columnView ? selectColumnViewFilters() : selectAllFilters();

  const clearFilters = () => {
    if (tableMode === tableModes.myEmployees) {
      setHcdFilters(null);
    }
    if (columnView) {
      const temp = { ...activeFilters };
      delete temp[columnView.source];
      setActiveFilters(temp);
    } else {
      setActiveFilters({});
    }
  };

  return (
    <div className={s.main} onClick={(event) => event.stopPropagation()}>
      <div
        className={s.header}
        onClick={() => {
          setFilterValue(null);
          setColumnView(null);
        }}
      >
        <div className={s.headerTextBox}>
          {columnView && group.columns.length > 1 ? (
            <ArrowLeftSmall className={s.arrowIcon} size="16px" />
          ) : null}
          <div className={s.headerText}>Filter By</div>
        </div>
        {activeFilters && Object.keys(activeFilters).length > 0 ? (
          <TextButton
            className={s.clearAllButton}
            onClick={() => {
              clearDataFilter(group.title);
              setFilterValue(null);
              setColumnView(null);
              clearFilters();
            }}
          >
            Clear All
          </TextButton>
        ) : (
          <TextButton className={s.clearAllButton} onClick={selectFilters}>
            Select All
          </TextButton>
        )}
      </div>

      <div className={s.options}>
        {columnView ? (
          <div className={s.search}>
            <Input
              prefix={<Search className={s.searchIcon} size="16px" />}
              onChange={(event: any) => setFilterValue(event.target.value)}
              placeholder="Search values"
              className={s.searchInput}
              size="small"
            />
          </div>
        ) : null}
        <div className={columnView ? s.optionsList : s.optionsListFull}>
          {renderOptions()}
        </div>
      </div>
      <div className={s.footer}>
        <Button
          size="tiny"
          className={s.cancelButton}
          onClick={() => setMenuOpen(false)}
        >
          Cancel
        </Button>
        <Button
          size="tiny"
          className={s.filterButton}
          onClick={handleApplyFilter}
        >
          Filter
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  dropdowns: state.dropdowns.dropdowns,
  dropdownsFilterOptions: state.dropdowns.dropdownsFilterOptions,
  dataFilters: state.filter.dataFilters,
  hcdFilters: state.filter.hcdFilters,
  employeesData: state.employeesTable.employeesData,
  tableMode: state.table.tableMode,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDataFilters: (dataFilters: any) => dispatch(setDataFilters(dataFilters)),
  setHcdFilters: (hcdFilters: any) => dispatch(setHcdFilters(hcdFilters)),
  clearDataFilter: (filterName: any) => dispatch(clearDataFilter(filterName)),
  setCurrentView: (view: any) => dispatch(setCurrentView(view)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu);
