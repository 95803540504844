import React, { useState, useEffect } from 'react';
import s from './InputText.scss';
import { connect } from 'react-redux';
import { setRef, setChangedSeats } from '../../../redux/actions/seatsActions';
import CircleSmallFilled from 'wix-ui-icons-common/CircleSmallFilled';
import { v4 as uuidv4 } from 'uuid';
import { Input } from "@wix/design-system";
import { showTooltip } from '../../../redux/actions/tooltipActions';
import { getPendingChangeTooltip } from '../../../../../Client/utils';
import { MAX_CHARS } from '../../../../../Client/config';

const InputText = (props: any) => {
  const {
    value,
    data,
    col,
    currentCellRef,
    changedSeats,
    setRef,
    setChangedSeats,
    showTooltip,
  } = props;

  const maxChars: number = col.maxChars || MAX_CHARS;

  const [focus, setFocus] = useState(false);
  const [edited, setEdited] = useState(
    changedSeats[data._id] && changedSeats[data._id][col.source],
  );
  const [myId, setMyId] = useState(uuidv4());
  const [debounceTimer, setDebounceTimer] = useState<any>(null);
  const [pendingChange, setPendingChange] = useState<any>(null);

  useEffect(() => {
    setPendingChange(
      data.pendingChanges && data.pendingChanges[col.source]
        ? data.pendingChanges[col.source]
        : null,
    );
  }, [data, changedSeats]);

  const renderTooltip = (e: any) => {
    const display =
      edited ||
      getPendingChangeTooltip(pendingChange, value, col.source) ||
      value;
    if (!display || display.length <= maxChars) {
      return;
    }
    const rect = e.target.getBoundingClientRect();
    showTooltip(display, rect.x, rect.y - 40);
  };

  useEffect(() => {
    setEdited(changedSeats[data._id] && changedSeats[data._id][col.source]);
  }, [data, changedSeats]);

  const handleCellClick = () => {
    document.addEventListener('keydown', escFunction, false);
    setRef(myId);
    setFocus(true);
  };

  const escFunction = (event: any) => {
    if (event.keyCode === 27) {
      setFocus(false);
    }
  };
  const handleChange = (value: any) => {
    const newChangedSeats = {
      ...changedSeats,
      [data._id]: { ...changedSeats[data._id], [col.source]: value },
    };
    setChangedSeats(newChangedSeats);
  };

  const debounceChange = (value: any) => {
    setEdited(value);
    if (debounceTimer) {
      clearTimeout(debounceTimer);
      setDebounceTimer(null);
    }
    setDebounceTimer(setTimeout(() => handleChange(value), 700));
  };

  return (
    <div>
      {focus && currentCellRef === myId ? (
        <div
          style={{ position: 'relative' }}
          onClick={(event: any) => event.stopPropagation()}
        >
          <Input
            className={s.input}
            value={edited || pendingChange || value}
            onChange={(event) => debounceChange(event.target.value)}
            size="small"
          />
        </div>
      ) : (
        <div className={s.main} onClick={handleCellClick}>
          {edited ? (
            <CircleSmallFilled className={s.edited} size="18px" />
          ) : null}
          <div
            onMouseEnter={renderTooltip}
            className={pendingChange && !edited ? s.pendingChange : ''}
          >
            {edited
              ? edited && edited.length > maxChars
                ? `${edited.substring(0, maxChars - 3)}...`
                : edited
              : pendingChange
              ? pendingChange.length > maxChars
                ? `${pendingChange.substring(0, maxChars - 3)}...`
                : pendingChange
              : value && value.length > maxChars
              ? `${value.substring(0, maxChars - 3)}...`
              : value}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentCellRef: state.seats.currentCellRef,
  changedSeats: state.seats.changedSeats,
  dropdowns: state.dropdowns.dropdowns,
  avatarsHash: state.dropdowns.avatarsHash,
});

const mapDispatchToProps = (dispatch: any) => ({
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  setChangedSeats: (changedSeats: any) =>
    dispatch(setChangedSeats(changedSeats)),
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputText);
