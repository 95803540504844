import React from 'react';
import WixIcon from './WixIcon';
import s from '../Login.scss';

const LoginTitle = () => (
  <h1 className={s.title}>
    <WixIcon width="50" height="19" />
    Seats
  </h1>
);

export default LoginTitle;
