import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './ColumnGroup.scss';
import { Text, Tooltip } from "@wix/design-system";
import { toggleGroup } from '../../../redux/actions/filterAction';
import ChevronLeft from 'wix-ui-icons-common/ChevronLeft';
import ChevronRight from 'wix-ui-icons-common/ChevronRight';
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import CircleSmallFilled from 'wix-ui-icons-common/CircleSmallFilled';
import FilterMenu from '../FilterMenu/FilterMenu';
import { v4 as uuidv4 } from 'uuid';
import { setRef } from '../../../redux/actions/seatsActions';

const ColumnGroup = (props: any) => {
  const {
    size,
    group,
    toggleGroup,
    columnGroups,
    setRef,
    currentCellRef,
    dataFilters,
  } = props;

  const [filtered, setFiltered] = useState(
    dataFilters && dataFilters[group.title],
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const [myId, setMyId] = useState(uuidv4());

  useEffect(() => {
    setFiltered(dataFilters && dataFilters[group.title]);
  }, [dataFilters]);

  const toggleMenu = () => {
    if (menuOpen && currentCellRef !== myId) {
      setRef(myId);
      return;
    }
    setMenuOpen(!menuOpen);
    setRef(myId);
  };

  const handleCollapse = (group: any) => {
    const collapsed = columnGroups.filter(
      (groupObj: any) =>
        !groupObj.formOnly && groupObj.display && !groupObj.filtered,
    );
    if (collapsed.length > 1) {
      toggleGroup(group.title);
    }
  };

  return group.display ? (
    <div className={s.columnGroup} style={{ minWidth: size, maxWidth: size }}>
      <ChevronLeft
        className={s.collapseChevron}
        onClick={() => handleCollapse(group)}
      />
      <div className={s.columnGroupTitle}>
        {filtered ? (
          <CircleSmallFilled className={s.filtered} size="18px" />
        ) : (
          <div className={s.iconPlaceHolder}></div>
        )}
        <Text
          className={filtered ? s.columnGroupTextFiltered : s.columnGroupText}
          onClick={
            group.title === 'Actions' || group.title === 'Seats No.'
              ? () => {}
              : toggleMenu
          }
        >
          {group.title}
        </Text>
        {group.title === 'Actions' ||
        group.title === 'Seats No.' ||
        group.title === 'Team Status' ? (
          <ChevronDown className={s.hidden} />
        ) : (
          <ChevronDown className={s.chevronDown} onClick={toggleMenu} />
        )}
        {menuOpen && currentCellRef === myId ? (
          <div className={s.filterMenu}>
            <FilterMenu
              group={group}
              setFiltered={setFiltered}
              setMenuOpen={setMenuOpen}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div className={s.groupDivider}>
      <Tooltip appendTo="window" content={group.title}>
        <ChevronRight
          className={s.chevronRight}
          onClick={() => toggleGroup(group.title)}
        />
      </Tooltip>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  columnGroups: state.filter.columnGroups,
  currentCellRef: state.seats.currentCellRef,
  dataFilters: state.filter.dataFilters,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleGroup: (title: string) => dispatch(toggleGroup(title)),
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnGroup);
