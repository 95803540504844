import React from 'react';
import { connect } from 'react-redux';
import s from './FormItem.scss';
import CustomMultiSelect from '../../../CustomMultiSelect/CustomMultiSelect';
import CustomCheckBox from '../CustomCheckBox/CustomCheckBox';
import CustomTextArea from '../CustomTextArea/CustomTextArea';
import FormAutoFillDropdown from '../FormAutoFillDropdown/FormAutoFillDropdown';
import FormRequestorDropdown from '../FormRequestorDropdown/FormRequestorDropdown';
import CustomInput from '../CustomInput/CustomInput';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import FormStatus from '../FormStatus/FormStatus';
import FormReplacement from '../FormReplacement/FormReplacement';
import {
  setRef,
  getValue,
  formName,
  showModalTooltip,
  hideModalTooltip,
  setCurrentDropdownValue,
} from '../../../redux/actions/requestModalActions';
import { formValueSelector, change } from 'redux-form';

const Empty = (props: any) => <div />;

const FormItem = (props: any) => {
  const {
    change,
    hidden,
    source,
    parent,
    getValue,
    rowStart,
    rowEnd,
    colStart,
    colEnd,
    type,
    formTooltip,
    showModalTooltip,
    hideModalTooltip,
  } = props;

  const parentValue = getValue(parent);
  const initValue = getValue(source);

  const typeElementMap: any = {
    checkbox: CustomCheckBox,
    inputText: CustomInput,
    textArea: CustomTextArea,
    dropdown: FormAutoFillDropdown,
    requester: FormRequestorDropdown,
    multiSelect: CustomMultiSelect,
    datepicker: CustomDatePicker,
    status: FormStatus,
    replacement: FormReplacement,
    empty: Empty,
  };

  const renderByType = () => {
    const Component = typeElementMap[type];

    return (
      Component && (
        <Component {...props} parentValue={parentValue} initValue={initValue} />
      )
    );
  };

  if (hidden) {
    change(source, null);
    return null;
  }

  const renderTooltip = (e: any) => {
    if (!formTooltip) {
      return;
    }
    const rect = e.target.getBoundingClientRect();
    showModalTooltip(formTooltip, rect.x, rect.y - 40);
    // setTimeout(() => {
    //   hideModalTooltip();
    // }, 2000);
  };

  return (
    <div
      id={source}
      className={s.formInputContainer}
      style={{
        gridRow: `${rowStart || 'auto'}/${rowEnd || 'auto'}`,
        gridColumn: `${colStart || 'auto'}/${colEnd || 'auto'}`,
      }}
      onMouseEnter={renderTooltip}
      onMouseLeave={() => hideModalTooltip()}
    >
      {renderByType()}
    </div>
  );
};

const selector = formValueSelector(formName);

const isHidden = (state: any, ownProps: any) => {
  const { hidder } = ownProps;
  if (!hidder) {
    return false;
  }

  const { source, showValues } = hidder;

  for (const sourceName of source) {
    const hidderValue = selector(state, sourceName);
    if (hidderValue && showValues.includes(hidderValue)) {
      return false;
    }
  }
  return true;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  request: state.requestModal.request,
  currentInputRef: state.requestModal.currentInputRef,
  currentDropdownValue: state.requestModal.currentDropdownValue,
  dropdowns: state.dropdowns.dropdowns,
  hidden: isHidden(state, ownProps),
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  setCurrentDropdownValue: (value: any) =>
    dispatch(setCurrentDropdownValue(value)),
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  getValue: (source: string) => dispatch(getValue(source)),
  change: (field: string, value: string, form = formName) =>
    dispatch(change(form, field, value)),
  showModalTooltip: (text: string, x: string, y: string) =>
    dispatch(showModalTooltip(text, x, y)),
  hideModalTooltip: () => dispatch(hideModalTooltip()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormItem);
