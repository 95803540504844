import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './ContactUsModal.scss';
import {
  Modal,
  Text,
  Button,
  CloseButton,
  Dropdown,
  InputArea,
  FilePicker,
} from "@wix/design-system";
import {
  setShowContactUsModal,
  sendFeedback,
} from '../redux/actions/modalsActions';

const title = 'Give Feedback';
const cancelButtonText = 'Cancel';
const confirmButtonText = 'Send';
const description =
  'Got a great idea or something is not right? Drop us a line and help us make Wix Seats better for everyone.';
const topicHeaderText = "What's on your mind?";
const subjectHeaderText = 'I want to talk about';
const topicPlaceholder = 'Choose an option';
const descriptionPlaceholder = 'Tell us more...';
const topicDropdownOptions = [
  { id: 0, value: 'I have general feedback' },
  { id: 1, value: 'I found a bug' },
  { id: 2, value: 'I found wrong info' },
  { id: 3, value: 'I have an idea' },
];
const screenshotMaxSize = 500000;
const screenshotMainLabel = 'Add screenshot';
const screenshotSubLabel = 'No file chosen (Max size 5MB)';
const screenshotSupportedFormats = '.png, .jpg';
const screenshotUploadErrorMsg = 'Failed to upload. File is too large.';

const ContactUsModal = (props: any) => {
  const { showContactUsModal, setShowContactUsModal, sendFeedback } = props;

  const escFunction = (event: any) => {
    if (event.keyCode === 27) {
      event.stopPropagation();
      onModalClose();
    }
  };

  const registerListeners = () => {
    document.addEventListener('keydown', escFunction, false);
  };

  const [selectedTopic, setSelectedTopic] = useState<any>(null);
  const [descriptionText, setDescriptionText] = useState<any>(null);
  const [screenshot, setScreenshot] = useState<any>(null);
  const [isSubmitButtonActive, setIsSubmitButtonActive] = useState<any>(false);

  useEffect(() => {
    setIsSubmitButtonActive(selectedTopic && descriptionText);
  }, [selectedTopic, descriptionText]);

  const onModalClose = () => {
    setSelectedTopic(null);
    setDescriptionText(null);
    setScreenshot(null);
    setShowContactUsModal(false);
    document.removeEventListener('keydown', escFunction, false);
  };

  const onConfirmClicked = () => {
    const feedbackData: any = {
      topic: selectedTopic,
      description: descriptionText,
    };
    if (screenshot) {
      feedbackData.file = screenshot;
    }
    sendFeedback(feedbackData);
    onModalClose();
  };

  return (
    <Modal isOpen={showContactUsModal || false} onAfterOpen={registerListeners}>
      <div className={s.main}>
        <div>
          <div className={s.top}>
            <Text className={s.title}>{title}</Text>
            <div></div>
            <CloseButton
              size="small"
              className={s.closeButton}
              onClick={onModalClose}
            ></CloseButton>
          </div>
        </div>
        <div className={s.body}>
          <Text className={s.message}>{description}</Text>
          <div className={s.buffer}></div>
          <Text className={s.message}>{topicHeaderText}</Text>
          <Dropdown
            onSelect={(option: any) => setSelectedTopic(option.value)}
            placeholder={topicPlaceholder}
            options={topicDropdownOptions}
          />
          <div className={s.buffer}></div>
          <Text className={s.message}>{subjectHeaderText}</Text>
          <InputArea
            onChange={(event) => setDescriptionText(event.target.value.trim())}
            placeholder={descriptionPlaceholder}
            rows={6}
          />
          <div className={s.buffer}></div>
          <FilePicker
            maxSize={screenshotMaxSize}
            mainLabel={screenshotMainLabel}
            subLabel={screenshotSubLabel}
            supportedFormats={screenshotSupportedFormats}
            errorMessage={screenshotUploadErrorMsg}
            onChange={(screenshot) => setScreenshot(screenshot)}
          />
        </div>
        <div className={s.bottom}>
          <div className={s.left}></div>
          <div className={s.right}>
            <Button className={s.cancelButton} onClick={onModalClose}>
              {cancelButtonText || 'Cancel'}
            </Button>
            {isSubmitButtonActive ? (
              <Button className={s.submitButton} onClick={onConfirmClicked}>
                {confirmButtonText}
              </Button>
            ) : (
              <Button
                className={`${s.submitButton} ${s.disabledButton}`}
                disabled
              >
                {confirmButtonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  showContactUsModal: state.modals.showContactUsModal,
});

const mapDispatchToProps = (dispatch: any) => ({
  setShowContactUsModal: (isShow: any) =>
    dispatch(setShowContactUsModal(isShow)),
  sendFeedback: (feedbackData: any) => dispatch(sendFeedback(feedbackData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsModal);
