import { httpClient } from '../../Client/httpClient';

export async function getSeat(seatId) {
  try {
    if (seatId) {
      const { data } = await httpClient.get(`/seats/seat/${seatId}`);
      return data.seat;
    }
    return;
  } catch (error) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('getSeat failed -origin error-' + error);
  }
}
