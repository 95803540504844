import React, { useState, useEffect } from 'react';
import s from './CustomTextArea.scss';
import { Field } from 'redux-form';
import {
  defaultStatusReasonMessage,
  defaultRejectedPrePendingMessage,
} from '../../../../../Client/config';
import { connect } from 'react-redux';

const renderField = ({
  input,
  placeholder,
  aditionalClass,
  disabled,
  meta: { touched, error, warning },
}: any) => {
  return (
    <div>
      <textarea
        {...input}
        autoComplete="off"
        placeholder={placeholder}
        className={`${s.textArea} ${aditionalClass} ${
          disabled ? s.disabled : ''
        }`}
        disabled={disabled}
      />
      {touched && error && <span>{error}</span>}
    </div>
  );
};

const CustomTextArea = (props: any) => {
  const { source, placeholder, request, change, mode } = props;
  const [disabled, setDisabled] = useState(mode === 'View');
  useEffect(() => {
    if (source === 'statusReason' && request && !request[source]) {
      request[source] =
        request.status === 10
          ? defaultRejectedPrePendingMessage
          : defaultStatusReasonMessage;
      change(source, request[source]);
    }
  }, []);
  return (
    <div className={s.inputAreaContainer}>
      <Field
        component={renderField}
        name={source}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  mode: state.requestModal.mode,
});

export default connect(mapStateToProps)(CustomTextArea);
