import { TOOLTIP } from './constants';

export const showTooltip = (text: string, x: string, y: string) => (
  dispatch: any,
) => {
  dispatch({
    payload: {
      tooltipY: y,
      tooltipX: x,
      tooltipText: text,
    },
    type: TOOLTIP.SHOW_TOOLTIP,
  });
};
export const hideTooltip = () => (dispatch: any) => {
  dispatch({
    type: TOOLTIP.HIDE_TOOLTIP,
  });
};
