import React, { useState, useEffect } from 'react';
import s from './AutofillDropdown.scss';
import { connect } from 'react-redux';
import {
  setRef,
  setChangedSeats,
  onGuildPriorityChange,
} from '../../../redux/actions/seatsActions';
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import CircleSmallFilled from 'wix-ui-icons-common/CircleSmallFilled';
import { Badge, Input } from "@wix/design-system";
import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
import { v4 as uuidv4 } from 'uuid';
import { showTooltip } from '../../../redux/actions/tooltipActions';
import {
  hiddenSubGuilds,
  hiddenTeams,
  isGuildPriorityActive,
  PRE_PENDING_GUILDS_NAMES,
  PRE_PENDING_COMPANIES_NAMES,
  MAX_CHARS,
} from '../../../../../Client/config';
import { getPendingChangeTooltip } from '../../../../../Client/utils';

const AutofllDropdown = (props: any) => {
  const {
    value,
    col,
    data,
    seats,
    showavatar,
    setRef,
    currentCellRef,
    changedSeats,
    setChangedSeats,
    guildPriorities,
    onGuildPriorityChange,
    dropdowns,
    avatarsHash,
    showTooltip,
  } = props;
  const [focus, setFocus] = useState(false);
  const [toggleUp, setToggleUp] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [myId, setMyId] = useState(uuidv4());
  const [edited, setEdited] = useState(
    changedSeats[data._id] && changedSeats[data._id][col.source],
  );
  const [pendingChange, setPendingChange] = useState<any>(null);

  useEffect(() => {
    setPendingChange(
      data.pendingChanges &&
        data.pendingChanges[col.source] &&
        data.pendingChanges[col.source] !== data[col.source]
        ? data.pendingChanges[col.source]
        : null,
    );
  }, [data, changedSeats]);

  const maxChars: number = col.maxChars || MAX_CHARS;

  useEffect(() => {
    setEdited(changedSeats[data._id] && changedSeats[data._id][col.source]);
    setAvatarImage(showavatar ? avatarUrl() : null);
  }, [data, changedSeats, pendingChange]);

  const shouldToggleUp = (event: any) => {
    if (!event) {
      return false;
    }
    const { y } = event.target.getBoundingClientRect();
    const dropdownHeight = 300;

    return Number(y || 0) + dropdownHeight > window.innerHeight;
  };
  const handleCellClick = (event: any) => {
    setToggleUp(shouldToggleUp(event));

    document.addEventListener('keydown', escFunction, false);
    setRef(myId);
    setFocus(true);
  };
  // const calculateGuildPriorities = (newGuildPriority: any) => {
  //   const { guild } = data;
  //   // getting all the active seats of the current seat's guild sorted by guildPriority 1,2,3... (no holes)
  //   const guildSeats = seats
  //     .filter((seat: any) => seat.guild === guild && seat.guildPriority)
  //     .sort(
  //       (a: any, b: any) => Number(a.guildPriority) - Number(b.guildPriority),
  //     );
  //   // finding the index of the current seat with the required guildPriority ()
  //   let newPriorityIndex = guildSeats.findIndex(
  //     (seat: any) => seat.guildPriority === newGuildPriority,
  //   );
  //   if (newGuildPriority > data.guildPriority) {
  //     newPriorityIndex++;
  //   }
  //   // splitting the array to 2 arrays (without the current seat)
  //   const tempArr1 = guildSeats
  //     .slice(0, newPriorityIndex)
  //     .filter((seat: any) => seat._id !== data._id);
  //   const tempArr2 = guildSeats
  //     .slice(newPriorityIndex)
  //     .filter((seat: any) => seat._id !== data._id);
  //   // pushing the current seat at the right index and merging the arrays
  //   tempArr1.push(data);
  //   const newArr = [...tempArr1, ...tempArr2];
  //   const newChangedSeats = {
  //     ...changedSeats,
  //   };
  //   // creating hash of the new order of guild priority based on the index
  //   const guildPriorityHash: any = {};
  //   for (let i = 0; i < newArr.length; i++) {
  //     guildPriorityHash[i + 1] = newArr[i]._id;
  //   }
  //   // creating the changedSeats object based on the new guildPriority hash
  //   for (let i = 0; i < newArr.length; i++) {
  //     const seat = guildSeats[i];
  //     const firstSeatId = guildPriorityHash[i + 1];
  //     newChangedSeats[firstSeatId] = {
  //       wixPriority: seat.wixPriority,
  //       guildPriority: i + 1,
  //     };
  //   }

  //   setChangedSeats(newChangedSeats);
  // };
  const handleOptionSelect = async (opt: any) => {
    const currentValue = edited || pendingChange || value;
    if (opt.value == currentValue || (opt.value == '-' && !currentValue)) {
      closeDropdown();
      return;
    }
    // if (col.source === 'guildPriority') {
    //   calculateGuildPriorities(opt.value);
    //   closeDropdown();
    //   return;
    let newChangedSeats;
    if (!isGuildPriorityActive) {
      newChangedSeats = {
        ...changedSeats,
        [data._id]: { ...changedSeats[data._id], [col.source]: opt.value },
      };
    }
    if (isGuildPriorityActive) {
      if (
        col.source === 'guildPriority' &&
        (opt.value === '-' || (currentValue && currentValue !== '-'))
      ) {
        newChangedSeats = {
          ...changedSeats,
          [data._id]: {
            ...changedSeats[data._id],
            pendingGuildPriority: opt.value,
          },
        };
      } else if (!(col.source === 'subGuild' || col.source === 'site')) {
        newChangedSeats = {
          ...changedSeats,
          [data._id]: { ...changedSeats[data._id], [col.source]: opt.value },
        };
      }
    }
    if (col.source === 'guild') {
      if (isGuildPriorityActive) {
        newChangedSeats = {
          ...newChangedSeats,
          [data._id]: { ...newChangedSeats[data._id], pendingSubGuild: '-' },
        };
      } else {
        newChangedSeats = {
          ...newChangedSeats,
          [data._id]: { ...newChangedSeats[data._id], subGuild: '-' },
        };
      }
    }
    if (isGuildPriorityActive) {
      if (col.source === 'subGuild') {
        newChangedSeats = {
          ...changedSeats,
          [data._id]: { ...changedSeats[data._id], pendingSubGuild: opt.value },
        };
      }
      if (col.source === 'site') {
        newChangedSeats = {
          ...changedSeats,
          [data._id]: { ...changedSeats[data._id], pendingSite: opt.value },
        };
      }
      if (
        (col.source === 'guild' ||
          col.source === 'subGuild' ||
          col.source === 'site') &&
        (newChangedSeats[data._id].guildPriority || data.guildPriority)
      ) {
        newChangedSeats = {
          ...newChangedSeats,
          [data._id]: {
            ...newChangedSeats[data._id],
            pendingGuildPriority: '-',
          },
        };
      }
    }
    if (col.source === 'company') {
      newChangedSeats = {
        ...newChangedSeats,
        [data._id]: { ...newChangedSeats[data._id], subTeam: '-' },
      };
    }
    if (col.source === 'company' || col.source === 'guild') {
      const isStatusChanged =
        newChangedSeats &&
        newChangedSeats[data._id] &&
        newChangedSeats[data._id].status;
      const currentStatus = isStatusChanged
        ? newChangedSeats[data._id].status
        : data.status;
      const isGuildChanged =
        newChangedSeats &&
        newChangedSeats[data._id] &&
        newChangedSeats[data._id].guild;
      const currentGuild = isGuildChanged
        ? newChangedSeats[data._id].guild
        : data.guild;
      const isCompanyChanged =
        newChangedSeats &&
        newChangedSeats[data._id] &&
        newChangedSeats[data._id].company;
      const currentCompany = isCompanyChanged
        ? newChangedSeats[data._id].company
        : data.company;
      const isPrePendingApproved =
        newChangedSeats &&
        newChangedSeats[data._id] &&
        newChangedSeats[data._id].isPrePendingApproved
          ? newChangedSeats[data._id].isPrePendingApproved
          : data.isPrePendingApproved;
      if (
        !PRE_PENDING_GUILDS_NAMES.includes(currentGuild) &&
        !PRE_PENDING_COMPANIES_NAMES.includes(currentCompany)
      ) {
        if (currentStatus === 10) {
          newChangedSeats = {
            ...newChangedSeats,
            [data._id]: { ...newChangedSeats[data._id], status: 1 },
          };
        }
        if (currentStatus === 11) {
          newChangedSeats = {
            ...newChangedSeats,
            [data._id]: { ...newChangedSeats[data._id], status: 3 },
          };
        }
      }
      if (
        (PRE_PENDING_COMPANIES_NAMES.includes(currentCompany) ||
          PRE_PENDING_GUILDS_NAMES.includes(currentGuild)) &&
        !isPrePendingApproved &&
        currentStatus === 1
      ) {
        newChangedSeats = {
          ...newChangedSeats,
          [data._id]: { ...newChangedSeats[data._id], status: 10 },
        };
      }
    }
    if (col.source === 'requester') {
      newChangedSeats = {
        ...newChangedSeats,
        [data._id]: {
          ...newChangedSeats[data._id],
          requesterEmail: opt.email || opt.oracleMail,
        },
      };
    }
    if (
      isGuildPriorityActive &&
      (col.source === 'guildPriority' ||
        col.source === 'guild' ||
        col.source === 'subGuild' ||
        col.source === 'site')
    ) {
      onGuildPriorityChange(data, newChangedSeats);
      closeDropdown();
      return;
    }
    setChangedSeats(newChangedSeats);
    setEdited(opt.value);
    closeDropdown();
  };
  const escFunction = (event: any) => {
    if (event.keyCode === 27) {
      closeDropdown();
    }
  };
  const closeDropdown = () => {
    setFocus(false);
    document.removeEventListener('keydown', escFunction, false);
  };
  const avatarUrl = () => {
    if (avatarsHash) {
      return (
        avatarsHash[edited || pendingChange || value] ||
        'https://i.stack.imgur.com/l60Hf.png'
      );
    }
    return 'https://i.stack.imgur.com/l60Hf.png';
  };
  const [avatarImage, setAvatarImage] = useState(
    showavatar ? avatarUrl() : null,
  );

  const getDropdownOptions = (col: any, data: any, changed?: any) => {
    if (col.source === 'subGuild') {
      const guild = dropdowns.guild.data.find((guildObj: any) => {
        const pendingGuild = data.pendingChanges?.guild;
        const value = changed?.guild || pendingGuild || data.guild;
        return guildObj.value === value;
      });
      if (!guild) {
        return [];
      }
      const subGuilds = guild.subs.filter(
        (subGuild: any) => !hiddenSubGuilds.includes(subGuild.externalId),
      );
      return subGuilds;
    }
    if (col.source === 'subTeam') {
      const company = dropdowns.company.data.find((compObj: any) => {
        const pendingCompany = data.pendingChanges?.company;
        const value = changed?.company || pendingCompany || data.company;
        return compObj.value === value;
      });
      if (!company) {
        return [];
      }
      const teams = company.subs.filter(
        (subGuild: any) => !hiddenTeams.includes(subGuild.externalId),
      );
      return teams;
    }
    if (isGuildPriorityActive) {
      if (col.source === 'guildPriority') {
        const subGuild = changed?.subGuild || data.subGuild;
        const site = changed?.site || data.site;
        if (!subGuild || subGuild === '-' || !site || site === '-') {
          return [];
        }
        if (guildPriorities[subGuild] && guildPriorities[subGuild][site]) {
          const currentValue = edited || value;
          if (currentValue === '-' || !currentValue) {
            return [
              ...guildPriorities[subGuild][site],
              {
                label: guildPriorities[subGuild][site].length,
                value: guildPriorities[subGuild][site].length,
              },
            ];
          }
          return guildPriorities[subGuild][site];
        }
        return [
          { label: '-', value: '-' },
          { label: 1, value: 1 },
        ];
      }
      if (col.source === 'guildPriority') {
        const { guild, guildPriority } = data;
        if (!guildPriority) {
          return [];
        }
        const guildSeats = seats.filter(
          (seat: any) => seat.guild === guild && seat.guildPriority,
        );
        const dropdownOptions = guildSeats.map((seat: any) => {
          return { label: seat.guildPriority, value: seat.guildPriority };
        });
        return dropdownOptions;
      }
    }
    if (props.col.title === "Priority Status") {
      return dropdowns[col.source].data.map((item: any) => {
        return {
          label: (
            <Badge size="small" skin={item.skin} onClick={() => { }}>
              {item.label}
            </Badge>
          ),
          value: item.value
        };
      });
    }
    return dropdowns[col.source].data;
  };

  const renderTooltip = (e: any) => {
    const display =
      edited ||
      getPendingChangeTooltip(pendingChange, value, col.source) ||
      value;
    if (!display || display.length <= maxChars) {
      return;
    }
    const rect = e.target.getBoundingClientRect();
    showTooltip(display, rect.x, rect.y - 40);
  };

  const getVaule = () => {
    if (props.col.title === 'Priority Status') {
      const text = edited || pendingChange || value || '-'
      const priorityObj = dropdowns[col.source].data.find((priority: any) => priority.value === text);

      return (<Badge
        size="small"
        skin={priorityObj?.skin}
        onClick={handleCellClick}
      >
        {text}
      </Badge>)
    }
    return edited
      ? edited.length > maxChars
        ? `${edited.substring(0, maxChars - 3)}...`
        : edited
      : pendingChange
        ? pendingChange.length > maxChars
          ? `${pendingChange.substring(0, maxChars - 3)}...`
          : pendingChange
        : value
          ? value.length > maxChars
            ? `${value.substring(0, maxChars - 3)}...`
            : value
          : '-'
  }
  return (
    <div>
      {focus && currentCellRef === myId ? (
        <div
          style={{ position: 'relative' }}
          onClick={(event: any) => event.stopPropagation()}
        >
          <Input
            className={s.input}
            value={filterValue}
            onChange={(event) => setFilterValue(event.target.value)}
            size="large"
          />
          <CustomDropdown
            title={`Select ${col.title}`}
            options={getDropdownOptions(col, data, changedSeats[data._id])}
            onSelect={handleOptionSelect}
            style={{
              position: 'absolute',
              zIndex: 1,
              top: toggleUp && '-270px',
            }}
            filter={filterValue}
          />
        </div>
      ) : (
        <div className={s.main} onClick={handleCellClick}>
          {edited ? (
            <CircleSmallFilled className={s.edited} size="18px" />
          ) : null}
          {avatarImage && (
            <img
              className={s.avatar}
              src={avatarImage}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = 'https://i.stack.imgur.com/l60Hf.png';
              }}
              width="20"
              height="20"
            ></img>
          )}
          <div
            onMouseEnter={renderTooltip}
            className={pendingChange && !edited ? s.pendingChange : ''}
          >
            {getVaule()}
          </div>
          <ChevronDown className={s.chevronDown} size="18px" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentCellRef: state.seats.currentCellRef,
  changedSeats: state.seats.changedSeats,
  dropdowns: state.dropdowns.dropdowns,
  avatarsHash: state.dropdowns.avatarsHash,
  guildPriorities: state.dropdowns.guildPriorities,
  seats: state.seats.seats,
});
const mapDispatchToProps = (dispatch: any) => ({
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  setChangedSeats: (changedSeats: any) =>
    dispatch(setChangedSeats(changedSeats)),
  onGuildPriorityChange: (data: any, newChangedSeats: any) =>
    dispatch(onGuildPriorityChange(data, newChangedSeats, null, null)),
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutofllDropdown);
