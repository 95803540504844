import { TABLE } from '../actions/constants';
import { tableModes } from '../../../../Client/config';

const initState: any = {
  tableMode: tableModes.teamRequests,
  refreshSeatsTable: false,
  showSeatsTableLoader: true,
};

export default (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case TABLE.TABLE_MODE:
      return { ...state, tableMode: payload };
    case TABLE.REFRESH_SEATS_TABLE:
      return { ...state, refreshSeatsTable: payload };
    case TABLE.SHOW_SEATS_TABLE_LOADER:
      return { ...state, showSeatsTableLoader: payload };
    default:
      return state;
  }
};
