import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Badge, Text } from "@wix/design-system";
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
import { Field } from 'redux-form';
import s from './FormAutoFillDropdown.scss';
import { formName } from '../../../redux/actions/requestModalActions';
import {
  hiddenSubGuilds,
  hiddenTeams,
  isGuildPriorityActive,
  PRE_PENDING_GUILDS_NAMES,
  PRE_PENDING_COMPANIES_NAMES,
} from '../../../../../Client/config';

const validateRequired = (value: string) => (value ? undefined : 'Required');

const renderField = ({
  input,
  placeholder,
  aditionalClass,
  disabled,
  meta: { touched, error, warning },
}: any) => {
  return (
    <input
      {...input}
      autoComplete="off"
      className={`${s.formInput} ${disabled ? s.disabled : ''} ${
        aditionalClass ? aditionalClass : ''
      } ${touched && error ? s.errorInput : ''}`}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

const FormAutoFillDropdown = (props: any) => {
  const {
    title,
    placeholder,
    currentInputRef,
    aditionalClass,
    guildPriorities,
    setRef,
    currentDropdownValue,
    setCurrentDropdownValue,
    source,
    form,
    dropdownName,
    dropdowns,
    parent,
    change,
    initValue,
    required,
    getValue,
    parentValue,
    request,
    mode,
  } = props;

  const [focus, setFocus] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const [value, setValue] = useState(initValue || '');
  const [initialValue, setInitialValue] = useState(initValue || '');
  const [selected, setSelected] = useState(false);
  const [myId, setMyId] = useState(uuidv4());
  const [options, setOptions] = useState([]);
  const [pendingChange, setPendingChange] = useState(null);
  const [disabled, setDisabled] = useState(mode === 'View');
  const [editable, setEditable] = useState(
    !(
      source === 'guildPriority' &&
      form &&
      form.values &&
      form.values.status === 6
    ),
  );
  const isPriorityPlanning = props.title === "Priority Status";

  useEffect(() => {}, [value]);

  useEffect(() => {
    if (request?.pendingChanges && request.pendingChanges[source]) {
      setPendingChange(request.pendingChanges[source]);
      change(source, request.pendingChanges[source]);
    }
  }, [request]);

  useEffect(() => {
    setEditable(
      !(
        source === 'guildPriority' &&
        form &&
        form.values &&
        form.values.status === 6
      ),
    );
  }, [form]);
  useEffect(() => {
    if (
      !optionSelected &&
      selected &&
      !currentInputRef &&
      currentDropdownValue &&
      currentDropdownValue[source]
    ) {
      setSelected(false);
      handleSetValue(currentDropdownValue[source]);
    }
  }, [currentInputRef]);

  useEffect(() => {
    if (
      parent &&
      currentDropdownValue &&
      currentDropdownValue[parent] !== undefined &&
      parentValue !== currentDropdownValue[parent] &&
      parentValue !== ''
    ) {
      handleSetValue('');
    }
    if ((parent && !parentValue) || parentValue === '') {
      const currentValue = { ...currentDropdownValue, [source]: initValue };
      setCurrentDropdownValue(currentValue);
    }
  }, [parentValue]);

  useEffect(() => {
    if (dropdowns[source]) {
      const defaultValue = dropdowns[source].defaultValue;
      if (!initValue && defaultValue) {
        handleSetValue(defaultValue.value);
      }
    }
  }, [dropdowns]);

  const handleOptionSelect = (option: any) => {
    let value = option.value;
    if (option.value === '-' && !isPriorityPlanning) {
      value = '';
    }
    if (isGuildPriorityActive) {
      if (source === 'guildPriority' && value) {
        if (value > 10) {
          form.values.status = 5;
        } else {
          form.values.status = 9;
        }
      }
      if (source === 'guild' || source === 'subGuild' || source === 'site') {
        form.values.guildPriority = '';
      }
    }
    if (
      ((source === 'guild' &&
        !PRE_PENDING_GUILDS_NAMES.includes(value) &&
        !PRE_PENDING_COMPANIES_NAMES.includes(form.values.company)) ||
        (source === 'company' &&
          !PRE_PENDING_COMPANIES_NAMES.includes(value) &&
          !PRE_PENDING_GUILDS_NAMES.includes(form.values.guild))) &&
      form.values.status === 10
    ) {
      form.values.status = 1;
    }
    if (
      !form.values.isPrePendingApproved &&
      form.values.status === 1 &&
      ((source === 'guild' && PRE_PENDING_GUILDS_NAMES.includes(value)) ||
        (source === 'company' && PRE_PENDING_COMPANIES_NAMES.includes(value)))
    ) {
      form.values.status = 10;
    }
    setOptionSelected(true);
    handleChildDropdownValue();
    setSelected(false);
    handleSetValue(value);
    setFocus(false);
  };

  const handleChildDropdownValue = () => {
    let currentValue;
    if (source === 'guild') {
      currentValue = { ...currentDropdownValue, subGuild: '' };
    }
    if (source === 'company') {
      currentValue = { ...currentDropdownValue, subTeam: '' };
    }
    setCurrentDropdownValue(currentValue);
  };

  const handleInputClick = (event: any) => {
    scrollTo();
    const val =
      pendingChange && value === initialValue && !optionSelected
        ? pendingChange
        : value;
    if (val !== '') {
      const currentValue = { ...currentDropdownValue, [source]: val };
      setCurrentDropdownValue(currentValue);
    }
    setOptionSelected(false);
    setSelected(true);
    setOptionValues();
    setRef(myId);
    handleSetValue('', true);
    setFocus(true);
    document.addEventListener('keydown', escFunction, false);
  };

  const escFunction = (event: any) => {
    if (event.keyCode === 27) {
      event.stopPropagation();
      closeDropdown();
    }
  };

  const closeDropdown = () => {
    setFocus(false);
    setRef(null);
    document.removeEventListener('keydown', escFunction, false);
  };

  const handleSetValue = (value: string, dummy?: any) => {
    change(source, value);
    if (dummy) {
      change(`dummy${source}`, value);
    }
    setValue(value);
  };

  const scrollTo = () => {
    const element = document.getElementById(source);
    const form = document.getElementById(formName);

    if (
      form &&
      element &&
      element.offsetTop + 200 > form.clientHeight + form.scrollTop
    ) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const setOptionValues = () => {
    if (isGuildPriorityActive && source === 'guildPriority') {
      const subGuild = form.values.subGuild;
      const site = form.values.site;
      if (!subGuild || !site) {
        setOptions([]);
        return;
      }
      if (guildPriorities[subGuild] && guildPriorities[subGuild][site]) {
        setOptions(guildPriorities[subGuild][site]);
      } else {
        const options: any = [
          { label: '-', value: '-' },
          { label: 1, value: 1 },
        ];
        setOptions(options);
      }
      return;
    }
    if (parent) {
      const parentData =
        dropdowns[parent] &&
        Array.isArray(dropdowns[parent].data) &&
        dropdowns[parent].data.filter(
          (item: any) => item.value === getValue(parent),
        )[0];

      if (parentData) {
        const subs = parentData.subs ? parentData.subs : [];
        const validSubs = subs.filter(
          (sub: any) =>
            !hiddenSubGuilds.includes(sub.externalId) &&
            !hiddenTeams.includes(sub.externalId),
        );
        setOptions(validSubs);
      } else {
        setOptions([]);
      }
    } else {
      const temp = dropdowns[dropdownName || source]
        ? dropdowns[dropdownName || source].data
        : [];
      setOptions(temp);
    }

    if (isPriorityPlanning) {
      setOptions(dropdowns[props.source].data.map((item: any) => {
        return {
          label: (
            <Badge size="small" skin={item.skin} onClick={() => { }}>
              {item.label}
            </Badge>
          ),
          value: item.value
        };
      }))
    }
  };

  const isFocused = focus && currentInputRef === myId;

  const getSkin = (vaule: any) => {
    const option = dropdowns[props.source]?.data?.find((op: any) => op.value == vaule);
    const skin = option?.skin;
    return skin ? skin : "";
  }

  const getField = () => {
    return (
      <Badge className={s.badge} skin={getSkin(value)}>
        {value}
      </Badge>
    )
  }

  return (
    <div className={s.mainContainer}>
      {title && (
        <Text className={s.formInputLabel}>
          {title}
          {required && '*'}
        </Text>
      )}
      <div className={`${s.formInputGroup} ${aditionalClass}`}>
        {isFocused && editable && !disabled ? (
          <Fragment>
            <div onClick={handleInputClick}>
              {isPriorityPlanning && value ? getField() : <Field
                name={`dummy${source}`}
                component={renderField}
                aditionalClass={aditionalClass}
                validate={required ? [validateRequired] : null}
                onChange={(e: any) => setValue(e.target.value)}
              />
              }
            </div>
            <CustomDropdown
              filter={value}
              title={placeholder}
              options={options}
              onSelect={handleOptionSelect}
              style={{
                position: 'absolute',
                zIndex: 1,
                width: 'inherit',
                marginTop: '9px',
              }}
            />
          </Fragment>
        ) : (
          <div
            className={s.formInputGroup}
            onClick={!disabled ? handleInputClick : () => { }}
          >
            {isPriorityPlanning && value ? getField() : <><Field
              name={source}
              component={renderField}
              aditionalClass={aditionalClass}
              validate={required ? [validateRequired] : null}
              placeholder={placeholder}
              onChange={(e: any) => setValue(e.target.value)}
              disabled={disabled}
            /> <div className={s.chevronBox}>
                {!disabled && <ChevronDown size="18" />}
              </div></>}

          </div>
        )}
      </div>
      {pendingChange && <div></div>}
      {pendingChange && (
        <Text className={s.changedFromText}>Changed from:</Text>
      )}
      {pendingChange && <div></div>}
      {pendingChange && (
        <Text className={s.pendingChangeText}>{initialValue}</Text>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  guildPriorities: state.dropdowns.guildPriorities,
  form: state.form[formName],
  request: state.requestModal.request,
  mode: state.requestModal.mode,
});

export default connect(mapStateToProps)(FormAutoFillDropdown);
