import React, { useState, useEffect } from 'react';
import { DocMoveto } from 'wix-ui-icons-common';
import { connect } from 'react-redux';
import { Text, Button, Search, Dropdown, IconButton } from "@wix/design-system";
import {
  searchSeats,
  setSearchResults,
  setPagination,
  setRef,
  downloadSpreadSheet,
} from '../redux/actions/seatsActions';
import {
  openModal,
  setMode,
  setRequest,
} from '../redux/actions/requestModalActions';
import {
  clearFiltersAndSearch,
  setCurrentView,
} from '../redux/actions/filterAction';
import s from './Filter.scss';
import FieldsFilter from './subComponents/FieldsFilter/FieldsFilter';
import SortBy from './subComponents/SortBy/SortBy';
import { v4 as uuidv4 } from 'uuid';
import SaveView from './subComponents/SaveView/SaveView';
import { allColumns } from '../redux/reducers/columns/columns';
import { statusNames } from '../../../Client/config';
import { JSONToCSVConvertor } from '../../../Client/utils';

let debounceTimer: any = null;

const Filter = (props: any) => {
  const [searchText, setSearchText] = useState('');
  const {
    pagination,
    searchSeats,
    setMode,
    setPagination,
    openModal,
    setRequest,
    sort,
    setRef,
    currentCellRef,
    user,
    fieldsFilter,
    currentView,
    searchValue,
    setCurrentView,
    columnGroups,
    searchResults,
    clearFiltersAndSearch,
    downloadSpreadSheet,
  } = props;

  const [fieldsDropdown, setFieldsDropdown] = useState(false);
  const [sortByDropdown, setSortByDropdown] = useState(false);
  const [viewDropdown, setViewDropdown] = useState(false);
  const [myId, setMyId] = useState(uuidv4());
  const [filtered, setFiltered] = useState(false);

  useEffect(() => {
    if (
      fieldsFilter &&
      ((fieldsFilter.filteredGroups && fieldsFilter.filteredGroups.length) ||
        (fieldsFilter.filteredColumns && fieldsFilter.filteredColumns.length))
    ) {
      setFiltered(true);
    } else {
      setFiltered(false);
    }
  }, [fieldsFilter]);

  useEffect(() => {
    if (searchText !== searchValue) {
      setSearchText(searchValue);
    }
  }, [searchValue]);

  const debounceSearch = (value: any) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
      debounceTimer = null;
    }
    debounceTimer = setTimeout(() => {
      setCurrentView(null);
      searchSeats(value);
    }, 500);
  };

  // const searchHandler = async (event: any) => {
  //   if (searchText.length === 0) {
  //     return;
  //   }
  //   searchSeats(searchText);
  //   return;
  // };

  const searchValueChange = (event: any) => {
    pagination > 0 && setPagination(0);
    const value = event.target.value;
    setSearchText(value);
    if (value.length === 0) {
      debounceSearch(' ');
      return;
    }
    debounceSearch(value);
  };

  const clearSearch = async () => {
    searchSeats(' ');
    return;
  };

  const openModalhandle = () => {
    setMode('New');
    setRequest(null);
    openModal();
  };

  // const handleDownloadSpreadsheet = () => {
  //   // Gets array of columnGroups objects and creates array of all "columns.source" to be presented
  //   const filteredColumns = columnGroups
  //     .filter(
  //       (columnGroup: any) =>
  //         columnGroup.display === true && columnGroup.filtered === false,
  //     )
  //     .map((displayedGroups: any) => displayedGroups.columns)
  //     .flat()
  //     .filter((column: any) => column.filtered === false)
  //     .map((column: any) => column.source);

  //   // Iterate over "searched properties" seats and add data from seat to newSeat
  //   const convertedSeats = searchResults.map((seat: any) => {
  //     // Initialize newSeat
  //     const newSeat: any = {};
  //     // Arrange properties of newSeat object as in UI table
  //     allColumns.forEach((columnGroup: any) => {
  //       columnGroup.columns.forEach((column: any) => {
  //         newSeat[column.source] = '';
  //       });
  //     });

  //     // Set columns data and Remove collapsed/filtered columns from newSeat
  //     for (const [key] of Object.entries(newSeat)) {
  //       if (
  //         filteredColumns.includes(key) &&
  //         key !== 'businessJustification' &&
  //         key !== 'none'
  //       ) {
  //         if (key === 'status') {
  //           newSeat[key] = statusNames[seat.status].label;
  //         } else {
  //           newSeat[key] = seat[key];
  //         }
  //       } else {
  //         delete newSeat[key];
  //       }
  //     }
  //     return newSeat;
  //   });

  //   JSONToCSVConvertor(convertedSeats, 'TEST', true);
  // };

  return (
    <div className={s.filterContainer}>
      <section className={s.filterSection}>
        {user.permission !== 2 && (
          <Button
            onClick={openModalhandle}
            className={`${s.filterButton} ${s.createNewButton}`}
          >
            Create New Request
          </Button>
        )}
        <div
          onClick={(event) => {
            setSortByDropdown(true);
            setFieldsDropdown(false);
            setViewDropdown(false);
            setRef(myId);
            event.stopPropagation();
          }}
          className={s.dropdownGroup}
        >
          <Text className={s.groupText}>Sort By</Text>
          <Dropdown
            className={s.filterDropdown}
            placeholder={sort.column.title}
          />
        </div>
        {sortByDropdown && currentCellRef === myId ? (
          <div
            className={s.sortByDropdown}
            style={{
              position: 'absolute',
              top: '193px',
              left: `${user.permission === 2 ? '97px' : '290px'}`,
              zIndex: 101,
            }}
          >
            <SortBy setSortByDropdown={setSortByDropdown}></SortBy>
          </div>
        ) : null}
        <div
          onClick={(event) => {
            setFieldsDropdown(true);
            setSortByDropdown(false);
            setViewDropdown(false);
            setRef(myId);
            event.stopPropagation();
          }}
          className={s.dropdownGroup}
        >
          <Text className={s.groupText}>Select Columns</Text>
          <Dropdown
            className={s.filterDropdown}
            customInput="input"
            placeholder={filtered ? 'Custom Columns' : 'All Columns'}
          />
        </div>
        {fieldsDropdown && currentCellRef === myId ? (
          <div
            className={s.fieldsDropdown}
            style={{
              position: 'absolute',
              top: '193px',
              left: `${user.permission === 2 ? '350px' : '540px'}`,
              zIndex: 101,
            }}
          >
            <FieldsFilter setFieldsDropdown={setFieldsDropdown} />
          </div>
        ) : null}
        {/* <More className={s.moreButton} /> */}
        <div
          onClick={(event) => {
            setFieldsDropdown(false);
            setSortByDropdown(false);
            setViewDropdown(true);
            setRef(myId);
            event.stopPropagation();
          }}
          className={s.dropdownGroup}
        >
          <Text className={s.groupText}>View</Text>
          <Dropdown
            className={s.filterDropdown}
            placeholder={
              currentView ? String(Object.keys(currentView)) : '-Select-'
            }
          />
        </div>
        {viewDropdown && currentCellRef === myId ? (
          <div
            className={s.fieldsDropdown}
            style={{
              position: 'absolute',
              top: '193px',
              left: '775px',
              zIndex: 101,
            }}
          >
            <SaveView setViewDropdown={setViewDropdown} />
          </div>
        ) : null}
      </section>
      <section className={s.filterSection}>
        <IconButton onClick={downloadSpreadSheet} className={s.importButton}>
          <DocMoveto className={s.importIcon} />
        </IconButton>
        <Button
          onClick={() => {
            clearFiltersAndSearch();
            setSearchText('');
          }}
          className={`${s.filterButton} ${s.clearChangesButton}`}
        >
          Clear all filters
        </Button>
        <Search
          className={s.filterSearch}
          // onEnterPressed={searchHandler}
          onChange={searchValueChange}
          onClear={clearSearch}
          value={searchText}
          customInput="input"
          noLeftBorderRadius={true}
          noRightBorderRadius={true}
          placeholder="Search"
          clearButton={false}
        />
      </section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  pagination: state.seats.pagination,
  sort: state.seats.sort,
  currentCellRef: state.seats.currentCellRef,
  user: state.auth.user,
  fieldsFilter: state.filter.fieldsFilter,
  currentView: state.filter.currentView,
  searchValue: state.filter.searchValue,
  columnGroups: state.filter.columnGroups,
  searchResults: state.seats.searchResults,
});

const mapDispatchToProps = (dispatch: any) => ({
  searchSeats: (value: any) => dispatch(searchSeats(value)),
  setSearchResults: (value: any) => dispatch(setSearchResults(value)),
  setPagination: (value: number) => dispatch(setPagination(value)),
  setRequest: (request: any) => dispatch(setRequest(request)),
  setMode: (mode: string) => dispatch(setMode(mode)),
  openModal: () => dispatch(openModal()),
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  setCurrentView: (view: any) => dispatch(setCurrentView(view)),
  clearFiltersAndSearch: () => dispatch(clearFiltersAndSearch()),
  downloadSpreadSheet: () => dispatch(downloadSpreadSheet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
