import { TABLE } from './constants';
import { initUserViews, setDataFilters } from './filterAction';
import {
  setGuildPriorityDropdownData,
  setDropdownInstances,
} from './dropdownsActions';
import {
  isGuildPriorityActive,
  statusNames,
  tableModes,
} from '../../../../Client/config';

let isDataInit = false;

export const setTableMode = (mode: tableModes) => async (
  dispatch: any,
  getState: any,
) => {
  dispatch({
    type: TABLE.TABLE_MODE,
    payload: mode,
  });
};

export const initData = () => async (dispatch: any, getState: any) => {
  if (isDataInit) {
    return;
  }
  dispatch(setDropdownInstances());
  isDataInit = true;
  // const { user } = getState().auth;
  dispatch(initUserViews());
  if (isGuildPriorityActive) {
    dispatch(setGuildPriorityDropdownData());
  }
  // dispatch(setStatusReasons());
  // if (user.permission === 3 || user.permission === 4) {
  //   dispatch(filterPrePendingSeats());
  // }
};

export const filterPrePendingSeats = () => async (
  dispatch: any,
  getState: any,
) => {
  const validStatuses = Object.entries(statusNames).map((statusName: any) => {
    const [key, value] = statusName;
    if (value.label !== 'PreP' && value.label !== 'Rejected Prep') {
      return parseInt(key, 10);
    }
  });
  dispatch(setDataFilters({ Status: { status: validStatuses } }));
};
