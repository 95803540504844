import s from './SaveView.scss';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Text, Button, Loader } from "@wix/design-system";
import { DeleteSmall } from 'wix-ui-icons-common';
import {
  setShowSaveViewModal,
  setCurrentView,
  removeView,
  resetView,
} from '../../../redux/actions/filterAction';

const SaveView = (props: any) => {
  const {
    setViewDropdown,
    setShowSaveViewModal,
    setCurrentView,
    views,
    removeView,
    currentView,
    resetView,
  } = props;
  const [selectedView, setSelectedView] = useState<any>(null);
  const [viewLoader, loadView] = useState<any>(false);
  const noViewSelectedName = 'None';

  const onDeleteViewClicked = (view: any) => {
    removeView(view);
  };

  useEffect(() => {
    if (viewLoader) {
      if (selectedView === noViewSelectedName) {
        resetView();
      } else {
        setCurrentView(selectedView);
      }
      setSelectedView(null);
      setViewDropdown(false);
      loadView(false);
    }
  }, [viewLoader]);

  const renderOptions = () =>
    views.map((view: any, i: any) => {
      return (
        <div
          key={i}
          className={
            selectedView === view || (!selectedView && currentView === view)
              ? s.selectedOption
              : s.option
          }
          onClick={() => {
            setSelectedView(view);
          }}
        >
          <Text className={s.optionText}>{Object.keys(view)}</Text>
          <div
            onClick={(event: any) => {
              event.stopPropagation();
              onDeleteViewClicked(view);
            }}
          >
            <DeleteSmall></DeleteSmall>
          </div>
        </div>
      );
    });

  const onCancel = () => {
    setSelectedView(null);
    setViewDropdown(false);
  };

  const onSubmit = () => {
    loadView(true);
  };

  return (
    <div>
      {views === null ? (
        <div className={s.noViewsMain}>
          <Loader size="small"></Loader>
        </div>
      ) : !views || !views.length ? (
        <div
          className={s.noViewsMain}
          onClick={(event: any) => event.stopPropagation()}
        >
          <div className={s.noViewsTextContainer}>
            <div>
              <Text size="small" weight="bold">
                No saved views yet.
              </Text>
            </div>
            <div>
              <Text size="small" weight="bold">
                Create your first one!
              </Text>
            </div>
            <Button
              size="small"
              className={s.button}
              onClick={() => {
                setShowSaveViewModal(true);
              }}
            >
              Save Current View
            </Button>
          </div>
        </div>
      ) : viewLoader ? (
        <div
          className={s.noViewsMain}
          onClick={(event: any) => event.stopPropagation()}
        >
          <Loader size="medium" />
        </div>
      ) : (
        <div
          className={s.main}
          onClick={(event: any) => event.stopPropagation()}
        >
          <div className={s.title}>
            <Text size="small" weight="bold">
              Views
            </Text>
          </div>
          <div
            className={
              selectedView === noViewSelectedName ? s.selectedOption : s.option
            }
            onClick={() => {
              setSelectedView(noViewSelectedName);
            }}
          >
            <Text className={s.optionText}>None</Text>
          </div>
          <div className={s.columns}>
            {renderOptions()}
            <div
              className={s.addNewView}
              onClick={() => {
                setShowSaveViewModal(true);
              }}
            >
              <Text className={s.addNewView}>+ Add Current View</Text>
            </div>
          </div>
          <div className={s.bottom}>
            <Button size="tiny" className={s.cancelButton} onClick={onCancel}>
              Cancel
            </Button>
            {selectedView ? (
              <Button size="tiny" className={s.submitButton} onClick={onSubmit}>
                Apply
              </Button>
            ) : (
              <Button size="tiny" className={s.disabledButton} disabled>
                Apply
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  views: state.filter.views,
  currentView: state.filter.currentView,
});

const mapDispatchToProps = (dispatch: any) => ({
  setShowSaveViewModal: (value: any) => dispatch(setShowSaveViewModal(value)),
  setCurrentView: (value: any) => dispatch(setCurrentView(value)),
  resetView: () => dispatch(resetView()),
  removeView: (view: any) => dispatch(removeView(view)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveView);
