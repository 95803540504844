import React from 'react';
import { connect } from 'react-redux';
import { cleanString } from '../../../../../Client/utils';
import TableCell from '../TableCell/TableCell';
import s from './RowGroup.scss';

const RowGroup = (props: any) => {
  const {
    group,
    data,
    firstGroup,
    headCountByTeam,
    targetHeadCount,
    amountOfSeatsByTeamAndStatus,
  } = props;

  const getValue = (col: any) => {
    if (data[col.source]) {
      return data[col.source];
    }
    if (data.errorSeat || !data.subTeam) {
      return null;
    }
    const isStudent = data.type === 'Student' || data.type === 'Intern' || !(data.position === 'No');

    const subTeamOrCompany = data.subTeam !== 'None' ? data.subTeam : data.company;
    const headCountSubTeamName = getHeadCountTeamName(subTeamOrCompany);
    if (col.source === 'teamHeadCount') {
      if (headCountByTeam.headCountByTargetTeam[headCountSubTeamName]) {
        return isStudent
          ? headCountByTeam.headCountByTargetTeam[headCountSubTeamName]
              .studentIntern
          : headCountByTeam.headCountByTargetTeam[headCountSubTeamName]
              .nonStudentIntern;
      }
      if (headCountByTeam[data.subTeamOrCompany]) {
        return isStudent
          ? headCountByTeam[data.subTeamOrCompany].studentIntern
          : headCountByTeam[data.subTeamOrCompany].nonStudentIntern;
      }
      return 0;
    }
    if (col.source === 'approvedFocusDIY') {
      let totalSeats = 0;
      const subTeam =
        amountOfSeatsByTeamAndStatus.targetHeadCountTeams[
        headCountSubTeamName
        ] || amountOfSeatsByTeamAndStatus.subTeams[subTeamOrCompany];
      if (subTeam) {
        totalSeats += isStudent
          ? subTeam['2']?.studentsInterns || 0
          : subTeam['2']?.nonStudentsInterns || 0;
        totalSeats += isStudent
          ? subTeam['5']?.studentsInterns || 0
          : subTeam['5']?.nonStudentsInterns || 0;
        totalSeats += isStudent
          ? subTeam['9']?.studentsInterns || 0
          : subTeam['9']?.nonStudentsInterns || 0;
        totalSeats += isStudent
          ? subTeam['13']?.studentsInterns || 0
          : subTeam['13']?.nonStudentsInterns || 0;
      }
      return totalSeats;
    }
    if (col.source === 'teamTargetHeadCount') {
      return isStudent ? '-' : targetHeadCount[headCountSubTeamName] || '-';
    }
    return null;
  };

  const getHeadCountTeamName = (subTeam: any) => {
    const cleanSubTeam = cleanString(subTeam);
    const headCountTeams = Object.keys(targetHeadCount);
    let filteredTeams: any = headCountTeams.find((team) => team === cleanSubTeam);
    if (filteredTeams) {
      return filteredTeams;
    }
    filteredTeams = headCountTeams.filter((team) => cleanSubTeam.startsWith(team));
    if (filteredTeams.length === 1) {
      return filteredTeams[0];
    }
    filteredTeams = filteredTeams.filter((team: string) => team.includes('\\'));
    return filteredTeams[0];
  };

  const renderGroup = () => {
    if (!group.display) {
      return <td key={group.title} className={s.tableDivider}></td>;
    }

    return group.columns
      .filter((col: any) => !col.filtered)
      .map((col: any, index: number) => (
        <TableCell
          firstCell={index === 0 && firstGroup}
          key={index}
          data={data}
          value={getValue(col)}
          col={col}
          style={{ minWidth: col.size, maxWidth: col.size }}
        />
      ));
  };

  return renderGroup();
};

const mapStateToProps = (state: any) => ({
  headCountByTeam: state.headCount.headCountByTeam,
  targetHeadCount: state.headCount.targetHeadCount,
  amountOfSeatsByTeamAndStatus: state.seats.amountOfSeatsByTeamAndStatus,
});

export default connect(mapStateToProps)(RowGroup);
