import React, { Fragment, useState } from 'react';
import { TextButton } from "@wix/design-system";
import { ChevronDown, Check, Share, Layers } from 'wix-ui-icons-common';
import { connect } from 'react-redux';
import { submit, getFormSyncErrors } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import CustomDropdown from '../../CustomDropdown/CustomDropdown';
import s from './ModalButtons.scss';
import {
  closeModal,
  saveAsDraft,
  setRef,
  setOpenNew,
  formName,
  setCurrentDropdownValue,
  setAmountOfCopies,
} from '../../redux/actions/requestModalActions';
import { displayModalMessage } from '../../redux/actions/seatsActions';
import {
  guildPriorityStatuses,
  isGuildPriorityActive,
} from '../../../../Client/config';

const ModalButtons = (props: any) => {
  const {
    closeModal,
    setRef,
    form,
    seats,
    submitForm,
    currentInputRef,
    saveAsDraft,
    setOpenNew,
    getErrors,
    displayModalMessage,
    setAmountOfCopies,
    amountOfCopies,
    mode,
    user,
  } = props;

  const [focus, setFocus] = useState(false);
  const [myId, setMyId] = useState(uuidv4());
  const [disabled, setDisabled] = useState(mode === 'View');

  // Options for submit dropdown
  const options = [
    { value: 'Submit Request', icon: <Check size="18" /> },
    { value: 'Submit & Open New', icon: <Share size="18" /> },
  ];
  if (mode === 'New' && !form.values.guildPriority) {
    options.push({ value: 'Submit Many', icon: <Layers size="18" /> });
  }

  const toggleOptions = () => {
    setFocus(!focus);
    setRef(myId);
  };

  const handleSelectOption = (option: any) => {
    if (option.value === 'Submit Many') {
      displayModalMessage({
        title: 'Select amount of copies',
        message: {
          initValue: amountOfCopies,
          minValue: 1,
          maxValue: 10,
          type: 'numberInput',
        },
        type: 'confirm',
        buttonText: 'Submit',
        onSubmit: handleDuplicateManySubmit,
        cancelButtonText: 'Return',
        modalWidth: 260,
      });
      return;
    }
    setOpenNew(option.value === options[1].value);
    setFocus(false);
    setCurrentDropdownValue(null);
    submitForm();
  };

  const handleDuplicateManySubmit = (value: any) => {
    setAmountOfCopies(value);
    handleFormSubmit();
  };

  const onSubmitClick = () => {
    if (mode === 'New' && user.permission === 1) {
      displayModalMessage({
        title: 'Time To Share',
        message: `Make sure that you have added all relevant people related to your request in the "Share Request With" field. Please note that only the people you have added will see your request in their dashboard, so be sure to add relevant guild and company managers for their visibility.`,
        type: 'confirm',
        buttonText: 'Submit',
        onSubmit: handleFormSubmit,
        cancelButtonText: 'Return',
      });
      return;
    }
    if (
      isGuildPriorityActive &&
      form.values.guildPriority !== form.initial.guildPriority
    ) {
      const subGuildPrioritySeats = seats.filter(
        (seat: any) =>
          seat._id != form.values._id &&
          seat.site == form.values.site &&
          seat.subGuild == form.values.subGuild &&
          seat.guildPriority &&
          guildPriorityStatuses.includes(seat.status),
      );

      if (subGuildPrioritySeats.length > 0) {
        const modalMessage =
          (!form.values.guildPriority && form.initial.guildPriority) ||
          form.values.guildPriority < Number(form.initial.guildPriority)
            ? 'Please note: this action will raise the priority of lower priority Seats'
            : 'Please note: this action will push down current Seats in equal & lower priority.';
        displayModalMessage({
          title: 'Guild priority',
          message: modalMessage,
          type: 'confirm',
          buttonText: 'Submit',
          onSubmit: handleFormSubmit,
          cancelButtonText: 'Return',
        });
        return;
      }
    }
    handleFormSubmit();
  };
  const handleFormSubmit = () => {
    scrollToErrorField();
    setOpenNew(false);
    setCurrentDropdownValue(null);
    submitForm();
  };

  const scrollToErrorField = () => {
    const errors = getErrors();
    const errorKeys = Object.keys(errors);

    if (errorKeys.length === 0) {
      return;
    }

    const firstErrorName = errorKeys[0];
    const errorElement = document.getElementById(firstErrorName);
    const form = document.getElementById(formName);

    if (form && errorElement) {
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Fragment>
      <TextButton onClick={closeModal} className={s.formCancelButton}>
        Cancel
      </TextButton>
      <div className={s.buttonsContainer}>
        {focus && currentInputRef === myId ? (
          <CustomDropdown
            options={options}
            onSelect={handleSelectOption}
            style={{
              position: 'absolute',
              zIndex: 1,
              width: '227px',
              height: '92px',
              top: '-95px',
              right: '0',
            }}
          />
        ) : null}
        {/* <button onClick={saveAsDraft} className={s.formSaveAsDraftButton}>
          Save as draft
        </button> */}
        {!disabled ? (
          <div>
            <button className={s.formSubmitButton} onClick={onSubmitClick}>
              Submit
            </button>
            <ChevronDown className={s.moreOptions} onClick={toggleOptions} />
          </div>
        ) : (
          <div>
            <button
              className={`${s.formSubmitButton} ${s.disabled}`}
              disabled={true}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  isModalOpen: state.requestModal.isModalOpen,
  currentInputRef: state.requestModal.currentInputRef,
  amountOfCopies: state.requestModal.amountOfCopies,
  form: state.form[formName],
  seats: state.seats.seats,
  getErrors: () => getFormSyncErrors(formName)(state),
  mode: state.requestModal.mode,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  closeModal: () => dispatch(closeModal()),
  setOpenNew: (openNew: boolean) => dispatch(setOpenNew(openNew)),
  setAmountOfCopies: (value: number) => dispatch(setAmountOfCopies(value)),
  setCurrentDropdownValue: (value: any) =>
    dispatch(setCurrentDropdownValue(value)),
  saveAsDraft: () => dispatch(saveAsDraft()),
  submitForm: (form: string = formName) => dispatch(submit(form)),
  displayModalMessage: (message: any) => dispatch(displayModalMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalButtons);
