import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import s from './Delegate.scss';
import { Text } from "@wix/design-system";
import { ChevronDown, ChevronUp, DeleteSmall } from 'wix-ui-icons-common';
import { setRef } from '../../../../../redux/actions/seatsActions';
import CustomDropdown from '../../../../../CustomDropdown/CustomDropdown';
import {
  showTooltip,
  hideTooltip,
} from '../../../../../redux/actions/tooltipActions';

const DISABLED_TEAM_MEMBER_TOOLTIP = 'You need to select a team first.';

const Delegate = (props: any) => {
  const {
    headOf,
    requesters,
    data,
    onTeamSelectedCallback,
    onDelegateSelectedCallback,
    onRemoveCallback,
    currentCellRef,
    setRef,
    avatarsHash,
    showTooltip,
    hideTooltip,
  } = props;

  const [validTeams, setValidTeams] = useState<any>([]);
  const [validTeamMembers, setValidTeamMembers] = useState<any>([]);
  const [focusTeam, setFocusTeam] = useState<any>(false);
  const [focusTeamMember, setFocusTeamMember] = useState<any>(false);
  const [teamFilterValue, setTeamFilterValue] = useState('');
  const [teamMemberFilterValue, setTeamMemberFilterValue] = useState('');
  const [teamDropdownId, setTeamDropdownId] = useState<any>(uuidv4());
  const [teamMemberDropdownId, setTeamMemberDropdownId] = useState<any>(
    uuidv4(),
  );
  const [selectedTeam, setSelectedTeam] = useState<any>(
    data.topCompany || data.topTeam || data.company,
  );
  const [selectedTeamMember, setSelectedTeamMember] = useState<any>(
    data.delegate,
  );
  const [avatarImage, setAvatarImage] = useState<any>(
    data.delegate ? avatarsHash[data.delegate.value] : null,
  );

  const renderTooltip = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    showTooltip(DISABLED_TEAM_MEMBER_TOOLTIP, rect.x, rect.y - 40);
  };

  const listTeams = (headOf: any) => {
    const teams: any = [];
    if (headOf.topCompanies && headOf.topCompanies.length > 0) {
      for (const [index, value] of headOf.topCompanies.entries()) {
        if (value.includes('\\')) {
          teams.push({
            id: index,
            label: value,
            value,
            type: 'topTeam',
          });
          continue;
        }
        teams.push({
          id: index,
          label: value,
          value,
          type: 'topCompany',
        });
      }
    }
    if (headOf.topTeams && headOf.topTeams.length > 0) {
      for (const [index, value] of headOf.topTeams.entries()) {
        if (headOf.topCompanies && !headOf.topCompanies.includes(value)) {
          teams.push({
            id: index,
            label: value,
            value,
            type: 'topTeam',
          });
        }
      }
    }
    if (teams.length === 0 && headOf.companies && headOf.companies.length > 0) {
      for (const [index, value] of headOf.companies.entries()) {
        teams.push({
          id: index,
          label: value,
          value,
          type: 'company',
        });
      }
    }
    setValidTeams(teams);
  };

  useEffect(() => {
    setSelectedTeam(data.topCompany || data.topTeam || data.company);
    setSelectedTeamMember(data.delegate);
    setAvatarImage(data.delegate ? avatarsHash[data.delegate.value] : null);
  }, [data]);

  const listRequesters = (requesters: any) => {
    const tempRequesters: any = [];
    for (const [index, requester] of requesters.entries()) {
      tempRequesters.push({
        id: index,
        label: requester.label,
        value: requester.value,
        email: requester.email || requester.oracleMail,
        icon: requester.icon || 'https://i.stack.imgur.com/l60Hf.png',
      });
    }
    setValidTeamMembers(tempRequesters);
  };

  useEffect(() => {
    listTeams(headOf);
  }, [headOf]);

  useEffect(() => {
    if (!requesters) {
      return;
    }
    listRequesters(requesters);
  }, [requesters]);

  useEffect(() => {
    onTeamSelectedCallback(data.id, selectedTeam);
  }, [selectedTeam]);

  useEffect(() => {
    onDelegateSelectedCallback(data.id, selectedTeamMember);
  }, [selectedTeamMember]);

  const handleSelectTeamClicked = () => {
    setRef(teamDropdownId);
    setFocusTeam(true);
  };

  const handleSelectTeamMemberClicked = () => {
    setRef(teamMemberDropdownId);
    setFocusTeamMember(true);
  };

  const handleSelectTeam = (option: any) => {
    setSelectedTeam(option);
    setFocusTeam(false);
  };

  const handleSelectTeamMember = (option: any) => {
    setSelectedTeamMember(option);
    setAvatarImage(option.icon);
    setFocusTeamMember(false);
  };

  return (
    <div className={s.main}>
      <Text className={s.title}>Team</Text>
      <div
        className={`${s.formInputGroup} ${s.formInputGroupTeam}`}
        onClick={handleSelectTeamClicked}
        id={teamDropdownId}
      >
        <input
          className={`${s.formInput} ${s.teamsInput}`}
          placeholder={
            focusTeam && currentCellRef === teamDropdownId
              ? ''
              : '-Select Team-'
          }
          value={
            focusTeam && currentCellRef === teamDropdownId
              ? teamFilterValue
              : selectedTeam?.value
          }
          onChange={(event) => setTeamFilterValue(event.target.value)}
        />
        <div className={s.chevronBox}>
          {focusTeam && currentCellRef === teamDropdownId ? (
            <ChevronUp size="16" />
          ) : (
            <ChevronDown size="16" />
          )}
        </div>
      </div>
      {focusTeam && currentCellRef === teamDropdownId && (
        <CustomDropdown
          onClick={(event: any) => event.stopPropagation()}
          title={' '}
          options={validTeams}
          onSelect={(option: any) => handleSelectTeam(option)}
          style={{
            position: 'absolute',
            zIndex: 1,
            height: '97px',
            width: '158px',
            marginTop: '-128px',
            marginLeft: '42px',
          }}
          headerStyle={{
            height: '12px',
          }}
          optionsStyle={{
            height: '30px',
            paddingTop: '5px',
            paddingBottom: '6px',
            fontSize: '12px',
          }}
          footerStyle={{
            height: '12px',
          }}
          filter={teamFilterValue}
        />
      )}
      <Text className={s.title}>Delegate</Text>
      {selectedTeam ? (
        <div
          className={`${s.formInputGroup} ${s.formInputGroupTeamMember}`}
          onClick={handleSelectTeamMemberClicked}
          id={teamDropdownId}
        >
          <input
            className={`${s.formInput} ${
              avatarImage ? s.teamMembersInput : s.teamsInput
            }`}
            placeholder={
              focusTeamMember && currentCellRef === teamMemberDropdownId
                ? ''
                : '-Select Team member-'
            }
            value={
              focusTeamMember && currentCellRef === teamMemberDropdownId
                ? teamMemberFilterValue
                : selectedTeamMember?.value
            }
            onChange={(event) => setTeamMemberFilterValue(event.target.value)}
          />
          <div className={s.chevronBox}>
            {focusTeamMember && currentCellRef === teamMemberDropdownId ? (
              <ChevronUp size="16" />
            ) : (
              <ChevronDown size="16" />
            )}
          </div>
          {avatarImage && currentCellRef !== teamMemberDropdownId && (
            <img
              src={avatarImage}
              className={s.avatar}
              width="18"
              height="18"
            />
          )}
        </div>
      ) : (
        <div
          className={s.formInputGroupDisabled}
          id={teamDropdownId}
          onMouseEnter={renderTooltip}
          onMouseLeave={hideTooltip}
        >
          <input
            className={`${s.formInput} ${
              avatarImage ? s.teamMembersInput : s.teamsInput
            }`}
            placeholder="-Select Team member-"
            value={selectedTeamMember?.value}
            disabled
          />
          <div className={`${s.chevronBox} ${s.chevronBoxDisabled}`}>
            <ChevronDown size="16" />
          </div>
          {avatarImage && (
            <img
              src={avatarImage}
              className={s.avatar}
              width="18"
              height="18"
            />
          )}
        </div>
      )}
      {focusTeamMember && currentCellRef === teamMemberDropdownId && (
        <CustomDropdown
          onClick={(event: any) => event.stopPropagation()}
          title={' '}
          options={validTeamMembers}
          onSelect={(option: any) => handleSelectTeamMember(option)}
          style={{
            position: 'absolute',
            zIndex: 1,
            height: '158px',
            width: '158px',
            marginTop: '-190px',
            marginLeft: '305px',
          }}
          headerStyle={{
            height: '12px',
          }}
          optionsStyle={{
            height: '30px',
            paddingTop: '5px',
            paddingBottom: '6px',
            paddingLeft: '6px',
          }}
          filter={teamMemberFilterValue}
        />
      )}
      <div
        className={s.delete}
        onClick={(event: any) => {
          event.stopPropagation();
          onRemoveCallback(data.id);
        }}
      >
        <DeleteSmall />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  headOf: state.auth.user.headOf,
  requesters: state.dropdowns.dropdowns.requester.data,
  currentCellRef: state.seats.currentCellRef,
  avatarsHash: state.dropdowns.avatarsHash,
});

const mapDispatchToProps = (dispatch: any) => ({
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
  hideTooltip: () => dispatch(hideTooltip()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Delegate);
