import React, { useState, useEffect } from 'react';
import s from './CheckBox.scss';
import { connect } from 'react-redux';
import { setChangedSeats } from '../../../redux/actions/seatsActions';
import CircleSmallFilled from 'wix-ui-icons-common/CircleSmallFilled';
import { Checkbox } from "@wix/design-system";
import { showTooltip } from '../../../redux/actions/tooltipActions';
import { getPendingChangeTooltip } from '../../../../../Client/utils';

const CheckBox = (props: any) => {
  const {
    value,
    col,
    data,
    changedSeats,
    setChangedSeats,
    blockEdit,
    showTooltip,
  } = props;

  const [edited, setEdited] = useState(
    changedSeats[data._id] &&
      (changedSeats[data._id][col.source] ||
        changedSeats[data._id][col.source] === false),
  );

  const [pendingChange, setPendingChange] = useState<any>(null);

  useEffect(() => {
    const pendingValue =
      data.pendingChanges && data.pendingChanges[col.source]
        ? data.pendingChanges[col.source]
        : null;
    setPendingChange(pendingValue);
    const isEdited =
      changedSeats[data._id] &&
      (changedSeats[data._id][col.source] ||
        changedSeats[data._id][col.source] === false);
    setEdited(isEdited);
    setChecked(
      isEdited
        ? !stupidBool(pendingValue || value)
        : stupidBool(pendingValue || value),
    );
  }, [changedSeats, data]);

  const stupidBool = (val: any) => {
    if (!val) {
      return false;
    }
    if (val.toString().toLowerCase() === 'n') {
      return false;
    }
    return true;
  };

  const [checked, setChecked] = useState(stupidBool(value));

  const handleCheckbox = () => {
    if (blockEdit) {
      return;
    }
    let newValue: any;
    const newChangedSeats = { ...changedSeats };
    if (
      changedSeats[data._id] &&
      (changedSeats[data._id][col.source] ||
        changedSeats[data._id][col.source] === false)
    ) {
      newValue = stupidBool(pendingChange || value);
      setChecked(newValue);
      setEdited(false);
      delete newChangedSeats[data._id][col.source];
      setChangedSeats(newChangedSeats);
    } else {
      newValue = pendingChange ? stupidBool(pendingChange) : !stupidBool(value);
      setChecked(newValue);
      setEdited(true);
      newValue = newValue ? 'Y' : 'N';
      newChangedSeats[data._id] = {
        ...changedSeats[data._id],
        [col.source]: newValue,
      };
    }
    setChangedSeats(newChangedSeats);
  };

  const renderTooltip = (e: any) => {
    if (!pendingChange) {
      return;
    }
    const display = getPendingChangeTooltip(
      stupidBool(pendingChange),
      stupidBool(value),
      col.source,
    );
    const rect = e.target.getBoundingClientRect();
    showTooltip(display, rect.x, rect.y - 40);
  };

  return (
    <div className={s.main} onMouseEnter={renderTooltip}>
      {edited ? <CircleSmallFilled className={s.edited} size="18px" /> : null}
      <Checkbox
        className={checked ? s.checkboxChecked : s.checkbox}
        checked={checked}
        onChange={handleCheckbox}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  changedSeats: state.seats.changedSeats,
});

const mapDispatchToProps = (dispatch: any) => ({
  setChangedSeats: (changedSeats: any) =>
    dispatch(setChangedSeats(changedSeats)),
  showTooltip: (text: string, x: string, y: string) =>
    dispatch(showTooltip(text, x, y)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckBox);
