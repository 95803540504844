import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import s from './CustomDropdown.scss';

const CustomDropdown = (props: any) => {
  const {
    title,
    options,
    style,
    filter,
    onSelect,
    headerStyle,
    optionsStyle,
    footerStyle,
  } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [pageSize, setPageSize] = useState(30);

  useEffect(() => {
    const optionsContainer = document.getElementById('options-container');
    if (!optionsContainer) {
      return;
    }
    optionsContainer.addEventListener('scroll', handleScroll);
    return () => optionsContainer.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) {
      return;
    }
    fetchMoreListItems();
  }, [isFetching]);

  const handleScroll = () => {
    const optionsContainer = document.getElementById('options-container');
    if (
      !optionsContainer ||
      optionsContainer.scrollHeight - optionsContainer.scrollTop >
        optionsContainer.clientHeight + optionsContainer.clientHeight
    ) {
      return;
    }
    setIsFetching(true);
  };

  const fetchMoreListItems = () => {
    const sum: number = Number(pagination) + Number(pageSize);
    setPagination(sum);
    setIsFetching(false);
  };

  const renderOptions = () => {
    return options
      .filter((option: any) => {
        if (!filter || !option?.value || Number.isInteger(filter)) {
          return true;
        }
        return option.value
          .toString()
          .toLowerCase()
          .includes(filter?.toLowerCase());
      })
      .slice(0, pagination + pageSize)
      .map((option: any, i: any) => {
        const avatar = typeof option.icon === 'string' && (
          <img
            className={s.avatar}
            src={option.icon}
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src = 'https://i.stack.imgur.com/l60Hf.png';
            }}
            width="20"
            height="20"
          ></img>
        );
        return (
          <div
            key={i}
            className={s.option}
            style={optionsStyle}
            onClick={() => onSelect(option)}
          >
            {avatar || option.icon || null}
            <div className={s.optionText}>{option.label || option.value}</div>
          </div>
        );
      });
  };
  return (
    <div className={s.main} style={style}>
      {title && (
        <div className={s.header} style={headerStyle}>
          <div className={s.headerText}>{title}</div>
        </div>
      )}
      <div id="options-container" className={s.columns}>
        {renderOptions()}
      </div>
      {footerStyle && <div style={footerStyle} />}
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDropdown);
