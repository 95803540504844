import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import s from './Form.scss';
import Group from './FormGroup/Group';
import GridGroup from './FormGroup/GridGroup';
import { formName } from '../../redux/actions/requestModalActions';

const RequestModalForm = (props: any) => {
  const { formData, hidden } = props;

  const isGrid = (group: any) => {
    const filteredColumns = group.columns.filter(
      (col: any) => col.edit || col.editModeOnly,
    );

    if (group.type === 'grid' && filteredColumns.length > 1) {
      return true;
    }
    return false;
  };

  const renderFormFromInputData = () => {
    return formData.map((group: any, index: number) => {
      return isGrid(group) ? (
        <GridGroup key={index} {...group} hidden={hidden} />
      ) : (
        <Group key={index} {...group} />
      );
    });
  };

  return (
    <form id={formName} className={s.form}>
      {renderFormFromInputData()}
    </form>
  );
};

const mapStateToProps = (state: any) => ({
  formData: state.filter.columnGroups,
});

const withReduxForm = reduxForm({
  form: formName,
})(RequestModalForm);

export default connect(mapStateToProps, null)(withReduxForm);
