import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Actions from '../Actions/Actions';
import Status from '../Status/Status';
import AutofillDropdown from '../AutofillDropdown/AutofillDropdown';
import Replacement from '../Replacement/Replacement';
import Text from '../Text/Text';
import CheckBox from '../CheckBox/CheckBox';
import CustomDatePicker from '../DatePicker/CustomDatePicker';
import InputText from '../InputText/InputText';
import RequesterText from '../RequesterText/RequesterText';
import { hideTooltip } from '../../../redux/actions/tooltipActions';
import Justification from '../Justification/Justification';
import {
  isGuildPriorityActive,
  PRE_PENDING_PERMISSION,
  PRE_PENDING_GUILDS_NAMES,
  PRE_PENDING_COMPANIES_NAMES,
  prePendingStatuses,
  headOfEditStatuses,
  approvedStatuses,
  headOfPermissions,
  hrApprovedStatuses,
} from '../../../../../Client/config';
import s from './TableCell.scss';
import { initData } from '../../../redux/actions/tableActions';
import { isPrePending } from '../../../../../Client/utils';
import FormatDate from '../Date/Date';

const TableCell = (props: any) => {
  const {
    value,
    col,
    data,
    user,
    firstCell,
    changedSeats,
    mergedSeats,
    hideTooltip,
    initData,
    ...rest
  } = props;

  useEffect(() => {
    initData();
  }, []);

  let blockEdit =
    (user.permission === 1 && data.status !== 1) ||
    !col.edit ||
    data.delegated ||
    (user.permission === 3 && !data.mySeat) ||
    (user.permission === 5 &&
      (!data.mySeat || data.status !== 1) &&
      !approvedStatuses.includes(data.status));

  const getElementType = (colType: any) => {
    const typeElementMap: any = {
      actions: Actions,
      text: Text,
      status: Status,
      requester: blockEdit ? RequesterText : AutofillDropdown,
      inputText: blockEdit ? Text : InputText,
      dropdown: blockEdit ? Text : AutofillDropdown,
      checkbox: CheckBox,
      datepicker: CustomDatePicker,
      replacement: Replacement,
      justification: Justification,
      date: FormatDate
    };
    return typeElementMap[colType];
  };

  const renderTypeElementMap = (col: any) => {
    if (isGuildPriorityActive && col.source === 'guildPriority' && !blockEdit) {
      const currentStatus =
        changedSeats && changedSeats[data._id] && changedSeats[data._id].status
          ? changedSeats[data._id].status
          : data.status;
      if (currentStatus === 6) {
        blockEdit = true;
      }
    }
    if (
      user.permission === PRE_PENDING_PERMISSION &&
      (!Object.keys(prePendingStatuses).includes(data.status.toString()) ||
        (!isPrePending(data) && data.status !== 1))
    ) {
      blockEdit = true;
    }
    if (
      col.source !== 'status' &&
      (user.permission === 1 || user.permission === 5) &&
      isPrePending(data)
    ) {
      if (
        data.status === 10 ||
        (user.permission === 5 && approvedStatuses.includes(data.status))
      ) {
        blockEdit = false;
      } else {
        blockEdit = true;
      }
    }
    if (
      headOfPermissions.includes(user.permission) &&
      !headOfEditStatuses.includes(data.status)
    ) {
      blockEdit = true;
    }
    if(col.source === 'status' && user.permission === 5){      
      if(hrApprovedStatuses.includes(data.status)) blockEdit = false;
      else blockEdit = true;
    }    
    if(user.permission === 5 && (col.source === 'talentCenter' || col.source === 'hrRecruiter')) blockEdit = false;
    //if (user.permission === 3 && col.source === 'seniority') blockEdit = false;
    if((user.permission === 7 || user.permission === 8) && col.source === 'seniority'){      
      if(data.status === 1)
        blockEdit = false;
        else
        blockEdit = true;
    } 
    if(user.permission === 9 && (col.source === 'status') && value === 1) blockEdit = false;
    const Component = getElementType(col.type);
    return (
      <div style={blockEdit ? {} : { cursor: 'pointer' }}>
        <Component
          col={col}
          value={value}
          data={data}
          showavatar={col.type === 'requester' ? {} : null}
          blockEdit={blockEdit}
        />
      </div>
    );
  };

  return (
    <Fragment>
      {firstCell && data.newSeat ? (
        <td {...rest} onMouseLeave={hideTooltip}>
          <div className={s.newOptionsContainer}>
            <div className={s.newFlag}>
              <p className={s.newText}>New</p>
            </div>
            {renderTypeElementMap(col)}
          </div>
        </td>
      ) : (
        <td {...rest} onMouseLeave={hideTooltip}>
          {renderTypeElementMap(col)}
        </td>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  changedSeats: state.seats.changedSeats,
  mergedSeats: state.seats.mergedSeats,
});

const mapDispatchToProps = (dispatch: any) => ({
  hideTooltip: () => dispatch(hideTooltip()),
  initData: () => dispatch(initData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableCell);
