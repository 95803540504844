import React from 'react';
import s from './ToolTip.scss';

const ToolTip = (props: any) => {
  const { text, x, y } = props;
  if (!text || !x || !y) {
    return null;
  }
  return (
    <div
      className={s.tooltip}
      style={{
        position: 'absolute',
        top: `${Number(y)}px`,
        left: `${Number(x)}px`,
        zIndex: 10000,
      }}
    >
      {text}
    </div>
  );
};

export default ToolTip;
