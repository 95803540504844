import { SEATS } from '../actions/constants';
import { seatsSegmentSize } from '../../../../Client/config';

const initState: any = {
  seats: [],
  searchResults: [],
  changedSeats: {},
  pagination: 0,
  pageSize: seatsSegmentSize,
  sort: {
    column: { title: 'Last Modified', source: 'updatedAt' },
    type: 'Desc',
  },
  currentCellRef: null,
  message: null,
  modalMessage: null,
  justificationModalMessage: null,
  seatId: null,
  mergedSeats: null,
  statusJustifications: null,
  viewId: null,
  viewStart: 0,
  viewEnd: seatsSegmentSize * 2,
  amountOfSeatsByTeamAndStatus: null,
  seatsCount: { total: 0 },
};

export default (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SEATS.SET_SEATS:
      return { ...state, seats: payload };
    case SEATS.SET_SEAT_ID:
      return { ...state, seatId: payload };
    case SEATS.SEARCH_SEATS:
      return { ...state, searchResults: payload };
    case SEATS.SET_PAGINATION:
      return { ...state, pagination: payload };
    case SEATS.SET_SORT:
      return { ...state, sort: payload };
    case SEATS.SET_REF:
      return { ...state, currentCellRef: payload };
    case SEATS.SET_CHANGED_SEATS:
      return { ...state, changedSeats: payload };
    case SEATS.SET_MERGED_SEATS:
      return { ...state, mergedSeats: payload };
    case SEATS.SET_MESSAGE:
      return { ...state, message: payload };
    case SEATS.SET_MODAL_MESSAGE:
      return { ...state, modalMessage: payload };
    case SEATS.SET_JUSTIFICATION_MODAL_MESSAGE:
      return { ...state, justificationModalMessage: payload };
    case SEATS.SET_FILTERING_COLUMNS:
      return { ...state, filteringColumns: payload };
    case SEATS.SET_STATUS_JUSTIFICATIONS:
      return { ...state, statusJustifications: payload };
    case SEATS.SET_VIEW_ID:
      return { ...state, viewId: payload };
    case SEATS.SET_VIEW_START:
      return { ...state, viewStart: payload };
    case SEATS.SET_VIEW_END:
      return { ...state, viewEnd: payload };
    case SEATS.SET_SEATS_COUNT:
      return { ...state, seatsCount: payload };
    case SEATS.SET_AMOUNT_OF_SEATS_BY_TEAM_AND_STATUS:
      return { ...state, amountOfSeatsByTeamAndStatus: payload };
    default:
      return state;
  }
};
