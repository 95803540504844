import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import RowGroup from '../RowGroup/RowGroup';
import s from './TableRow.scss';

const useOnScreen = (ref: any, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []);

  return isIntersecting;
};

const TableRow = (props: any) => {
  const { data, groups, columns } = props;
  const ref = useRef() as any;
  const onScreen = useOnScreen(ref);
  if (!onScreen) {
    return (
      <tr ref={ref} className={s.tableRow}>
        {columns.map((col: any, index: number) => (
          <td key={index}></td>
        ))}
      </tr>
    );
  } // we can create a sceleton row component and return it here

  const groupFilter = (group: any) => !group.filtered && !group.formOnly; // We can add additional group filters here. (example: !group.newConditionProp)
  const groupDisplayed = (group: any) => !group.displayed && !group.formOnly;
  const firstGroup = groups
    .filter(groupFilter)
    .filter((group: any) => group.display)[0]; // Filtering the first group to check first cell.

  const renderRow = () =>
    groups
      .filter(groupFilter)
      .map((group: any, index: number) => (
        <RowGroup
          key={index}
          group={group}
          data={data}
          displayed={groupDisplayed}
          firstGroup={firstGroup && group.title === firstGroup.title}
        />
      ));

  return (
    <tr className={s.tableRow} ref={ref}>
      {renderRow()}
    </tr>
  );
};

const mapStateToProps = (state: any) => ({
  groups: state.filter.columnGroups,
  pendingChangesSeat: state.seats.pendingChangesSeat,
});

export default connect(mapStateToProps, null)(TableRow) as any;
