import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import s from './ColumnGroup.scss';
import { Text, Tooltip } from "@wix/design-system";
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import CircleSmallFilled from 'wix-ui-icons-common/CircleSmallFilled';
import FilterMenu from '../../Table/subComponents/FilterMenu/FilterMenu';
import { v4 as uuidv4 } from 'uuid';
import { setRef } from '../../redux/actions/seatsActions';

const columnGroup = (props: any) => {
  const { size, group, currentCellRef, hcdFilters, setRef } = props;

  const [filtered, setFiltered] = useState(
    hcdFilters && hcdFilters[group.title],
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const [myId, setMyId] = useState(uuidv4());

  useEffect(() => {
    setFiltered(hcdFilters && hcdFilters[group.title]);
  }, [hcdFilters]);

  const toggleMenu = () => {
    if (menuOpen && currentCellRef !== myId) {
      setRef(myId);
      return;
    }
    setMenuOpen(!menuOpen);
    setRef(myId);
  };

  return group.display ? (
    <div className={s.columnGroup} style={{ minWidth: size, maxWidth: size }}>
      <div className={s.columnGroupTitle}>
        {filtered ? (
          <CircleSmallFilled className={s.filtered} size="18px" />
        ) : (
          <div className={s.iconPlaceHolder}></div>
        )}
        <Text
          className={filtered ? s.columnGroupTextFiltered : s.columnGroupText}
          onClick={group.title !== 'Company/Department' ? () => {} : toggleMenu}
        >
          {group.title}
        </Text>
        {group.title !== 'Company/Department' ? (
          <ChevronDown className={s.hidden} />
        ) : (
          <ChevronDown className={s.chevronDown} onClick={toggleMenu} />
        )}
        {menuOpen && currentCellRef === myId ? (
          <div className={s.filterMenu}>
            <FilterMenu
              group={group}
              setFiltered={setFiltered}
              setMenuOpen={setMenuOpen}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div className={s.groupDivider}>
      <Tooltip appendTo="window" content={group.title} children={{}}/>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentCellRef: state.seats.currentCellRef,
  hcdFilters: state.filter.hcdFilters,
});

const mapDispatchToProps = (dispatch: any) => ({
  setRef: (cellRef: number) => dispatch(setRef(cellRef)),
});

export default connect(mapStateToProps, mapDispatchToProps)(columnGroup);
